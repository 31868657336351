@font-face {
  font-family: 'Ratsula';
  src: local('Ratsula'), url('../fonts/A_Ratsula/A_Ratsula.otf') format('OpenType');
  // font-display: swap;
}

@font-face {
  font-family: 'La Belle Aurore';
  src: local('La Belle Aurore'), url('../fonts/LaBelleAurore/LaBelleAurore-Regular.ttf') format('TrueType');
  // font-display: swap;
}

@font-face {
  font-family: 'Notera2';
  src: local('Notera2'), url('../fonts/Notera2/Notera2-Medium.ttf') format('TrueType');
  // font-display: swap;
}

body {
  @include font-feature-settings("kern", "liga", "pnum");
  -webkit-font-smoothing: antialiased;
  color: $color-text-primary;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: $base-font-size;
  font-weight: 400;
  text-align: center;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
  color: $color-primary;
  word-break: break-word;
}

h1 { font-size: 2em; }
h2 { font-size: 1.6em; }
h3 { 
  font-size: 1.067em; 
  text-transform: uppercase; 
  font-family: $base-font-family;
  font-weight: $font-weight-semibold;
  letter-spacing: 0.08em;
  margin-bottom: 1.75em;
}

p {
  margin: 0 0 $small-spacing;
}

a {
  text-decoration: underline;
  transition: color 0.1s linear;
  cursor: pointer;
  color: $color-primary;

  &:active,
  &:focus,
  &:hover {
    color: $color-primary-active;
    text-decoration: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}
strong {
  font-weight: 600;
}

.handwriting {
  font-family: $handwriting-font-family;
}

.beauty-handwriting {
  font-family: $beauty-handwriting-font-family;
}