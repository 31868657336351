.minun-ratsula-front #content-top {
  padding-bottom: 0;

  > .outer-wrapper { max-width: none; padding: 0; }
}

#minun-ratsula-bonus-page {
  > h2 { font-size: 2.4em; }
  text-align: center;

  #ostohyvitys-container {
    position: relative;
  }

  #ostohyvitys-progressbar {
    width: 100%;
    position: absolute;
    text-align: center;
    pointer-events: none;

    svg {
      width: 23.33em;
      height: 23.33em;
    }
  }

  #ostohyvitys-content {
    padding-top: 4em;

    #ostohyvitys-hyvityskausi {
      color: #cd96a5;
    }

    #ostohyvitys-hyvitysmaara, #ostohyvitys-seuraavahyvitys {
      font-size: 4em;
      font-family: $heading-font-family;
      color: $color-primary;
    }

    #ostohyvitys-hyvitysmaara {
      line-height: 140px;
    }

    #ostohyvitys-seuraavaan {
      font-size: 1.33em;
      color: $color-primary;
    }

    #ostohyvitys-seuraavahyvitys {
      margin-top: 0.3em;
      font-size: 1.5em;
      line-height: 48px;
      color: $color-primary;
    }
  }

  #ostohyvitys-korttitiedot {
    margin: 1.5em 0 2em;
  }

  // .center {
  //   text-align: center;
  // }

  // .card-info {
  //   @include media($medium-min) {
  //     position: absolute;
  //   }

  //   a {
  //     display: block;
  //     margin-top: 0.2em;
  //   }
  // }

  // .image-wrap {
  //   margin-bottom: 4em;
  // }

  // h1 {
  //   margin-bottom: 0.2em;
  // }

  // .center {
  //   text-align: center;
  // }

  // #bonus-center {
  //   h2 {
  //     margin-bottom: 1em;
  //   }

  //   margin-bottom: 3em;
  //   .next-level {
  //     margin-bottom: 1.5em;
  //   }
  // }
  // /*
  //   #bonus-blocks {
  //     display: flex;
  //     flex-wrap: wrap;

  //     .bonus-block {
  //       flex: 1;
  //       min-width: 250px;
  //       max-width: 300px;
  //       border-left: 1px dotted $color-primary-contrast;
  //       //padding-left: 5px;

  //       .step-amount {
  //         margin-bottom: 0;
  //         padding-left: 5px;
  //       }
  //       .step-bonus {
  //         padding-left: 5px;
  //       }

  //       .block-content {
  //         background: #fff;
  //         height: 180px;

  //         &.current {
  //           @include linear-gradient(left, $color-primary-contrast 50%, #fff 50%);
  //         }
  //       }
  //     }

  //     .bonus-info {
  //       display: inline-block;

  //       .your-bonus-caption {
  //         text-transform: uppercase;
  //         text-align: center;
  //       }
  //       .your-bonus {
  //         text-align: left;
  //       }
  //     }
  //   } */

  // .slider {
  //   // @include linear-gradient(left, $color-primary-contrast 50%, #fff 50%);
  //   min-width: 250px;
  //   height: 150px;
  //   flex: 2;
  //   margin-bottom: 2em;
  // }

  // #bonus-top {
  //   //text-align: center;
  //   margin-bottom: 2em;
  // }

  // #bonus-bottom {
  //   padding-bottom: 2em;

  //   @include media($medium-min) {
  //     display: flex;
  //     flex-wrap: wrap;
  //     justify-content: space-between;
  //   }

  //   h2 {
  //     font-size: 2.9em;
  //     margin-bottom: 0.2em;
  //   }

  //   p {
  //     margin-bottom: 0;
  //   }

  //   .uppercase {
  //     text-transform: uppercase;
  //     font-weight: 500 !important;
  //     font-size: 0.9em;
  //     line-height: 1.1em;
  //     margin-bottom: 1em;
  //   }

  //   .bold {
  //     font-weight: bold;
  //   }

  //   #bottom-left,
  //   #bottom-right {
  //     min-width: 200px;
  //     flex: 1;
  //     text-align: center;
  //     margin-bottom: 2em;
  //   }

  //   #bottom-left {
  //     @include media($medium-min) {
  //       margin-right: 2em;
  //     }

  //     a {
  //       margin-top: 0.2em;
  //       display: block;
  //     }
  //   }

  //   #bottom-right {
  //     @include media($medium-min) {
  //       margin-left: 2em;
  //     }
  //   }
  // }

  // .shopping-history {
  //   margin-top: 2.5em;
  // }

  #ostohyvitys-kaytettavissa {
    &, h2 { color: #ffe5dc; }

    background-color: #8c0b2d;
    padding: 4em 0;
  }

  #ostoshyvitysta-jaljella {
    font-size: 4em;
    font-family: $heading-font-family;
  }

  #ostohyvitysta-keratty {
    margin-top: 2em;
  }
}

.huoltoilmoitus h3 {
  margin: 3em 0;
}

#bonus-loading {
  @include header-text();
  text-align: center;
  color: $color-primary-contrast;
  padding: 1em 0;
  font-size: 2em;
  margin-bottom: 1em;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

/* purchases */

#minun-ratsula-purchases-page {
  tr {
    td {
      padding: 0 0 0.2em 0;
    }
  }
}

// v2
#minun-ratsula-bonus-page-2 {
    #ostohyvitykseni {
        color: $color-primary;
        padding: 10px;
        max-width: 800px;
        margin: 0 auto;

        .oh-title {
            font-family: $heading-font-family;
            font-weight: 400;
            text-transform: uppercase;
            text-align: left;
        }

        .oh-btn {
        }
        .text-left {
            text-align: left;
        }

        .oh-intro {
            text-align: left;
            font-size: 16px;
            color: #505050;
            letter-spacing: 0;
            line-height: 21px;
        }

        #ostohyvitys-card {
            padding: 45px 0;
            background: $color-primary;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1px;
            flex-direction: column;
            margin-bottom: 5px;

            .topper {
                display: flex;
                justify-content: center;
                align-items: flex-end;
            }
            .amount {
                font-family: $heading-font-family;
                font-weight: 600;
                font-size: 80px;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 68px;
            }
            .currency {
                font-family: $heading-font-family;
                font-size: 14px;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 1;
            }
            .explainer {
                font-family: $heading-font-family;
                font-size: 10px;
                color: #FFFFFF;
                letter-spacing: 0;
                text-align: center;
                line-height: 36px;
                margin-top: 15px;
                line-height: 1;
            }
        }
    }

    #ostohyvitys-season {
        border-radius: 1px;
        background: #FFE5DC;
        padding: 20px 20px 12px 20px;
        color: $color-primary;
        margin: 0 10px;

        .topper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .topper-season {
                font-family: $heading-font-family;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 13px;
            }
            .topper-season-date {
                font-family: $heading-font-family;
                font-size: 10px;
                letter-spacing: 0;
                line-height: 13px;
            }
            .topper-amount {
                font-family: $heading-font-family;
                font-size: 24px;
                letter-spacing: 0;
            }
            .topper-currency {
                font-family: $heading-font-family;
                font-size: 14px;
                letter-spacing: 0;
            }
            .topper-amount-info {
                font-family: $heading-font-family;
                font-size: 8px;
                letter-spacing: 0;
            }
        }
        .progress {
            margin: 15px 0;
            width: 100%;
            border-radius: 8px;
            height: 23px;
            background-color: #FFF;
            position: relative;

            .progress-bar {
                border-radius: 8px;
                height: 23px;
                background-color: $color-primary;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .explainer {
            font-family: $heading-font-family;
            font-size: 10px;
            letter-spacing: 0;
        }
    }
}
@media screen and (max-width: 767px) {
    #minun-ratsula-bonus-page h2 {
        font-size: 1.8em;

    }
    #minun-ratsula-bonus-page-2 #ostohyvitykseni #ostohyvitys-card .amount {
        font-size: 35px;
        line-height: 1;
    }
}
@media screen and (max-width: 450px) {
    #minun-ratsula-bonus-page-2 #ostohyvitykseni #ostohyvitys-season .text-sm-right {
        text-align: right;
    }
}
