.minun-ratsula-omat-tiedot-otsikko {
    
    h2 {
        font-family: $krona-font-family;
        font-size: 26px;
        font-weight: 400;
        color: #8C0B2D;
        letter-spacing: 0;
        text-align: left;
        text-transform: uppercase;
        margin-top: calc(60px - 2em);
        margin-bottom: 15px;
    }

    margin: 0;

    @include row();
}

#salasana-container-title, #profile-kiinnostukset-edit, #profile-peruskokotiedot-edit, #profile-sizes-edit {
    margin: 3em 0 0 0;
    h2 {
        text-align: left;
        font-family: $krona-font-family;
        font-size: 18px;
        font-weight: 400;
        color: #8C0B2D;
        letter-spacing: 0;
        text-transform: capitalize;
    }
    h2::before,
    h2::after {
        display: none!important;
    }
}

#edit-profile-kiinnostukset-field-tunnisteet-und {
    width: 100%;

    .form-item.form-type-checkbox {
        display: inline-block !important;
        float: unset !important;
        width: 25% !important;
        margin: 0 0 25px 0 !important;
    }
}

#salasana-container {
    margin: 0;
    float: unset;
    width: 100%;
    text-align: left;

    .form-item.form-type-password {
        width: min-content;
        float: unset;
        display: inline-block;
        margin-right: 20px;
    }
}
#edit-salasana-paivitys, #profile-kiinnostukset-edit input[type=submit] {
    text-align: left;
    margin: 0;
    width: auto;
    display: block;
}
.ratsula-omat-nimi-info {
    text-align: left;
}
.ratsula-omat-tiedot-tabit {

    text-align: left;
    margin-top: 5px;

    .ratsula-tiedot-tab {
        font-family: $base-font-family;
        font-weight: 500;
        margin: 10px 10px 10px 0;
        color: #8C0B2D;
        text-decoration: none;
        border: 1px solid #8C0B2D;
        padding: 7px 5px;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 18px;
        text-transform: uppercase;
        
        &.selected {
            color: #fff;
            background-color: #8C0B2D;
        }
    }
}
.ratsula-omat-nimi-info {
    margin: 40px 0 25px 0;
}
.form-item.form-type-date-combo.form-item-profile-main-field-syntym-aika-und-0 {
    margin: 0;
}
.name-change-info, #edit-profile-main-field-firstname label, #edit-profile-main-field-lastname label, #profile-main-field-katuosoite-add-more-wrapper label 
#profile-main-field-postinumero-add-more-wrapper label, #profile-main-field-paikkakunta-add-more-wrapper label, #profile-main-field-puhelinnumero-add-more-wrapper label, .form-item-profile-main-email label,
#edit-profile-main-field-syntym-aika-und-0-value label {
    display: none;
}
#edit-profile-main-field-syntym-aika {
    margin: 0;
    text-align: left;
    select.date-clear.form-select {
        border: 1px solid #828282;
    }
}
#field-cloth-sizes-values {
    select.form-select {
        border: 1px solid #828282;
    }
}
input.form-text {
    border: 1px solid #828282;
}

#minun-ratsula-omat-tiedot-form { 
    text-align: center;

    #profile-peruskokotiedot-edit, #profile-sizes-edit, .view-kiinnostavat-br-ndit {
        display: none;
    }

    input#edit-profile-main-field-firstname-und-0-value,
    input#edit-profile-main-field-lastname-und-0-value,
    input#edit-profile-main-field-firstname-und-0-value::placeholder,
    input#edit-profile-main-field-lastname-und-0-value::placeholder,
    input#edit-profile-main-email,
    input#edit-profile-main-email::placeholder,
    input#edit-profile-main-field-katuosoite-und-0-value,
    input#edit-profile-main-field-katuosoite-und-0-value::placeholder,
    input#edit-profile-main-field-postinumero-und-0-value,
    input#edit-profile-main-field-postinumero-und-0-value::placeholder,
    input#edit-profile-main-field-paikkakunta-und-0-value,
    input#edit-profile-main-field-paikkakunta-und-0-value::placeholder,
    input#edit-profile-main-field-puhelinnumero-und-0-value,
    input#edit-profile-main-field-puhelinnumero-und-0-value::placeholder,
    input#edit-salasana-uusi-pass1,
    input#edit-salasana-uusi-pass1::placeholder,
    input#edit-salasana-uusi-pass2,
    input#edit-salasana-uusi-pass2::placeholder {
        color: #505050;
    }
    input#edit-profile-main-field-firstname-und-0-value,
    input#edit-profile-main-field-lastname-und-0-value,
    input#edit-profile-main-email,
    input#edit-profile-main-field-katuosoite-und-0-value,
    input#edit-profile-main-field-postinumero-und-0-value,
    input#edit-profile-main-field-paikkakunta-und-0-value,
    input#edit-profile-main-field-puhelinnumero-und-0-value,
    input#edit-salasana-uusi-pass1,
    input#edit-salasana-uusi-pass2 {
        padding: 5px 10px;
    }

    .form-item.form-disabled input {
        background-color: #EFEEEE;
    }

    #profile-peruskokotiedot-edit {
        margin-bottom: 2em;
        text-align: left;
        
        &:not(.ajax-processed) {
            .field-name-field-paita, .field-name-field-housut,
            .field-name-field-kenk-, .field-name-field-puku, 
            .field-name-field-pituus, .field-name-field-materiaaliallergiat {
                // @include span-columns(4);
                // @include omega('3n+1');
                width: calc(33.33% - 10px);
                margin: 0 10px 10px 0;
                display: inline-block;
            
                .form-item {
                    margin: 0;
                }
            }
        }


        &.ajax-processed {
            .field-name-field-paita, .field-name-field-housut,
            .field-name-field-kenk-, .field-name-field-puku, 
            .field-name-field-pituus, .field-name-field-materiaaliallergiat {
                // @include span-columns(4);
                // @include omega('3n+2');
                width: calc(33.33% - 10px);
                margin: 0 10px 10px 0;
                display: inline-block;
            
                .form-item {
                    margin: 0;
                }
            }
        }

        @include media($mobile) {
            &:not(.ajax-processed), &.ajax-processed {
                .field-name-field-paita, .field-name-field-housut,
                .field-name-field-kenk-, .field-name-field-puku, 
                .field-name-field-pituus, .field-name-field-materiaaliallergiat {
                    @include span-columns(12);
                    @include omega('1n');
                }
            }
        }

        .field-name-field-lis-tiedot {
            // @include span-columns(6);
            // @include shift(3);
            width: calc(33.33% - 10px);
            margin: 0 10px 10px 0;
            display: inline-block;
        
            .form-item {
                margin: 0;
            }

            @include media($mobile) {
                @include span-columns(12);
                @include shift(0);
            }
        }
    }

    .field-name-field-cloth-sizes {
        .tabledrag-toggle-weight-wrapper {
            display: none;
        }

        table tbody tr td:nth-child(3) {
            display: none;
        }
    }
        
    .field-name-field-cloth-sizes > div > .form-item > div.clearfix {
        // float: left;
        // margin-left: 35%;

        &:after {
            display: none;
        }

        input {
            margin-top: 0;
            background-color: #d0d0d0;
            color: $color-primary;

            &:hover {
                background-color: #c0c0c0;
            }
        }

        @include media($mobile) {
            float: none;
            margin: 1em auto;
        }
    }

    #edit-profile-sizes-save {    
        display: block;

        @include media($mobile) {
            float: none;
            margin: 1em auto;
        }
    }

    .form-item > .field-multiple-table {
        margin: 1em 0;

        thead, .field-multiple-drag { 
            display: none;
        }

        tbody, tr, tr:hover td {
            background: transparent !important;
        }

        td {
            border: none !important;
            padding: 0;

            &:nth-child(2) {
                @include row();
                margin: 1em 0;

                @include media($mobile) {
                    border-bottom: 1px solid $color-primary-contrast;
                }
            }
        }

        .field-name-field-cloth, .field-name-field-brand, .field-name-field-size {
            @include span-columns(2);
        }

        .field-name-field-description {
            @include span-columns(5);
        }

        @include media($mobile) {
            .field-name-field-cloth, .field-name-field-brand, .field-name-field-size, .field-name-field-description {
                @include span-columns(12);
            }
        }

        .form-submit {
            @include span-columns(1);

            padding: 0;
            margin-top: 1.1em;
            height: 2.916em;
            width: 2.083em;
            background-color: transparent;
            background-size: 100%;
            background-repeat: no-repeat;
            background-image: url('../img/Trash.svg');
            color: transparent;

            @include media($mobile) {
                display: inline;
                float: none;
            }
        }

        .field-name-field-cloth {
            .data-added {
                text-align: left;

                @include media($mobile) {
                    position: absolute;
                    margin-top: -4.5em;
                }
            }
        }

        .field-name-field-lis-tty {
            display: none;
        }

    }

    #profile-main-edit {
        margin-bottom: 2em;
        text-align: left;

        &:not(.ajax-processed) {
            .name-wrapper,
                .form-item-profile-main-email,
                .field-name-field-katuosoite, .field-name-field-paikkakunta,
                .field-name-field-postinumero, .field-name-field-puhelinnumero {
                    // @include span-columns(3);
                    // @include omega('4n+1');¨
                    width: calc(33.33% - 10px);
                    margin: 0 10px 10px 0;
                    display: inline-block;

                    .form-item {
                        margin: 0;
                    }
                    // float: left;
            }
        }

        &.ajax-processed {
            .name-wrapper,
                .form-item-profile-main-email,
                .field-name-field-katuosoite, .field-name-field-paikkakunta,
                .field-name-field-postinumero, .field-name-field-puhelinnumero {
                    // @include span-columns(3);
                    // @include omega('4n+2');
                    width: calc(33.33% - 10px);
                    margin: 0 10px 10px 0;
                    display: inline-block;
                
                    .form-item {
                        margin: 0;
                    }
            }
        }
        
        @include media($mobile) {
            &:not(.ajax-processed), &.ajax-processed {
                .name-wrapper,
                    .form-item-profile-main-email,
                    .field-name-field-katuosoite, .field-name-field-paikkakunta,
                    .field-name-field-postinumero, .field-name-field-puhelinnumero {
                        @include span-columns(12);
                        @include omega('1n');
                }
            }
        }

        .field-name-field-postinumero {
            clear: left;
        }

        .name-wrapper {
            .form-item, input {
                margin-bottom: 0.25em;
            }

            .name-change-info {
                font-size: 0.75em;
            }
        }

        .field-name-field-syntym-aika, .field-name-field-haluan-ratsulan-tietoa-tuo {
            .form-type-checkbox {
                float: left;
                margin-right: 2em;
            }
        }
        .field-name-field-haluan-ratsulan-tietoa-tuo {
            .form-type-checkbox {
                width: auto;
                margin: 0;
                flex: unset;
                float: unset;
                margin-right: 30px;
            }
        }

        .field-name-field-syntym-aika {
            margin: 0;

            .container-inline-date.date-no-float {
                width: 100%;

                .form-type-date-select {
                    margin-right: 0;

                    .form-type-select {
                        select { margin: 0 0.5em 0 0; }
                        &:first-child, &:last-child { 
                            select { width: 5em; }
                        }
                    }
                }
            }
        }

        .field-name-field-haluan-ratsulan-tietoa-tuo {
            margin: 2em 0;
            
            .form-checkboxes {
                // @include display(flex);
                // @include flex-wrap(wrap);
                // justify-content: center;
                // text-align: center;
                display: flex;
                justify-content: flex-start;
            }
        }
    }

    .field-name-field-tunnisteet {
        
        .form-type-checkboxes {
            margin: 2em 0;
        }
        
        .form-type-checkboxes > label { display: none; }

        .form-checkboxes {
            text-align: left;
            margin: 2em 0;
            @include clearfix();

            .form-item {
                @include span-columns(3 of 15);
                @include omega(5n);

                @include media($mobile) {
                    @include span-columns(12);
                    @include omega(1n);
                }
            }
        }
    }

    .view-kiinnostavat-br-ndit {
        margin: 3em 0 1em 0;
        clear: both;

        .view-content .view-group .view-group-rows {
            @include display(flex);
            @include flex-wrap(wrap);
            justify-content: center;
            text-align: center;
        }

        .views-row {
            @include span-columns(2);
            @include omega(6n);

            @include media($mobile) {
                @include span-columns(6);
                @include omega(2n);
            }

            @include media($mobile-small) {
                @include span-columns(8);
                @include shift(2);
                @include omega(1n);
            }

            position: relative;
            margin-top: 1em;
            margin-bottom: 1em;
            
            .views-field-field-image {
                .brandi-flagging {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    .flag-wrapper {
                        position: static;
                    }

                    a {
                        color: transparent;
                        cursor: pointer;

                        position: absolute;
                        right: 0;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        
                        background-image: url('../img/Bookmark.svg');
                        background-size: 1.467em 1.867em;
                        background-position: 1em 1em 0 0;
                        background-repeat: no-repeat;

                        &.unflag-action, &:hover {
                            background-image: url('../img/Bookmark_active.svg');
                        }
                    }
                }
                    
                img {
                    height: auto !important;
                    width: auto !important;
                }
            }

            .views-field-name {
                margin-top: 1.333em;
                font-weight: 500;
            }
        }
    }
}

body.logged-in.page-minun-ratsula, body.logged-in.page-node-1056, body.logged-in.page-node-631, body.ratsula-wide-content.page-node-1112, body.ratsula-wide-content.page-node-10721, body.ratsula-wide-content.page-node-22493 {
    .minun-ratsula-valikko ul.menu {
        justify-content: flex-start;

        li {
            margin: 5px;
            background-color: unset;
            &.active-trail {
                background-color: unset;

                a {
                    background: #8C0B2D;
                    color: #FFFFFF !important;
                }
            }

            a {
                margin: 0;
                font-family: $base-font-family;
                text-transform: uppercase;
                font-size: 16px;
                letter-spacing: 1px;
                line-height: 18px;
                color: #8C0B2D;
                background: #FFFFFF;
                border: 1px solid #8C0B2D;
                padding: 10px 5px;
    
                &.active, &:hover {
                    background: #8C0B2D;
                    color: #FFFFFF!important;
                }
            }
        }
        
    }

    @media print {
        .minun-ratsula-valikko, #page-header, #site-header, #site-footer, .sticky-header, #footer-admin-menu, #service-booking-admin-week-selectors { display: none; }
        
        #service-booking-reservations-list { margin: 0; }
        
        main {
            margin-top: 0 !important; 

            #content-top { padding-bottom: 0; }
            /*#profile-sizes-edit {
                page-break-after: always;
            }*/

            input[type="submit"], button {
                display: none;
            }

            .view-kiinnostavat-br-ndit .brand-flagged-0, #salasana-container, #salasana-container-title, #profile-kiinnostukset-edit, #edit-profile-main-field-haluan-ratsulan-tietoa-tuo { display: none !important; }
        }
    }
}