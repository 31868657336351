#booking-main-view {
  padding-top: 7em;
  background-size: auto 6.267em;
  margin-top: 1em;
  background-image: url('../img/Pukeutumisneuvoja.svg');
  background-repeat: no-repeat;
  background-position: center top;

  @include clearfix();

  &:after {
    clear: both;
    content: ' ';
    display: table;
  }

  h2 {
    margin-bottom: 1.25em;
  }

  .no-servants-message {
    text-align: center;
  }
}

#booking-main-view > ul {
  li, li a {
    @include header-text();
    font-size: 1em;
    text-decoration: none;
  }

  &:after {
    clear: both;
    content: ' ';
    display: table;
  }
}

#booking-main-view > ul > li {
  font-size: 0.85em;
  opacity: 0.1;
  text-align: center;
  margin-bottom: 2em;

  @include media($medium-min) {
    @include span-columns(3 of 14);
    @include omega(4);

    &:first-child {
      @include shift(1 of 14);
    }
  }

  @include media($mobile) {
    @include span-columns(12);
  }

  &.selected, &.active {
    opacity: 1;
  }

  .step-number {
    background-color: $color-primary;
    border-radius: 50%;
    color: $color-primary-contrast;
    width: 4em;
    height: 4em;
    line-height: 4em;
    margin: 1em auto;
    display: block;
  }

  .step-chosen {
    color: #bf002e;
    display: block;
  }
}

#booking-main-view {
  .service-inner, #selected-wrapper .servant {
    cursor: pointer;
    overflow: hidden;
    margin-top: $gutter / 2;
    margin-bottom: $gutter / 2;
    padding: $column;
    position: relative;
    background-color: $color-secondary;
    text-align: center;
    color: $color-text-primary;
  }

  .service {
    &:not(.wide) {
      @include media($fhd-min) {
        @include ratsula-columns(4);
      }

      @include media($large) {
        @include ratsula-columns(6);
      }

      @include media($mobile) {
        @include ratsula-columns(12);
      }
    }

    &.wide {
      @include media($fhd-min) {
        @include ratsula-columns(8);
      }

      @include media($large) {
        @include ratsula-columns(12);
      }
    }

    h3 {
      height: 2em;
    }
    .service-image {
      margin: 1em 0 2em 0;
    }
  }

  #servant-selector, #service-selector {
    @include display(flex);
    @include flex-wrap(wrap);
    justify-content: center;
    text-align: center;

    .servant, .service {
      cursor: pointer;
    }
  }

  #service-booking-router-wrapper {
    min-height: 10em;
  }

  #servant-selector {
    .servant {
      @include media($large-min) {
        @include span-columns(2);
        @include omega(6n);
      }

      @include media($medium-only) {
        @include span-columns(3);
        @include omega(4n);
      }

      @include media($mobile) {
        @include span-columns(6);
        @include omega(2n);
      }

      @include media($mobile-small) {
        @include span-columns(12);
        @include omega(1n);
      }

      margin-bottom: 2em;

      .servant-name {
        font-weight: $font-weight-medium;
        margin-top: 1.333em;
        text-align: center;
      }

      img {
        max-height: 220px;
      }
    }
  }

  .service-description, .servant-description {
    line-height: 1em;
    min-height: 3em;
    overflow: hidden;
    white-space: pre-line;
  }

  #selected-wrapper, #confirmation {
    .servant, .service {
      @include media($large-min) {
        @include span-columns(5);

        &:first-child {
          @include shift(1);
        }
      }
      @include media($medium-only) {
        @include span-columns(6);

        &:first-child {
          @include shift(0);
        }
      }

      @include media($mobile) {
        @include span-columns(12);

        &:first-child {
          @include shift(0);
        }
      }
    }
  }

  #time-selector {

    div.time,
    a.time {
      cursor: pointer;
      font-weight: bold;

      background-color: $color-primary-contrast;
      color: $color-primary-contrast;

      padding: 0.667em;
      display: inline-block;
      margin: 1em;
      min-width: 10em;
      text-decoration: none;

      &:hover {
        background-color: $color-primary-active;
      }
    }

    div.time {
      background-color: $light-gray;
      cursor: default;
      color: $medium-gray;

      &:hover {
        background-color: $light-gray;
      }
    }

    .reserve-time {
      padding-top: 1em;
      font-size: 0.7em;
      text-transform: uppercase;
      text-decoration: underline;
    }

    .reserved {
      padding-top: 1em;
      font-size: 0.7em;
      text-transform: uppercase;
      text-decoration: none;
    }

  }

  #confirm-wrapper, #confirmed-header {
    text-align: center;
  }

  .confirmation-header, #info-form {
    @include span-columns(8);
    @include shift(2);

    @include media($mobile) {
      @include span-columns(12);
      @include shift(0);
    }
  }

  .datepicker-wrap {
    display: table;
    margin: 0 auto 2em auto;
  }

  .datepicker-item {
    float: left;
    margin: 0 0.75em;

    @include media($mobile) {
      display: block;
      float: none;
      margin: 1em auto !important;
    }
  }

  #date-next, #date-prev {
    font-size: 2em;
    height: 1.5em;
    width: 1.5em;
    line-height: 1.5em;
    margin-top: -0.25em;
    display: inline-block;
    background-color: $color-primary;
    color: $color-primary-contrast;
    border-radius: 50%;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: $color-primary-active;
    }

    &.invisible {
      opacity: 0;
      cursor: default;
    }
  }

  .cant-find-time {
    text-align: center;
    margin-top: 2em;
    margin-bottom: 2em;
  }
}

#booking-main-view > div {
  margin-top: 2em;
}

.webform-client-form {
  .form-actions {
    text-align: center;
  }

  .centerize {
    text-align: center;

    label, select, input {
      display: inline-block;
      line-height: 2em;
    }
  }
}