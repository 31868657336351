body.page-minun-ratsula-reservations {
    #content-top > .outer-wrapper {
        max-width: 100%;
    }
}

#service-booking-reservations {
    margin: 2em 5em;

    @include media($medium) {
        margin: 2em 0;
    }

    #service-booking-reservations-week {
        text-align: center;
        margin-bottom: 2em;
    }

    .service-booking-reservation-day {
        float: left;
        @include span-columns(4);
        @include omega(3);

        @include media($mobile) {
            @include span-columns(12);
            @include omega(1n);
        }

        .sbrd-title {
            @include header-text();
            text-align: center;
        }

        .service-booking-reservation {
            cursor: pointer;
            text-align: center;
            margin: 1em 0;
            padding: 1em;
            background-color: $color-primary;
            color: #fff;


            .sbr-time {
                @include header-text();
                color: #fff;
            }

            &.notReserved {
                opacity: 0.6;
            }
        }
    }

    #service-booking-reservations-servant {
        select {
            margin: 2em auto;
        }
    }

    #service-booking-reservations-days {
        @include clearfix();

        @include media($medium-min) {
            @include display(flex);
            @include flex-wrap(wrap);
            justify-content: center;
        }

        text-align: center;

        .day-navigator {
            &:hover {
                text-decoration: underline;
            }    
        }

        .day-navigator, #sbrd-cal {
            cursor: pointer;
            float: left;
            @include header-text();

            @include media($mobile) {
                float: none;
            }

            height: 2em;
            line-height: 2em;
            margin: 2em 1em;
        }

        #sbrd-cal-input {
            z-index: -1;
            opacity: 0;
            position: absolute;
            margin-top: -5em;
            width: auto;
        }
    }

    .no-reservations {
        text-align: center;
        margin: 1em 0;
    }
}

html body.page-minun-ratsula-ajanvaraukset-viikko {
    @media print {
        .minun-ratsula-valikko, #page-header, #site-header, #site-footer, .sticky-header, #footer-admin-menu, #service-booking-admin-week-selectors { display: none; }
    #service-booking-reservations-list { margin: 0; }
        main {
            margin-top: 0 !important; 

            #content-top { padding-bottom: 0; }
        }
        
        table.sticky-table { margin-bottom: 0; }
        margin-top: 0 !important;
    }
}

#service-booking-admin-week-selectors {
    text-align: center;

    .service-booking-admin-week-selector {
        display: inline-block;
        margin: 1em;

        a {
            @include header-text();
        }
    }
}

#service-booking-reservations-list {
    margin: 2em 0;

    .service-booking-reservation {
        text-align: center;
        margin: 1em auto;
        width: 25em;
        max-width: 100%;
        padding: 1em;
        background-color: $color-primary;
        color: #fff;


        .sbr-time {
            @include header-text();
            color: #fff;
        }

        &.notReserved {
            opacity: 0.6;
        }
    }
}

.service-booking-modal .modal-inner {
    text-align: center;

    .sbm-modal-title {
        @include header-text();
        margin-bottom: 1em;
    }

    .modal-links a {
        display: inline-block;
        text-transform: uppercase;
        font-weight: $font-weight-semibold;
        font-size: 0.7em;
        line-height: 3em;
        padding: 0 1em;
        margin: 1em;
        margin-top: 0;
        color: #fff;
        background-color: $color-primary;
    }
}