.ratsula-line-item {
    display: flex;
    position: relative;
    break-inside: avoid-page;

    &.refunded {

        .ratsula-line-item-content-left,
        .ratsula-line-item-content-right {
            text-decoration: line-through;
        }

        .ratsula-line-item-refunded {
            margin-top: 1em;
            color: #8c0b2d;
        }
    }

    .ratsula-line-item-media {
        width: 150px;
        max-width: 20vw;
        margin-right: 0.5em;
        text-align: center;

        img {
            height: auto;
        }
    }

    .ratsula-line-item-content-wrapper {
        flex: 1;
    }

    .ratsula-line-item-content {
        display: flex;
        color: #505050;
        flex: 1;

        .ratsula-line-item-content-left {
            flex: 1;
            margin-right: 1em;
        }

        .ratsula-line-item-content-right {
            text-align: right;

            .quantity-selector {
                justify-content: flex-end;
            }
        }

        @media screen and (max-width: 767px) {
            display: block;

            .ratsula-line-item-content-right {
                text-align: left;

                .quantity-selector {
                    justify-content: flex-start;
                }
            }
        }
    }

    .ratsula-line-item-brand,
    .ratsula-line-item-title {
        a {
            font-weight: bold;
            color: #505050;
            text-decoration: none;
        }
    }

    .ratsula-line-item-brand {
        font-size: 0.8rem;
    }

    .ratsula-line-item-title a {
        font-weight: normal;
    }

    .ratsula-line-item-discount {
        color: #8c0b2d;
        margin-bottom: 0.5em;
    }

    .ratsula-line-item-price {
        font-family: 'Ratsula', sans-serif;
        font-size: 1.25em;
        display: flex;
        flex-wrap: wrap;

        @media screen and (min-width: 768px) {
            justify-content: flex-end;
        }
    }

    .line-item-row-status {
        font-weight: 500;
        color: #8c0b2d;
        margin-top: 0.25em;
    }

    .ratsula-line-item-discounted-price {
        color: #8c0b2d;
        margin-right: 1em;
    }

    .ratsula-line-item-delete a {
        display: flex;

        img {
            margin-right: 0.5em;
        }
    }

    .ratsula-line-item-original-price {
        text-decoration: line-through;
    }
}

.no-more-at-stock {}

.page-admin-commerce-orders {
    #content {
        width: 1200px;
        max-width: 100%;
        padding: 0 1em;
        margin-left: auto;
        margin-right: auto;
    }

    .ratsula-line-item {
        .ratsula-line-item-content-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .ratsula-line-item-media img {
            max-height: 8em;
            width: auto;
        }
    }

    .field-name-commerce-order-total .commerce-price-formatted-components {
        width: auto;
        margin-left: 0;
    }

    .field-name-commerce-line-items {
        margin-bottom: 1em;

        .views-row:not(.views-row-first) {
            border-top: 1px solid #f1f1f1;
            padding-top: 0.5em;
        }
    }

    @media print {
        .field-name-commerce-order-total {
            float: left;
            margin-right: 2em;

            .commerce-price-formatted-components {
                border: none;

                td {
                    padding: 0;
                }

                .component-title {
                    padding-left: 0;
                    padding-right: 10px;
                }
            }
        }

        .field-name-commerce-order-total,
        .field-name-commerce-smartpost-extra-field,
        .field-name-commerce-prinetti-extra-field {
            break-inside: avoid;
        }
    }

    .field-name-commerce-smartpost-extra-field:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
}

@media print {
    .page-admin-commerce-orders h1 {
        font-size: 24px !important;
    }

    .page-admin-commerce-orders .field-name-commerce-order-total .commerce-price-formatted-components {
        width: 350px;
    }
}
