.front {
  #content-top {
    section:not(.full-width) {
      @include span-columns(4);
      
      @include media($fhd-min) {
        &.ostohyvitys, &.edut {
          margin-right: 0;
        }
      }

      @include media($large) {
        @include span-columns(6);
        
        &.bonussaldo, &.minun-ratsulani {
          margin-right: 0;
        }

        &.ostohyvitys, &.edut {
          @include shift(3);
          @include row();
        }
      }

      @include media($mobile) {
        @include span-columns(12);
        @include omega('1n+1');

        &.ostohyvitys, &.edut {
          @include shift(0);
        }
      }
    }

    .full-width {
      @include clearfix;
      text-align: center;

      &.etusivun-otsikko {
        @include header-text();
        padding-top: 2em;
        margin-bottom: 2em;

        h1 {
          margin-bottom: 0;

          @include media($mobile) {
            margin-bottom: 0.5em;
          }
        }

        p {
          font-size: 4em;
          line-height: 1.25em;
          margin: 0;

          &:last-child {
            font-size: 1.6em;
          }
        }
      }

      h2 {
        width: 100%;
        max-width: 37em;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }

    }

    .palvelunosto-kuvalla, .palvelunosto {
      margin: 2em 0;
      padding: 0 2em;
      text-align: center;
    }

    .palvelunosto {
      p {
        margin-bottom: 1.5em;
      }
    }

    .palvelunosto-kuvalla {
      background-color: $color-secondary;
      height: 25em;

      padding-top: 12em;
      background-position: center 3em;
      background-size: auto 6.333em;
      background-repeat: no-repeat;

      &.asukokonaisuudet {
        background-image: url('../img/Pukeutumisneuvoja.svg');
      }

      &.bonussaldo {
        background-image: url('../img/Omattiedot.svg');
      }

      &.ostohyvitys {
        background-image: url('../img/Edut.svg');
      }

      a { color: $color-text-primary; }
    }

    .keskita-varaa-pukeutumisneuvoja-front {
      font-family: $heading-font-family;
      font-size: 1em;
      margin-top: 3em;
      margin-bottom: 3em;

      .varaa-aika-pukeutumisneuvojalle-front a {
        width: 38em;
        max-width: 100%;
        display: inline-block;
        text-transform: uppercase;
        padding: 2em 4em;
        background-color: #8c0b2d;
        color: #fff !important;
        text-decoration: none;
      }
    }
  }
}

.block-sisaltofeed > h2 {
  padding-top: 1em;
  // font-size: 4em;
  text-align: center;
}

.sisaltofeed-items {
  @include ratsula-row();

  min-height: 5em;

  // display: grid;
  // grid-template-columns: 33.333% 33.333% 33.333%;
  // row-gap: $gutter;
  // column-gap: $gutter;
  position: relative;

  .sisaltofeed-loader {
    /*background-color: rgba(0, 0, 0, 0.4);*/
    top: 0; left: 0;
    right: 0; bottom: 0;

    background-image: url('../img/Loader.svg');
    background-size: 3em;
    background-position: center 1em;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 10;
  }
    
  .sisaltofeed-item {
    margin-top: $gutter / 2;
    margin-bottom: $gutter / 2;
    text-align: center;

    .sisaltofeed-item-inner {
      cursor: pointer;
      overflow: hidden;
      position: relative;
      background-color: $color-primary-contrast;
      box-shadow: $card-shadow;
      border-radius: $card-radius;
    }

    .sisaltofeed-title {
      // display: none; //! !
      height: 2em;
      margin: 2em 0;
      // @include calc(margin-bottom, "#{$column} - 1em");

      .hide-text { color: transparent; width: 0; height: 0; overflow: hidden; display: block; }

      .facebook, .instagram {
        height: 2em;
        background-position: center;
        background-size: auto 100%;
        background-repeat: no-repeat;
      }

      .facebook { background-image: url('../img/FB.svg'); }
      .instagram { background-image: url('../img/Ins.svg'); }
    }

    .sisaltofeed-flag {
      position: absolute;
      height: 1.867em;
      width: 1.467em;

      cursor: pointer;

      right: 1.467em;
      top: 1em;
      
      background-image: url('../img/Bookmark.svg');
      background-size: 100%;
      background-repeat: no-repeat;

      &.flagged, &:hover {
        background-image: url('../img/Bookmark_active.svg');
      }
    }

    .sisaltofeed-image {
      z-index: 2;
      margin: 1em;
      overflow: hidden;
      position: relative;

      .sisaltofeed-image-inner {
        width: 100%;

        &.has-video {
          z-index: 3;
          position: absolute;
        }
      }

      /*&.width-100 img { width: 100%; }
      &.height-100 img { height: 100%; max-width: none; }*/
    }
    
    .sisaltofeed-video {
      video {
        height: 270px;
        width: 270px;
        max-width: 100%;
      }
    }

    .video-play {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 4;

      svg {
        width: 4em;
        height: 4em;
      }
    }

    .sisaltofeed-floating-text {
      position: absolute;
      top: $column-y-padding;
      bottom: $column-y-padding;
      left: 0;
      right: 0;
      
      overflow: hidden;

      margin: 0 3em;
      line-height: 1.5em;
    }

    .sisaltofeed-text {
      overflow: hidden;
      z-index: 1;
      margin: 1em;
      height: 4.5em;
      line-height: 1.5em;
      position: relative;
      
      &.noImage {
        /*margin-bottom: 18em;
        padding-bottom: 7.334em;*/
      }

      &.textHidden {
        visibility: hidden;
      }
    }

    &.yksiSarake {
      @include ratsula-columns(4);

      @include media($large) {
        @include ratsula-columns(6);
      }

      @include media($mobile) {
        @include ratsula-columns(12);
      }

    }

    &.kaksiSaraketta {
      @include ratsula-columns(8);
      
      @include media($large) {
        @include ratsula-columns(12);
      }
    }
  }

  /*@include media($mobile) {
    &.mobile-limited .sisaltofeed-item:nth-child(1n+7) { display: none; }
  }*/
}

.sisaltofeed-load-more-outer {
  text-align: center;
  margin: 2em 0;

  .sisaltofeed-load-more {
    @include loader();
  }
}

.no-feed-text {
  text-align: center;

  a {
    cursor: pointer;
    font-weight: $font-weight-semibold;
    text-decoration: underline;    
    color: $color-primary;
    text-transform: uppercase;

    @include loader();

    &:hover {
      text-decoration: none;
      color: $color-primary-active;
    }
  }
}

.sisaltofeed-filters, .brand-list-filters {
  text-align: center;
  margin: 1em 0;

  .sisaltofeed-filter, .brand-list-filter {
    color: $color-primary;
    font-size: 0.8em;
    display: inline-block;
    margin: 1em 2em;
    cursor: pointer;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;

    &.active {
      text-decoration: underline;
    }
  }
}

.view-kerrosnavigaatio {
  margin: 2em 0 0;

  .view-content {
    text-align: center;

    .views-row {
      display: inline-block;

      a {
        display: inline-block;
        padding: 0.5em;
        margin: 0.5em;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;

        &.active {
          background-color: $color-primary;
          color: $color-primary-contrast;
        }
      }
    }
  }
}

#front-kerrokset {
  text-align: center;
  margin: 2em 0;

  .kerros-button {
    display: inline-block;
    padding: 0.5em;
    margin: 0.5em;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    &.active {
      background-color: $color-primary;
      color: $color-primary-contrast;
    }
  }
}

.front-kerrokset {
  margin-top: 4em;
  margin-bottom: 4em;

  h2 {
    max-width: 30em;
    margin: 0 auto;
  }

  .view-kerrokset {
    .visibility-hidden {
      visibility: hidden;
    }

    .kerros:nth-child(1n+2) {
      display: none;
    }

    .page-img {
      margin-bottom: 4em;

      @include media($mobile) {
        margin-bottom: 2em;
      }

      img {
        width: 100%;
        height: auto;
      }
    }


    .buttons, .cols  {
      @include row();

      > div {
        @include span-columns(4);

        @include media($mobile) {
          @include span-columns(12);
        }
      }
    }

    .buttons {
      margin: 1em 0;

      a {
        margin: 1em 0;
      }
    }

    .cols {
      .left, .right {
        margin-top: 80px;
        padding: 0 1em;

        transform: translateY(-15px);
      }

      .sellers {
        display: flex;

        > .view {
          flex: 1;
          overflow: hidden;

          .view-group-rows {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.5s transform;

            .views-row {
              text-align: center;
            }
          }
        }
      }
    }

    .brandit {
      margin-top: 4em;

      .field-content {
        @include row();

        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .taxonomy-term {
          @include span-columns(2);

          @include media($mobile) {
            @include span-columns(6);
          }

          @include media($medium) {
            @include span-columns(3);
          }

          img {
            height: auto;
          }
        }

        .field-name-title {
          font-family: $base-font-family;
          color: $color-text-primary;
        }
      }
    }
  }
}

.header-puhelinnumero {
  @include media($mobile) {
    a {
      text-decoration: underline !important;
    }
  }
}

.oma-ehdotus-pukeutumisneuvonta {
  @include span-columns(8);
  @include shift(2);

  h2 {
    text-align: center;
    font-size: 3em;
  }

  .webform-component--paivamaara, .webform-component--kuukausi, .webform-component--kellonaika {
    @include span-columns(4);
    @include omega(3);
  }

  .webform-component--kellonaika select {
    width: 100%;
  }

  .form-item-submitted-kerros {
    @include span-columns(3);
    @include omega(4);
  }

  .form-actions {
    text-align: center;
  }
}

.kerroksen-myyjat {
  margin: 2em 0;
  overflow: hidden;
  
  h2 {
    @include header-text();
    color: $color-primary;
    text-align: center;
    font-size: 1.6em;
    margin-bottom: 2.5em;
  }

  .view-content .view-group {
    position: relative;
  }

  .view-content .view-group-rows {
    @include display(flex);
    @include flex-wrap(wrap);
    justify-content: center;
    text-align: center;

    @include media($mobile) {
      transition: 1s all;
      justify-content: flex-start;
    }

    @include media($medium-min) { transform: none !important; }

    .views-row {
      @include media($medium-min) {
        @include span-columns(2);
        @include omega(6n);
      }
       
      margin-bottom: 2em;

      @include media($medium) {
        @include span-columns(4);
      }

      @include media($medium-only) {
        @include omega(3n);
      }

      /*@include media($mobile-small) {
        @include span-columns(7);
        @include row();
        @include omega(1n);
      }*/

      @include media($mobile) {
        margin-left: 0;
        margin-right: 0;
        padding: 0 1em;
        clear: none !important;
      }

      .views-field-field-firstname {
        margin-top: 1.333em;
        font-weight: 500;
      }
    }
  }

  .nuoli-vasen, .nuoli-oikea {
    display: none;
    cursor: pointer;

    @include media($mobile) {
      display: block;
      position: absolute;
      height: 2.07em;
      width: 1.135em;
      margin-top: -1.035em;

      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .nuoli-vasen { left: 0; background-image: url('../img/nuoli_vasen.png'); }
  .nuoli-oikea { right: 0; background-image: url('../img/nuoli_oikea.png'); }
}

.kerroksen-brandit {
  margin: 0 0 2em 0;

  #floor-brand-list-block { position: relative; overflow: hidden; }

  h2 {
    margin-bottom: 2.5em;
  }

  .brand-list-items {
    @include display(flex);
    @include flex-wrap(wrap);
    justify-content: center;
    text-align: center;

    @include media($mobile) {
      transition: 1s all;
      justify-content: flex-start;
    }

    @include media($medium-min) { transform: none !important; }

    .brand-item {
      @include span-columns(2);
      @include omega(6n);
      margin-top: 2em;
      margin-bottom: 2em;
      text-align: center;

      @include media($medium) {
        @include span-columns(4);
      }

      @include media($medium-only) {
        @include omega(3n);
      }

      @include media($mobile) {
        /*@include span-columns(6);
        @include omega(2n);*/
        padding: 0 1em;
        margin-right: 0;
        clear: none !important;
      }

      .brand-image {
        img {
          max-height: 8em;
        }
      }

      .brand-name {
        margin-top: 0.5em;
        font-weight: 500;
      }
    }
  }

  .nuoli-vasen, .nuoli-oikea {
    display: none;
    cursor: pointer;

    @include media($mobile) {
      display: block;
      position: absolute;
      height: 2.07em;
      width: 1.135em;
      margin-top: -1.035em;

      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .nuoli-vasen { left: 0; background-image: url('../img/nuoli_vasen.png'); }
  .nuoli-oikea { right: 0; background-image: url('../img/nuoli_oikea.png'); }
}

.view-kerrokset-kuvagalleria {
  margin: 2em 0;

  h2 {
    margin-bottom: 2.5em;
  }

  .view-group-rows {
    @include display(flex);
    @include flex-wrap(wrap);
    justify-content: center;
    text-align: center;

    .views-row {
      @include span-columns(2);
      @include omega(6n);
      margin-top: 2em;
      margin-bottom: 2em;
      text-align: center;

      @include media($medium) {
        @include span-columns(4);
      }

      @include media($medium-only) {
        @include omega(3n);
      }
      @include media($large-only) {
        @include omega(3n);
      }

      @include media($mobile) {
        @include span-columns(6);
        @include omega(2n);
      }
    }
  }
}

.viikon-lounas {
  margin-top: 2em;

  .view-viikon-lounas {
    margin-top: 2em;
    text-align: center;
    
    .view-content {
      @include row();
      
      .views-row {

        .views-field {
          margin-bottom: 2em;
          @include span-columns(2);

          @include media($medium-only) {
            @include span-columns(4);
            @include omega(3n);
          }

          @include media($mobile) {
            @include span-columns(12);
            @include omega(1n);
          }
        }
      }
    }
  }
}

.node-type-floor {
  #content-wrapper {
    background-repeat: no-repeat;
    background-size: auto 3.25em;
    background-position: 50% 3em;
    padding-top: 9em;
  }

  &.page-node-1 #content-wrapper { background-image: url('../img/0_Pohjakerros.png'); }
  &.page-node-136 #content-wrapper { background-image: url('../img/1_Katutaso.png'); }
  &.page-node-137 #content-wrapper { background-image: url('../img/2_Naistenkerros.png'); }
  &.page-node-138 #content-wrapper { background-image: url('../img/3_Miestenkerros.png'); }
}

.kerros-otsikko {
  margin: 2em 0;
  text-align: center;

  /*
  background-size: auto 4em;
  background-position: top center;
  background-repeat: no-repeat;

  &.pohjakerros {
    background-image: url('../img/0_Pohjakerros.svg');
  }

  &.katutaso {
    background-image: url('../img/1_Katutaso.svg');
  }

  &.naistenkerros {
    background-image: url('../img/2_Naistenkerros.svg');
  }

  &.miestenkerros {
    background-image: url('../img/3_Miestenkerros.svg');
  }*/
}

.pukeutumisneuvojat-otsikko {
  margin: 2em 0;
  padding-top: 6em;
  background-image: url('../img/Pukeutumisneuvoja.svg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 4em;
}

#footer-admin-menu {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 5em;
  z-index: 40;

  @include display(flex);
  @include flex-wrap(wrap);
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: $color-primary;

  ul.menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0.25em;

    li {
      a {
        display: block;
        background-color: $color-secondary;
        color: $color-primary;
        text-transform: uppercase;
        font-weight: $font-weight-semibold;
        height: 2em;
        line-height: 2em;
        margin: 0.5em;
        padding: 0 1em;
        text-decoration: none;

        &.active {
          color: $color-secondary;
          background-color: $color-primary;
          border-color: $color-secondary;
        }

        @include media($mobile) {
          font-size: 0.75em;
        }
      }
    }
  }
}

.view-pukeutumisneuvojat-listaus {
  .view-group {
    @include span-columns(6);
    @include omega(2n);

    @include media($medium) {
      @include span-columns(12);
      @include omega(1n);
    }

    @include media($mobile) {
      @include span-columns(12);
      @include omega(1n);
    }

    margin-top: 2em;
    margin-bottom: 2em;

    h3 {
      @include heading-line();
    }

    .view-group-rows {
      @include display(flex);
      @include flex-wrap(wrap);
      justify-content: center;
      text-align: center;

      .views-row {
        @include span-columns(4);

        @include media($medium-min) {
          @include omega(3n);
        }

        @include media($mobile) {
          @include span-columns(6);
          @include omega(2n);
        }

        @include media($mobile-small) {
          @include span-columns(7);
          @include row();
          @include omega(1n);
        }

        margin-top: 1em;
        margin-bottom: 1em;

        .views-field-field-firstname {
          margin-top: 1em;
          font-weight: 500;

          a {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
        
      }
    }
  }
}


.view-pukeutumisneuvonta-ajat {
  margin-bottom: 2em;

  .view-group {
    overflow: hidden;
    h2 {
      margin: 2em 0;
    }
  }

  .views-row {
    @include span-columns(4);
    @include omega(3n);

    @include media($medium) {
      @include span-columns(6);
      @include omega(2n);
    }

    @include media($mobile) {
      @include span-columns(12);
      @include omega(1n);
    }

    margin-top: 2em;
    margin-bottom: 2em;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    background-color: $color-secondary;

    h3 {
      margin: $column-y-padding $column;
    }

    .views-field-field-image {
      z-index: 2;
      margin: 0 $column;
      overflow: hidden;
      position: relative;

      .field-content {
        width: 100%;
        height: 18em;

        img {
          max-width: none;
        }
      }
    }

    .pn-text {
      overflow: hidden;
      z-index: 1;
      margin: 2.667em 3em;
      height: 2em;
      line-height: 1em;
      position: relative;
      text-align: center;
    }

    .views-field-body {
      margin:3em;
    }
  }
}

.front-brandilista {
  padding: 2.1em 1em;

  h2 {
    margin-bottom: 1.25em;
    padding-top: 0;
    font-size: 1.6em;
  }

  .view-group {
    margin-top: 2em;
    margin-bottom: 2em;

    @include span-columns(3);
    @include omega(4);

    @include media($medium) {
      @include span-columns(5);
      @include omega(2n);
      
      &:nth-child(2n) {
        @include shift(1);
      }
    }

    @include media($mobile) {
      @include span-columns(6);
      @include omega(2n);
      
      &:nth-child(2n) {
        @include shift(0);
      }
    }

    .views-row {
      text-align: center;
      text-transform: uppercase;
      font-weight: $font-weight-semibold;
      font-size: 1.067em;
      line-height: 1.25em;
    }
  }
}

.view-yhteystiedot-luokittelulistaus .view-group-rows {
  @include clearfix();
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2em 0;

  .views-row {
    float: left;
    margin: 0.5em;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.yhteystiedot-listaus {
  .view-group > a { position: absolute; margin-top: -10em; }

  .view-group-rows {
    .views-row {
      @include span-columns(6);
      @include omega(2n);
      margin-top: 2em;
      margin-bottom: 2em;

      @include media($medium-only) {
        @include span-columns(10);
        @include shift(1);
        @include omega(1n);
      }

      @include media($mobile) {
        @include span-columns(12);
        @include omega(1n);
        text-align: center;
      }

      .views-field-field-lastname {
        font-size: 1.25em;
        font-weight: $font-weight-semibold;
      }

      .views-field-field-image {
        @include media($medium-min) {
          float: left;
          max-width: 30%;
          margin-right: 2em;
        }

        @include media($mobile) {
          margin: 1em 0;
          clear: both;

          img {
            max-width: 17em;
          }
        }

        img {
          height: auto;
          width: auto;
        }
      }
    }
  }
}

.kirjautumis-block {
  margin: 2em 0;

  form {
    div.item-list {
      display: none;
    }

    .form-actions {
      text-align: center;
    }
  }
}


.rekisteroidy-block {
  clear: both;

  width: 100%;
  max-width: 70em;
  margin: 0 auto;
  margin-bottom: 2em;
  text-align: center;

  a {
    text-transform: uppercase;

    &.laatikko {
      width: 19em;
      max-width: 100%;

      display: block;
      margin: 1em auto;
      @include header-text();
      background-color: $color-primary;
      color: $color-primary-contrast !important;
      padding: 2.5em 0;
      font-weight: normal;
      text-decoration: none;
      font-size: 2em;

      .new-row {
        display: block;
        font-size: 0.5em;
      }

      &:hover {
        background-color: $color-primary-active;
      }
    }

    &.ei-laatikkoa {
      font-size: 0.8em;
      color: $color-primary;
      font-weight: $font-weight-semibold;
      text-align: center;
    }
  }
}

#modakortit-valinta {
  @include clearfix();

  .kateiskortti, .tilikortti {
    float: left;
    width: 45%;

    @include media($medium-min) {
      &:first-child {
        margin-right: 10%;
      }
    }

    @include media($mobile) {
      width: 100%;
    }

    a.laatikko {
      @include clearfix();
      text-transform: uppercase;
      width: 16em;
      max-width: 100%;

      display: inline-block;
      @include header-text();
      background-color: $color-primary;
      color: $color-primary-contrast !important;
      padding: 5em 0;
      font-weight: normal;
      text-decoration: none;
      margin: 1em;

      &:hover {
        background-color: $color-primary-active;
      }

      @include media($medium-min) {
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  
}

.fb-page {
  display: block !important;
}

.fb-page > span {
  display: block !important;
  margin: 2em auto 0 auto;
  max-width: 100%;

  iframe {
    max-width: 100%;
  }
}

.vuokrauspalvelu-yhteydenotto {
    margin-top: 2em;

    @include media($medium-min) {
      @include span-columns(6);
      @include shift(3);
    }
}

.nosto-kuvalla {
  color: #2b2b2b;

  &.arvot .nosto-container {
    background-image: url('../img/A_arvot.png');
  }

  &.missio .nosto-container {
    background-image: url('../img/A_missio.png');
  }

  &.visio .nosto-container {
    background-image: url('../img/A_visio.png');
  }

  .nosto-container {
    background-color: $color-secondary;
    
    margin: 2em 0;
  
    padding-top: 13em;
    background-position: center 3em;
    background-size: auto 9.4em;
    background-repeat: no-repeat;
  }

  &.half {
    @include media($medium-min) {
      @include span-columns(6);

      .nosto-container {
        height: 24em;
      }
    }

    @include media($large-min) {
      .nosto-container {
        height: 21em;
      }
    }
  }

  p {
    margin: 0 2em;
    padding: 0 0 2em 0;
    text-align: center;

    strong {
      color: $color-primary;
    }
  }
}

.view-palveluesittelyt {
  .view-group {
    @include row();
  }

  .views-row {
    @include media($fhd-min) {
      @include omega(3n);
      @include span-columns(4);
    }

    @include media($large) {
      @include omega(2n);
      @include span-columns(6);
    }

    @include media($mobile) {
      @include span-columns(12);
    }

    .views-row-inner {
      overflow: hidden;
      margin-top: 2em;
      margin-bottom: 2em;
      padding: 3em;
      position: relative;
      background-color: #fff;
      text-align: center;
      color: #2b2b2b;
    }

    h3 {
      margin-bottom: 1.5em;

      a { text-decoration: none; }
    }

    .views-field-field-image {
      margin-bottom: 2em;

      img { height: auto !important; }
    }

    .views-field-field-lyhyt-kuvaus {
      height: 8.8em;
      overflow: hidden;


      @include media($mobile) {
        height: auto;
      }
    }

    .views-field-view-node {
      margin-top: 1em;

      a {
        background-color: $color-primary-contrast;
        font-size: 0.8em;
        color: $color-primary-contrast !important;
        font-weight: $font-weight-semibold;
        text-align: center;
        display: block;
        text-transform: uppercase;
        text-decoration: none;

        padding: 0.75em;
      }
    }
  }
}

.go-back-link {
  display: flex;
  align-items: center;
  color: $color-primary;
  margin-right: 2em;

  img {
    margin-right: 0.5em;
  }
}

.merkkituote-block, .uusimmat-tuotteet-block {
  margin: 4em 0;
}

.merkkituote-block {
  margin-top: 0;
}

.ratsula-uusimmat-tuotteet .shop-list .shop-products {
  @include media($medium) {
    grid-template-columns: calc(50% - 0.5em) calc(50% - 0.5em);
  }
}

.merkkituotteita {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5em;

  .merkkituote {
    width: 25%;
    padding: 0.5em;
    position: relative;

    @include media($mobile) {
      width: 50%;
      padding-bottom: 0;
    }

    .merkkituote-text {
      position: absolute;
      font-family: 'Ratsula', sans-serif;
      border: 2px solid $color-primary;
      // padding: 1em;
      padding: calc(1em + 3px) 1em 1em 1em;
      line-height: 0.7em;
      left: 50%;
      top: 50%;

      transform: translate(-50%, -50%);
      background-color: #ffffffaa;
    }

    a:hover {
      .merkkituote-text {
        background-color: #fff;
      }
    }
  }
}

.ratsula-floors-wrapper {
  padding: 4em 0;
  // background: $color-secondary;
  // color: $color-primary;

  h2 {
    padding: 0 1em;
    margin-bottom: 10px;
  }

  h3 {
    letter-spacing: 0;
    text-align: left;
    text-transform: none;
    font-size: 1.5em;
    font-weight: normal;
  }

  @include media($mobile) {
    padding: 1.5em 0;

    h2 {
      padding: 0;
      font-size: 1.6em;
    }
  }

  .shop-kerros {
    .content {
      color: #505050;
      a {
        color: #505050;
      }
    }
  }

  .floor-link {
    display: inline-block;

    @include header-text();
    color: $color-secondary;
    background-color: $color-primary;
    border: 2px solid $color-primary;
    // padding: 1em;
    text-decoration: none;
    // padding: 13px 16px;
    padding: calc(13px + 3px) 16px 13px 16px;
    margin-top: 1em;
    color: #FFEFEA;

    &:hover {
      // background-color: 
    }
  }
}

.ratsula-service-wrapper {
  padding: 4em 0;
  background: $color-secondary;
  color: $color-primary;

  @include media($mobile) {
    padding: 1.5em 0;
  }
  
  h2 {
    display: none;
  }

  .ratsula-etusivun-palvelut-description {
    text-align: center;
    margin: 0 0 2em 0;
    display: none;
  }

  .shop-services {
    h3 {
      font-family: 'La Belle Aurore', sans-serif;
      font-size: 3em;
      text-transform: none;
      font-weight: normal;
      letter-spacing: normal;
      margin-bottom: 0.5em;
    }
    h2 {
      display: block;
    }

    .link {
      display: inline-block;

      @include header-text();
      background: $color-primary;
      color: $color-secondary;
      border: 2px solid $color-primary;
      padding: calc(1em + 3px) 1em 1em 1em;
      text-decoration: none;

      margin-top: 2em;

      &:hover {
        background-color: $color-primary-contrast;
        color: $color-primary;
      }
    }
  }
}

#sliding-popup .eu-cookie-compliance-banner {
  .info {
    display: flex;
    align-items: center;
    padding: 1em 0;
  }

  #popup-text {
    margin-top: 0;
    flex: 1;
    max-width: none;
    padding-right: 1em;
  }

  #popup-buttons {
    margin: 0;

    .agree-button {
      border: 2px solid $color-primary-contrast;
    }
    
    .agree-button, .decline-button {
      margin: 0;
    }

    @include media($mobile) {
      text-align: center;

      .agree-button {
        font-size: 0.8em;
        display: block;
        margin-bottom: 1em;
      }
    }
  }
}

.rakkaudella-kasin-poimittu-outer {
  margin: 2em 0;

  .rakkaudella-kasin-poimittu {
    display: flex;
    width: 100%;
    text-align: left;
    align-items: center;
    flex-wrap: wrap;

    h2 {
      text-align: left;
    }

    .left, .right {
      width: 50%;
      padding: 3em;
    }

    .right {
      text-align: center;
    }

    @include media($medium) {
      .left, .right {
        padding: 1em;
      }
    }

    @include media($mobile) {
      .left, .right {
        width: 100%;
        max-width: 30em;
        margin: 0 auto;
      }

      .right img {
        max-height: 15em;
      }  
    }

    .content {
      color: $color-primary;
    }
  }
}

.toimituspalkki {
  position: static;
  text-align: center;
  padding: 0.5em 0;
  background-color: #fff;
  background-color: #ffffffe0;
  color: $color-text-secondary;
  font-size: 0.8125em;

  @include media($medium-min) {
    // position: absolute;
    // left: 0;
    // right: 0;

    .toimituskulu-row {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      column-gap: 1em;
    
      div {
        flex: 1 1 0;

        @include media($fhd-min) {
          flex: none;
        }
      }
    }

    .outer-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}

.not-front .toimituspalkki {
  position: static;
}

.page-node-1059 .node .field-name-title {
  display: none;
}

.ratsula-oh-on-page {
  .aukioloweek {
    margin: 2em 0 4em 0;
  }

  .aukioloaika-now {
    font-weight: 600;
    font-size: 1.25em;
    text-align: center;
    margin: 1em 0;
  }

  .days {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0.5em 0;

    .day-names {
        font-weight: bold;
    }

    .day {
        margin: 0.5em;
    }

    .day-oh {
        white-space: nowrap;
    }
  }
  table {
    margin: 0;
    border-collapse: collapse;
    table-layout: auto;
    width: auto;

    tr.this-week td {
        font-weight: 600;
    }

    td {
        padding: 0;
        
        &:nth-child(1) { padding-right: 1.25em; }
        &:nth-child(2) { text-align: center; padding: 0 0.25em; }
        &.closed { text-align: center; }
    }

    tr:hover td {
        background-color: transparent;
    }
  }
}

.ratsula-campaign-banners {
  display: flex;
  flex-direction: column;
  margin: 0 -1.5em 0em -1.5em;

  .ratsula-campaign-banner {
    display: block;
    text-decoration: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    flex: 1;
    height: 12.5em;
    padding: 1.5em;
    text-align: left;
    margin: 1.5em;

    @include media($mobile-small) {
      padding: 1em;
    }

    display: flex;
  }

  .ratsula-campaign-banner-text {
    flex: 1;
  }

  .ratsula-campaign-banner-text, .ratsula-campaign-banner-percent {
    font-size: 2em;
    color: $color-primary;
    font-family: "Ratsula",sans-serif;
    word-break: break-word;
  }

  // &.single-banner .ratsula-campaign-banner {
  .ratsula-campaign-banner {
    // flex-di
    align-items: center;

    .ratsula-campaign-banner-percent {
      font-size: 4em;
      padding-left: 1em;
    }

    @include media($medium) {
      flex-direction: column;
    
      .ratsula-campaign-banner-text {
        width: 100%;
        text-align: center;
      }

      .ratsula-campaign-banner-percent {
        width: 100%;
        text-align: center;
      }
    }

    .ratsula-campaign-banner-percent {
      @include media($large) {
        font-size: 3em;
      }

      @include media($medium) {
        font-size: 2.5em;
      }

      @include media($mobile) {
        font-size: 2em;
      }
    }

    .ratsula-campaign-banner-text {
      @include media($large) {
        // font-size: 1.5em;
      }

      @include media($medium) {
        font-size: 1.75em;
      }

      @include media($mobile) {
        font-size: 1.1em;
      }

      @include media($mobile-small) {
        font-size: 1em;
      }
    }
  }

  // &.multiple-banners .ratsula-campaign-banner {
  //   flex-direction: column;
    
  //   .ratsula-campaign-banner-text {
  //     font-size: 1.75em;
  //   }

  //   .ratsula-campaign-banner-percent {
  //     text-align: right;
  //     font-size: 2.5em;
  //     padding-left: 1em;
  //   }
  // }

  // &.banner-count-3 .ratsula-campaign-banner {
  //   font-size: 0.7em;
  // }

  // @include media($large) {
  //   &.banner-count-2 .ratsula-campaign-banner {
  //     font-size: 0.8em;
  //   }

  //   &.banner-count-3 .ratsula-campaign-banner {
  //     font-size: 0.7em;
  //   }
  // }

  // @include media($medium) {
  //   &.banner-count-2 .ratsula-campaign-banner {
  //     font-size: 0.6em;
  //   }

  //   &.banner-count-3 {
  //     display: block;
  
  //     .ratsula-campaign-banner {
  //       font-size: 0.8em;
  //       align-items: center;
  //       flex-direction: row;
  //     }
  //   }
  // }

  // @include media($mobile) {
  //   &.multiple-banners {
  //     display: block;

  //     .ratsula-campaign-banner {
  //       font-size: 0.6em;
  //       flex-direction: column;
  //       align-items: initial;
  //     }
  //   }
  // }
}

.os-coupons-subtitle {
  font-size: 20px;
  color: #8C0B2D;
  letter-spacing: 0;
  text-align: left;
  margin: 65px 0 15px 0;
  font-family: $krona-font-family;
}
.os-coupons {
  &.shop-list .shop-products {
    justify-content: start;
    margin: 0;

    .shop-product {
      padding: 1.3em 0 0 0 !important;

      div.product-brand {
          font-family: $krona-font-family;
          font-size: 20px;
          color: #8C0B2D;
          letter-spacing: 0;
          text-align: center;
        }
    }

    @media (max-width: 400px) {
      grid-template-columns: 100%;
    }
  }

  

  .os-expires-at {
    // position: absolute;
    // right: 0;
    // top: 0;
    padding: 0.2rem;
    font-size: 0.75em;
    // background-color: $color-secondary;
    // color: $color-primary;
    // z-index: 1;
  }

  .os-used {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0008;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 500;
  }
}

.os-coupon {
  .os-coupon-image {
    text-align: center;
    margin: 2em 0;

    img {
      max-height: 33vh;
    }
  }

  .os-button {
    margin: 0;
    text-transform: none;
    display: block;
    text-align: center;

    &:hover {
      background-color: $color-primary;
    }

    .smaller {
      font-size: 0.75em;
    }
  }

}

.flag-tuotteiden-tykk-ykset {
  position: static;
  
  a {
    position: absolute;
    height: 32px;
    width: 32px;

    cursor: pointer;

    left: 16px;
    top: 16px;
    z-index: 10;

    font-size: 0.1px;
    color: transparent;
    display: block;
    
    background-image: url('../img/heart-clear.svg');
    background-size: 100%;
    background-repeat: no-repeat;

    &.unflag-action, &:hover {
      background-image: url('../img/heart-filled.svg');
    } 
  }
}