#site-footer {
  background: $color-primary;
  padding-bottom: 2em;
  text-align: center;

  .outer-wrapper,
  a {
    text-transform: uppercase;
    color: $color-primary-contrast;
    font-weight: $font-weight-semibold;
  }

  .footer-osoite-aukiolo {
    font-size: 0.75em;
    margin-bottom: 2.667em;

    @include media($medium-min) {
      margin-left: 11em;
      margin-right: 11em;
    }
  }

  .outer-wrapper {
    position: relative;
  }

  .footer-logo {
    padding: 2em 0;

    img {
      height: 4.125em;
    }
  }

  .footer-somelinkit {
    @include media($medium-min) {
      position: absolute;
      top: 2em;
      right: 1em;
    }

    @include media($mobile) {
      margin-bottom: 2em;
    }

    a {
      display: inline-block;
      margin: 0px 0.25em;
      text-decoration: none;
    }

    img {
      height: 1.8em;
    }

    .vuoden-yritys {
      width: 7em;
      height: auto;
      display: block;
      margin: 1em auto;
    }

  }

  .footer-uutiskirje {
    @include clearfix();

    #mc_embed_signup_scroll {
      @include display(flex);
      @include flex-wrap(wrap);
      justify-content: center;
      text-align: center;
    }

    input[type="text"],
    input[type="email"] {
      max-width: 35em;
      width: 80%;
      float: left;
      margin-bottom: 0;
      color: $color-primary;

      @include media($mobile) {
        width: 60%;
      }
    }

    input[type="button"],
    input[type="submit"] {
      width: 20%;
      float: left;
      max-width: 10em;
      padding: 0.85em;

      @include media($mobile) {
        width: 40%;
      }
    }
  }
}

#site-footer-new {
  background: $color-primary;
  color: $color-secondary;

  .footer-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 20px;

    .footer-new-block {
      width: 33%;
      padding: 0px 15px;
    }

    @include media($medium) {
      .footer-new-block {

        &:nth-of-type(1) {
          order: 1;
        }

        &:nth-of-type(2) {
          order: 0;
        }

        &:nth-of-type(3) {
          order: 2;
        }
      }
    }

    @include media($medium) {
      flex-direction: column;
      align-items: unset;



      .footer-new-block {
        width: 100%;
        padding: 0px;

        &:nth-of-type(0) {
          order: 1;
        }
        &:nth-of-type(1) {
          order: 0;
        }
        &:nth-of-type(2) {
          order: 2;
        }
      }

      .footer-menus {
        .menu-info {
          margin: 0 auto;
        }
      }
    }

    .footer-sub-newsletter {
      max-width: 400px;
      margin: 40px auto;

      img {
        display: none;
      }

      .footer-osoite-aukiolo {
        display: none;
      }

      .footer-sub-newsletter-texts {
        margin-bottom: 20px;

        h4,
        p {
          text-align: left;
          color: #FFE5DC;
        }

        h4 {
          margin-bottom: 10px;
        }

        p {
          font-size: 14px;
          line-height: 24px;
        }
      }

      #mc_embed_signup_scroll {
        display: flex;
        flex-direction: row;
        margin-top: 7px;

        #mce-EMAIL {
          background: $color-secondary;
          border: 1px solid $color-secondary;
          height: 40px;
          color: $color-primary;

          &::placeholder {
            color: $color-primary;
            // font-size: 14px;
            font-size: 0;
          }
        }

        #mc-embedded-subscribe {
          border: 1px solid $color-secondary;
          height: 40px;
          color: $color-secondary;
        }
      }
    }

    .footer-menus {
      section.block.block-menu-block {
        display: none;
      }

      .block.block-ratsula-misc.show-on-mobile-and-desktop {
        display: block !important;

        .menu-info {
          color: white;
          font-size: 15px;
          max-width: 400px;
          margin: 40px auto;
          
          @include media($large-min) {
            max-width: 300px;
          }
          @include media($medium) {
            text-align: center;
          }

          .menu-info-details.menu-asiakaspalvelu-details {
            display: block;
          }

          .toggle-label {
            line-height: 20px;
            margin-bottom: 10px;

            .toggle {
              display: none;
            }

            &.aukioloaika-now,
            &.asiakaspalvelu-title {
              font-weight: 600;
            }
          }

          .menu-opening-hours-details {
            padding-top: 0;
            padding-bottom: 18px;

            table {
              margin-bottom: 18px;

              td {
                padding: 0;
                line-height: 25px;
              }
            }

            #aukioloajat-lisatiedot {
              p {
                display: none;

                &:last-of-type {
                  display: block;
                }
                a {
                  text-decoration: underline!important;
                }
              }
            }
          }

          .menu-asiakaspalvelu {
            .asiakaspalvelu-title {
              display: none;
            }
          }
          .menu-asiakaspalvelu-details {

            h6, h2 {
              font-size: 18px;
              line-height: 25px;
              margin-bottom: 2px;
              font-weight: bold;
              text-transform: lowercase;

              &::first-letter {
                text-transform: uppercase;
              }

              strong {
                font-weight: bold;
              }

              @include media($medium) {
                text-align: center;
              }
            }

            p {
              margin-bottom: 0px;

              a {
                line-height: 25px;
              }

              strong {
                display: none;
              }

              &:last-of-type {
                margin-top: 18px;
              }
            }
          }

          .menu-lowermenu-item {
            margin-bottom: 10px;

            .gomee-lunch-title {
              display: none;
            }
          }

          a,
          h2,
          h6 {
            color: white;
          }

          a {
            text-decoration: underline;
          }

          .toggle {
            filter: invert(1);
          }
        }
      }
    }

    .footer-logo-new {
      margin-top: 40px;
      text-align: center;

      p {
        margin-top: -3px;
      }

      a {
        color: #FFFFFF;
        text-decoration: none;
        font-size: 15px;
      }
    }

    #nav-social-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 20px 0;

      img {
        width: 20px;
        height: 20px;
        margin: 0 5px;
      }
    }

    #nav-policy-footer {
      overflow: auto;
      padding-bottom: 10px;

      div {
        width: 50%;
        float: left;
        text-align: right;
        padding: 0 10px;

        &:last-of-type {
          text-align: left;
        }

        a {
          color: white;
          font-size: 14px;
          text-decoration: none;
        }
      }

    }
    #nav-payments-footer {
      padding-bottom: 30px;
      text-align: center;
      a {
        color: white;
        font-size: 14px;
        text-decoration: none;
      }
    }
  }
}