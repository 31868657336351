$base-spacing: 1.5em !default;
$alert-color: #fff6bf !default;
$error-color: #fbe3e4 !default;
$notice-color: #e5edf8 !default;
$success-color: #e6efc2 !default;
$message-border-radius: 5px;

@mixin flash($color) {
  /* background-color: $color;*/
  color: $color-primary-contrast;
  display: block;
  margin-bottom: $base-spacing / 2;
  padding: $base-spacing / 2 $base-spacing / 2 $base-spacing / 2 50px;
  border-radius: $message-border-radius;

  a {
    color: $color-primary-contrast;

    &:focus,
    &:hover {
      color: $color-primary-active;
    }
  }
}

.messages {
  background-color: $color-primary;
  text-align: center;
  font-weight: 500;
  padding: 1em;

  &.error {
    @include flash($error-color);
  }

  &.status {
    @include flash($success-color);
  }

  &.notice {
    @include flash($notice-color);
  }

  &.warning {
    @include flash($alert-color);
  }
}

@include media($large) {
  .floating-status-message .messages {
    position: fixed;
    top: 0;
    left: 1em;
    right: 1em;
    margin-top: 1em;
  }
}