// Typography
$base-font-family: 'Poppins', sans-serif;
$krona-font-family: 'Krona One', sans-serif;
$heading-font-family: 'Ratsula', sans-serif;
$handwriting-font-family: 'La Belle Aurore', 'Brush Script MT', Cursive;
$beauty-handwriting-font-family: 'Notera2', 'Brush Script MT', Cursive;

$font-weight-medium: 500;
$font-weight-semibold: 600;
// Font Sizes
$base-font-weight: 300;
$base-font-size: 16px;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

$header-top-height: 2.667em;
$page-header-height-without-bottom: 12.167em;
$page-header-height-large-screen: 11.167em;
$page-header-height-medium-screen: 8.77em;
$page-header-height-mobile-screen: 7.8em;

// Colors
$blue: #477dca;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;

$color-header-top: #FFEFEA;
$color-white: #FFF;
$color-white-active: darken($color-white, 15%);
// $color-bg: #FAFAFA;
$color-bg: #FFFFFF;
$color-bg-active: darken($color-bg, 15%);
$color-primary: #8c0b2d;
$color-primary-contrast: #fff;
$color-primary-active: darken($color-primary, 15%);
$color-primary-light: #9D2343;
$color-secondary: #FFE5DC;
$color-secondary-active: darken($color-secondary, 15%);
$color-light: #F1F1F1;
$color-text-primary: #505050;
$color-text-secondary: #636363;
$color-text-disabled: #828282;
$color-text-secondary-active: darken($color-text-secondary, 15%);

$base-background-color: $color-bg;
// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($color-primary, $lightness: -5%, $alpha: -0.3);

$card-shadow: 0 0px 16px 0px rgba(0, 0, 0, 0.2);
$card-radius: 2px;
/*$mobile: new-breakpoint(max-width 500px 4);*/