table {
  @include font-feature-settings("kern", "liga", "tnum");
  border-collapse: collapse;
  margin: $small-spacing 0;
  table-layout: auto;
  width: 100%;
}

th {
  font-weight: 500;
  padding: $small-spacing 0;
  text-align: left;
}

td {
  border-bottom: $base-border;
  padding: $small-spacing 0;
}

table tbody, table tr.odd, table tr.even, table th, table tbody tr:hover > td, table tbody tr td, table tbody tr:first-child td {
  background: transparent;
  border-bottom: none;
  border-top: none;
}

table {
  thead th, tbody td {
    border-bottom: 1px solid $color-light;
  }
}

tr,
td,
th {
  vertical-align: middle;
}
