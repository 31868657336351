#ratsula-kayttajahaku-form {
    #ratsula-kayttajahaku-input {
        margin: 0 0.5em;

        input {
            margin-bottom: 0;
            height: 3em;
        }
    }

    #ratsula-kayttajahaku-results {
        position: absolute;
        bottom: 4em;
        background-color: $color-secondary;
        border: 1px solid $color-primary;
        border-bottom: none;
        text-align: left;

        .ratsula-kayttajahaku-result {
            width: 20em;
            padding: 1em;
            border-bottom: 1px solid $color-primary;

            &:last-child {
                border-bottom: none;
            }

            .nayta {
                float: right;

                a {
                    background-color: $color-primary;
                    color: $color-primary-contrast;
                }
            }
        }
    }
}