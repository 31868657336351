@import "../../../neato/bower_components/neat/app/assets/stylesheets/neat-helpers";
// Neat Overrides
$column: 5em;
$column-y-padding: 2.667em;
$gutter: 2em;
$grid-columns: 12;
$max-width: 1200px;

// Neat Breakpoints
$mobile-small-screen: 340px;
$mobile-screen-start: 341px;
$mobile-screen: 767px;
$medium-screen-start: 768px;
$medium-screen: 969px;
$large-screen-start: 970px;
$large-screen: 1170px;
$fhd-screen-start: 1171px;

$mobile-small: new-breakpoint(max-width $mobile-small-screen 12);
$mobile: new-breakpoint(max-width $mobile-screen 12);
$medium: new-breakpoint(max-width $medium-screen 12);
$large: new-breakpoint(max-width $large-screen 12);

$mobile-min: new-breakpoint(min-width $mobile-screen-start 12);
$medium-min: new-breakpoint(min-width $medium-screen-start 12);
$medium-only: new-breakpoint(min-width $medium-screen-start max-width $medium-screen 12);
$large-min: new-breakpoint(min-width $large-screen-start 12);
$large-only: new-breakpoint(min-width $large-screen-start max-width $large-screen 12);
$fhd-min: new-breakpoint(min-width $fhd-screen-start 12);