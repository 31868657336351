fieldset {
  margin: 0;
  border: none;
  padding: 0;
}

input,
label,
select {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  color: $color-text-secondary;
  padding: 3px 5px;
  
  &, &:focus {
    border: none;
    outline: none;
  }

}

label {
  font-weight: 400;
  margin-bottom: $small-spacing / 2;
  padding-left: 0;
  font-size: 0.9em;

  &.required::after {
    content: "*";
  }

  abbr {
    display: none;
  }
}

#{$all-text-inputs},
select[multiple=multiple],
textarea {
  background-color: $color-white;

  &, &:focus {
    outline: none;
  }

  border: 1px solid #636363;
  border-radius: 0;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  font-family: $base-font-family;
  font-size: $base-font-size;
  margin-bottom: $base-spacing / 2;
  padding: 0.25em;
  transition: border-color;
  width: 100%;

  &:hover {
    // border-color: darken($color-primary, 10%);
  }

  &:focus {
    border-color: $color-primary;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }
}

textarea {
  resize: vertical;
}

input[type="search"] {
  @include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
  position: relative;
}

.form-type-checkbox, .form-type-radio {
  position: relative;

  label {
    cursor: pointer;
    margin-left: 3em;
    line-height: 2em;

    &.no-absolute::before {
      position: static;
      display: block;
    }
  }

  label::before {
    transition: 0.2s border-color, 0.2s background-size;

    content: ' ';
    border: 2px solid $color-text-secondary;
    border-radius: 100%;

    background-color: $color-white;
    background-image: url('../img/checkmark.svg');
    background-size: 0em 0em;
    background-repeat: no-repeat;
    background-position: center;

    position: absolute;
    // margin-left: -3em;
    width: 2em;
    height: 2em;
    left: 0;
    cursor: pointer;
  }

  input {
    display: none;
  }

  input:checked + label::before {
    border-color: $color-primary;
    background: url('../img/checkmark.svg');
    background-size: 1em auto;
    background-repeat: no-repeat;
    background-position: center;
  }
}

input[type="file"] {
  padding-bottom: $small-spacing;
  width: 100%;
}

input[type="submit"], input[type="button"], button {
  font-size: 1em;
  background-color: $color-primary;
  color: $color-primary-contrast;
  font-family: $heading-font-family;
  padding: 0.8em 1.167em 0.7em;
  text-transform: uppercase;
  letter-spacing: 1px;

  border: none;
  border-radius: 0;

  &:hover, &:focus {
    background-color: $color-primary-active;
  }
}

select {
  margin-bottom: $base-spacing;
  max-width: 100%;
  width: auto;
}

@include placeholder {
  color: $color-primary-active;
}

label.paivamaarafield-processed {
  cursor: pointer;
  padding-left: 3em;
  line-height: 2em;
  height: 2em;
  background-image: url('../img/Calendar.png');
  background-position: left center;
  background-size: 2em;
  background-repeat: no-repeat;
  width: max-content;

  &.paivamaara-selected {
    line-height: 2em;
  }

  .form-required {
    float: right;
  }
}

#user-login-forgot-pass-link, .moda-kortin-numero-hukassa {
  float: right;
}

.form-item input.error, .form-item textarea.error, .form-item select.error {
  border: 2px solid $color-primary;
}

#user-pass-reset, #user-pass, #custom-registration-registration-form, #custom-registration-create-authenticated-form-wrapper {
  text-align: center;
}
#edit-customer-profile-shipping-field-puhelin {
  margin-top: -15px;
}
#card-cash-entityform-edit-form, #card-account-entityform-edit-form {
  label {
    margin-bottom: 0;
  }
}
#custom-registration-registration-form {
  margin-bottom: 150px;

  label[for=edit-card-number], label[for=edit-first-name], label[for=edit-last-name] {
    text-align: left;
    margin-bottom: 0;
  }

  .form-item.form-type-textfield, .mknh-fixed {
    max-width: calc(550px + 1.167em + 1.167em);
    margin: 0 auto;
  }
  .mknh-fixed {
    margin: 50px auto 0 auto;
  }

  @media (max-width:650px) {
    input {
      &.form-submit {
        white-space: break-spaces;
        padding: 0.8em 1em 0.7em;
        font-size: 0.9em;
      }
    }
  }
}

form#user-login-form {
  max-width: calc(550px + 1.167em + 1.167em);
  margin: 0 auto;
}
#card-cash-entityform-edit-form, #card-account-entityform-edit-form, form#user-pass, form#webform-client-form-1112 {
  #edit-field-luottoraja-und {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .form-item {
    max-width: calc(550px + 1.167em + 1.167em);
    text-align: left;
    margin: 0 auto;
  }
}
#card-account-entityform-edit-form #edit-field-luottoraja .form-radios .form-item.form-type-radio {
  width: calc(33.33% - 1em);
  margin: 1em 1em 1em 0 !important;
  @media (max-width:650px) {
    width: 100%;
  }
}



.form-item.form-disabled input {
  background-color: $color-bg-active;
}

/* rinnakkaiskortit */


#field-rinnakkaiskortti-user-values,
#field-rinnakkaiskortti-values,
.field-name-field-rinnakkaiskortti {
  tr {
    th {
      display: none;
    }
    th.field-label {
      display: block;
    }
  }

  tbody {
    .field-multiple-drag {
      display: none;
    }
  }
}

.field-name-field-haluan-ratsulan-tietoa-tuo {
  .form-checkboxes {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .form-type-checkbox {
      flex: 1;
    }
  }
}

#edit-field-rinnakkaiskortti-user,
#field-rinnakkaiskortti-user-values,
#field-rinnakkaiskortti-values,
#edit-field-rinnakkaiskortti {
  th,
  .field-multiple-drag,
  .tabledrag-toggle-weight {
    display:none;
  }

  .field-multiple-table {
    .form-submit {
      margin-top: 3em;
      background-color: $light-gray;
      color: $dark-gray;
    }
  }

  .field-add-more-submit {
    background-color: rgba(140, 11, 45, 0.6);
  }
 // .ajax-new-content {

   // }

}

.field-name-field-rinnakkaiskortti .field-multiple-table,
#field-rinnakkaiskortti-user-values,
#field-rinnakkaiskortti-values {
  .form-submit {
    margin-top: 4em;
    background-color: rgba(140, 11, 45, 0.6);
  }
}

.krumo-name,
.krumo-root {
  color:#000 !important;
}

.entitytype-parallel_card-form {
  th label {
    display: none;
  }
}

form#webform-client-form-631 {
  max-width: calc(550px + 1.167em + 1.167em);
  margin: 0 auto;
  
  #edit-submitted-palvelu {
    text-align: left;
  }
  .form-item-submitted-palvelu {
    text-align: left;
    &.form-type-radio {
      input {
        display: none;
      }
    }
  }
  
  input#edit-submitted-paivamaara {
    visibility: hidden;
  }

  input#edit-submitted-kellonaika {
    cursor: pointer;
  }
}