.campaign-item {
    margin-bottom: 2rem;

    .campaign-banner {
        position: relative;

        .background {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            background-size: cover;
            overflow: hidden;
            display: flex;
            
            .image {
                @include media($mobile) {
                    display: none;
                }

                margin-left: 50%;
                height: 100%;

                img {
                    width: auto;
                    max-width: none;
                    max-height: 100%;
                }

            }
        }

        .content {
            word-break: break-word;
            padding: 2rem 1rem 2rem 1rem;

            @include media($medium-min) {
                width: 50%;
                padding: 5rem 1rem 5rem 0;
            }

            .content-image {
                margin-bottom: 1em;

                @include media($medium-min) {
                    display: none;
                }
            }

            .category {
                font-size: 1em;
                @include header-text();
                margin-bottom: 1em;

                a {
                    text-decoration: none;
                }
            }

            .title {
                @include header-text();
                font-size: 2em;
                line-height: 1.125em;
                margin-bottom: 1rem;

                @include media($large) {
                    font-size: 1.5em;
                }

                @include media($medium) {
                    font-size: 1.33em;
                }

                @include media($mobile) {
                    font-size: 1.25em;
                }
            }

            .description {
                font-size: 1em;
            }

            .buttons {
                display: flex;
                flex-wrap: wrap;
                margin-top: 3em;

                a {
                    @include header-text();
                    line-height: 1.2em;
                    // padding: 0.6em 0.8em 0.7em 0.6em;
                    padding: calc(0.6em + 3px) 0.8em 0.6em 0.8em;
                    margin: 0 1em 1em 0;
                    text-decoration: none;

                    @include media($mobile) {
                        text-align: center;
                    }
                }
            }

            
            @include media($mobile) {
                .buttons-wrapper {
                    margin-left: -2rem;
                    margin-right: -2rem;
                }
                
                .buttons {
                    overflow-x: auto;
                    margin-top: 1.5em;
                    padding: 0 2rem;
                    flex-wrap: nowrap;
                    white-space: nowrap;

                    a:last-child {
                        margin-right: 0;
                    }
                }
            }

        }
    }

    .campaign-products {
        padding: 2.5rem 0;
    }
}

section.block.block-block {
    .ratsula-text-campaign-banners {
        // padding-bottom: 16px;
        margin-bottom: 0;
    }
}

// #content section.block.block-ratsula-misc {
//     margin-top: 1em;
// }
// body section .ratsula-text-campaign-banners:first-of-type {
//     margin-top: 1em;
// }

#content-top-full-width {
    .ratsula-text-campaign-banners {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 1em;

        margin-top: 2em;
    }
}

.ratsula-text-campaign-banners {
    // margin: -1em-1em 1em -1em;
    margin: 0 -1em 0 -1em;

    // &:first-of-type {
    //     margin-top: 1em;
    // }

    .campaign-text-item {
        display: block;
        text-decoration: none;
        padding: 0.25rem 1em;

        @include media($mobile) {
            font-size: 0.8em;
            // color: $color-text-secondary !important;
        }

        &#to-free-delivery {
            background-color: #FFFFFF;
            border: 1px solid #828282;
            color: #505050;
            margin-bottom: 10px;
        }

        &:last-of-type {
            margin-bottom: 3em;
        }
    }

    @include media($medium-min) {
        margin-bottom: 1rem;

        .campaign-text-item {
            font-size: 16px;
            text-align: center;
            padding: 10px;
            line-height: 20px;
            margin-bottom: 1px;

            &:not([href]) {
                cursor: default;
            }
        }
    }
}