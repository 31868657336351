html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  background: $color-bg;

  &.no-scroll {
    overflow: hidden;
  }

  &.menu-open {
    #ratsula-search-app-mobile {
      display: none;
    }
  }

  &.page-node-21702 {
    #main {
      background: #fff4f4;
    }
  }

  #site-header {
    z-index: 5;
  }

  #page-header {
    z-index: 105;
  }

  main {
    z-index: 3;
    padding-top: $page-header-height-without-bottom;

    @include media($large) {
      padding-top: $page-header-height-large-screen;
    }

    @include media($medium) {
      padding-top: $page-header-height-medium-screen;
    }

    @include media($mobile) {
      padding-top: $page-header-height-mobile-screen;
    }

    #content-top {
      padding-top: 2em;
      padding-bottom: 2em;
      // background-color: $color-primary;
    }

    #content-wrapper {
      padding-top: 2em;
      padding-bottom: 2em;
      // background-color: $color-bg;

      @include media($mobile) {
        padding-top: 1em;
      }
    }

    #content-bottom {
      padding-top: 2em;
      padding-bottom: 2em;
      z-index: 1;
      // background-color: $color-bg;
    }

    .has-sidebar #content-wrapper {
      display: flex;

      #content-left {
        width: 25%;
        margin-right: $gutter;
      }

      #content {
        width: 75%;
      }

      @include media($medium) {
        #content-left {
          display: none;
        }

        #content {
          width: 100%;
        }

      }
    }
  }

  &.front {
    #page-header-background {
      // background-image: url('../img/R_Kansi_vk12.jpg');
      // background-size: cover;
      // height: 33.33em;
      overflow: hidden;
      position: relative;

      @include media($mobile) {
        // margin-top: 0;
        // height: 15em;
      }

      #page-header-backgrounds {
        // height: 100%;
        transition: 1s all;
        position: relative;
        display: flex;
        height: 500px;
        max-height: 50vh;

        .page-header-background-item {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          .page-header-background {
            height: 100%;
            background-size: cover;
            background-position: center;
          }
        }
      }

      .page-header-backgrounds-pager {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 2em;
        display: flex;

        .page-header-background-pager-item {
          margin: 0.25em;
          width: 0.4em;
          height: 0.4em;

          background-color: $color-white;
          border-radius: 1px;

          &.active {
            background-color: $color-primary;
          }
        }
      }

      .page-header-background-item {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .nuoli-vasen,
      .nuoli-oikea {
        position: absolute;
        top: 50%;
        background-repeat: no-repeat;
        cursor: pointer;

        height: 2.07em;
        width: 1.135em;
        margin-top: -1.035em;
      }

      .nuoli-vasen {
        left: 1em;
        background-image: url('../img/nuoli_vasen.png');
      }

      .nuoli-oikea {
        right: 1em;
        background-image: url('../img/nuoli_oikea.png');
      }
    }
  }

  &.admin-menu {
    main {
      // @include calc(padding-top, "#{$page-header-height-without-bottom} + 28px");

      // @include media($large) {
      //   @include calc(padding-top, "#{$page-header-height-medium-screen} + 28px");
      // }
      // @include media($mobile) {
      //   @include calc(margin-top, "#{$page-header-height-small-screen} + 28px");
      // }
    }
  }

  // &.admin-menu {
  //   #page-header-background {
  //       @include calc(margin-top, "#{$site-header-height} + 28px");
  //   }

  //   .has-header {
  //     #page-header-background {
  //       @include calc(margin-top, "#{$site-header-height} + #{$page-header-height} + 28px");

  //       @include media($mobile) {
  //         margin-top: 0;
  //       }
  //     }
  //   }
  // }
  // }
  // }
}

body.front {
  .rakkausleima {
    position: absolute;
    @include transition(all 0.8s);
    z-index: 1;

    @include calc(top, "#{$page-header-height-without-bottom} + 4em");
    right: 2em;
    width: 9em;

    @include media($large) {
      @include calc(top, "#{$page-header-height-medium-screen} + 4em");
      // bottom: 4em;
      // left: 4em;
    }

    @include media($mobile) {
      display: none;
      // bottom: 2em;
      // left: 2em;
      // width: 10em;
    }
  }
}

#breadcrumb {
  border: 1px solid $color-light;
  border-width: 0 0 1px 0;
  padding: 1em 0;

  a {
    text-decoration: none;
  }

  #breadcrumb-inner {
    display: flex;
  }

  .easy-breadcrumb {

    &,
    a {
      color: $color-text-secondary;
    }

    .easy-breadcrumb_segment-separator {
      padding: 0 0.5em;

      @include media($mobile) {
        padding: 0;
      }
    }

    .easy-breadcrumb_segment-title {
      color: $color-primary;
    }
  }

  @include media($mobile) {
    font-size: 0.75rem;
    padding: 0.45rem 0;
    margin: 0 0.25rem;

    #breadcrumb-inner {
      flex-wrap: wrap;
    }

    .block-easy-breadcrumb {
      width: 100%;
      margin-top: 0.5em;
    }
  }
}

.outer-wrapper {
  @include outer-container;

  padding: 0 1em;
}

.hidden {
  display: none !important;
}

#sliding-popup {
  .eu-cookie-compliance-more-button {
    font-family: 'Poppins';
    text-transform: unset;
    letter-spacing: normal;
    font-size: 12px;
  }

  .agree-button {
    padding: 0.75em;

    &:hover {
      background-color: $color-primary-active;
    }
  }

  .decline-button {
    color: $color-secondary;
    box-shadow: none;
    text-shadow: none;
    border: none;
    text-transform: none;
    letter-spacing: 0;
    font-family: 'Poppins';

    &,
    &:hover {
      background: none;
    }
  }
}

body.not-front {
  @include media($mobile) {
    // #page-header {
    //   position: static;
    //   margin-top: 5.334em;
    // }

    // &.admin-menu #page-header {
    //   margin-top: calc(29px + 5.334em) !important;
    // }

    // .has-header main {
    //   margin-top: $page-header-height-without-bottom;

    // }

    // &.admin-menu .has-header main {
    //   margin-top: calc(29px + #{$page-header-height-without-bottom}) !important;
    // }
  }
}

#messages {
  margin-top: 1em;
}

@media screen {
  .print-only {
    display: none !important;
  }
}

@media print {

  .hide-from-print,
  .grippie {
    display: none !important;
  }

  .show-when-print {
    display: block !important;
  }
}