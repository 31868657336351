@mixin header-text() {
    font-family: $heading-font-family;
    color: $color-text-primary;
}

@mixin normal-text() {
    font-family: $base-font-family;
}

@mixin heading-line() {
    text-align: center;
    overflow: hidden;
    font-size: 1.067em;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    position: relative;

    &::before, &::after {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        right: 50%;
        margin-right: 9em;
        top: 50%;
        height: 1px;
        background-color: $color-primary;
    }

    &:after {
        left: 50%;
        right: 0;
        margin-left: 9em;
        margin-right: 0;
    }
}

@mixin underlining() {
  padding-top: 2px;
  border-bottom: 2px solid transparent;
  
  &:active,
  &:focus,
  &:hover,
  &.active {
    border-bottom: 2px solid $color-primary;
  }
}

@mixin loader() {
  &.loader-triggered {
    position: relative;
    
    &::after {
      top: 0; left: 0;
      right: 0; bottom: 0;

      background-color: rgba(0, 0, 0, 0.4);
      background-image: url('../img/Loader.svg');
      background-size: 3em;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 10;

      content: ' ' !important;
      display: block !important;
      
    }
  }
}

@mixin ratsula-columns($span: $columns of $container-columns, $display: block) {
  $columns: nth($span, 1);
  $container-columns: container-span($span);

  $parent-columns: get-parent-columns($container-columns) !global;

  $direction: get-direction($layout-direction, $default-layout-direction);
  $opposite-direction: get-opposite-direction($direction);

  $display-table: is-display-table($container-display-table, $display);

  @if $display-table  {
    display: table-cell;
    width: percentage($columns / $container-columns);
  } @else {
    float: #{$opposite-direction};

    @if $display != no-display {
      display: block;
    }

    @if $display == collapse {
      @include -neat-warn("The 'collapse' argument will be deprecated. Use 'block-collapse' instead.");
    }

    @if $display == collapse or $display == block-collapse {
      width: flex-grid($columns, $container-columns) + flex-gutter($container-columns);

      &:last-child {
        width: flex-grid($columns, $container-columns);
      }

    } @else {
      padding-left: flex-gutter($container-columns) / 2;
      padding-right: flex-gutter($container-columns) / 2;
      width: ratsula-grid($columns, $container-columns);
    }
  }
}

@function ratsula-grid($columns, $container-columns) {
    @return percentage($columns / $container-columns);
}

@mixin ratsula-row() {
    @include row();

    padding-left: -($gutter / 2);
    padding-right: -($gutter / 2);
}

@mixin clearfix-before() {
  &::before {
    display: table;
    content: ' ';
    clear: both;
  }
}

@mixin valikko-item() {
  @include display(flex);
  @include flex-wrap(wrap);
  justify-content: center;
  text-align: center;
  line-height: 1em;

  li {
      display: block;
      float: left;
      margin: 0.5em 1em;
      padding: 0;
      background-color: $color-secondary;

      a {
        display: block;
        background-color: transparent;
        color: $color-primary;
        text-decoration: none;
        padding: 0.2em 0.5em;
        border: none;
        font-weight: $font-weight-medium;

        &.active {
          border: none;
          margin-bottom: 0;
          background-color: $color-primary;
          color: $color-secondary !important; 
          text-decoration: none;
        }
      }

      &.active-trail, &:hover {
          background-color: $color-primary;
          a, a:hover, a.active {
              color: $color-secondary !important; 
              text-decoration: none;
          }
      }
  }
}