.node-type-floor .ds-2col > div {
    @include span-columns(6);
    @include omega(2);

    @include media($mobile) {
        @include span-columns(12);
        @include omega(1n);
    }

    margin-bottom: 2em;
}

.ds-1col .field-name-title {
    @include header-text();

    text-align: center;
}

.thinner-on-large-screen,
.node-webform,
.entitytype-card_cash-form,
.entitytype-card_account-form,
.entitytype-parallel_card-form #edit-field-rinnakkaiskortti,
.entitytype-parallel_card-form #edit-field-rinnakkaiskortti-user,
#card-cash-entityform-edit-form,
#card-account-entityform-edit-form {
    @include media($large-min) {
        @include span-columns(8);
        @include shift(2);
    }
    @include media($medium-only) {
        @include span-columns(10);
        @include shift(1);
    }
}

.ds-1col {
    &.node-palveluesittely, &.node-page {
        @include media($large-min) {
            .field-name-body {
                @include span-columns(8);
                @include shift(2);
            }
        }
        @include media($medium-only) {
            .field-name-body {
                @include span-columns(10);
                @include shift(1);
            }
        }
    }
}

.node-type-tuote {
    .node-tuote {
        .group-left {
            min-height: 1px;
            padding-right: $gutter;
            width: 60%;
            position: relative;

            .sale-container {
                position: relative;
                
                .sale {
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 3;

                    .sale-text {
                        letter-spacing: 1px;
                        font-size: 1em;
                        margin: 0;
                        padding: 0.5em 1em;
                        color: #FFE5DC !important;
                        border-radius: 2px;
                    }
                }

                &.season-campaign .sale-text {
                    background-color: #18355E !important;
                }
            }
            @include media($mobile) {
                float: none;
                padding-right: 0;
                width: 100%;
            }

            .field-name-field-media > .field-items {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -1em;

                > .field-item {
                    margin: 1em;
                }
            }

            img {
                height: auto;
            }
            
            .field-collection-view {
                padding: 0;
                margin: 0;
            }

            .field-collection-container {
                margin-bottom: 0;
                border: none;
            }
        }

        .group-right {
            width: 40%;

            @include media($mobile) {
                float: none;
                width: 100%;
            }

            .field {
                margin-bottom: 1em;
            }

            .field.field-name-field-tuote {
                margin-bottom: 3em;
            }
        }

        .field-name-title h1 {
            font-size: 1.5em;
            font-family: $base-font-family;
            color: $color-text-primary;
            text-align: left;

            margin: 1.125em 0;
        }

        .product-price-discounted {
            display: flex;
        }

        .product-price-discounted, .product-price-discounted-to, .product-price-discount-coupon {
            font-size: 0.875rem;
        }

        .product-price-discounted-to {
            // color: $color-primary;
            margin-left: 1.5em;
        }

        .product-price-value {
            margin-bottom: 0.5em;
            font-size: 2em;
            font-family: $heading-font-family;

            .rs-ovh-price {
                font-size: 1.25rem;
            }

            &.product-on-sale {
                margin-bottom: 0;

                .rs-price {
                    color: $color-primary;
                    margin-right: 0.5em;
                }

                .rs-ovh-price {
                    color: #505050;
                    text-decoration: line-through;
                }
            }
        }

        .has-mr-price .product-price-value {
            font-size: 1.5em;
        }

        .mr-product-price {
            margin-bottom: 1em;
            color: $color-primary;

            .mr-product-price-title {
                font-family: $heading-font-family;
                text-transform: uppercase;
                
                .mr-product-price-info {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    border: 1px solid $color-primary;
                    border-radius: 50%;
                    margin-left: 0.5em;
                    text-transform: lowercase;
                    font-family: $base-font-family;
                    font-weight: bold;
                    cursor: default;
                    font-size: 14px;
                }
                .mr-product-price-info-details {
                    font-family: $base-font-family;
                    text-transform: initial;
                    font-size: 0.9em;
                }
            }

            .mr-product-price-value {
                color: $color-primary;
                font-size: 2em;
                font-family: $heading-font-family;
            }
        }
        .field-name-toimittajan-nimi {
            font-weight: 300;
        }

        .stock-status {
            margin: 0 0 10px 0;
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            color: #505050;
            padding-top: 5px;
        }
    }
}

.field-name-toimitusehdotjakulut {
    line-height: 1.5em;
    padding: 0.6em;
    border: 1px solid #979797;

    p {
        margin-bottom: 0;
    }
}