#page-menu, #page-menu-aspa {
    // z-index: 95;
    z-index: 200;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    // background-color: #222A;
    top: 0;

    // top: $page-header-height-without-bottom;

    @include media($large) {
        // top: $page-header-height-large-screen;
    }

    @include media($medium) {
        // top: $page-header-height-medium-screen;
    }

    @include media($mobile) {
        top: calc(#{$page-header-height-mobile-screen} - 30px);
        z-index: 100;
    }

    #page-menu-inner, #page-menu-aspa-inner {
        box-shadow: 1px 15px 10px 2px rgba(0, 0, 0, 0.13);
        padding-top: 0em;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        width: 500px;
        max-width: 100%;
        background-color: $color-white;

        display: flex;
        flex-direction: column;

        @include media($mobile) {
            right: auto;
            left: 0;
        }

        .outer-wrapper {
            width: 100%;
            overflow: auto;
            flex: 1;
            padding-top: 0;
            padding: 35px 20px;
        }

        @include media($mobile) {
            .outer-wrapper {
                padding: 0 20px 35px 20px;
            }
        }

        #nav-misc {
            margin-bottom: 50px;

            .nav-misc-row {
                display: flex;
                justify-content: space-between;

                div:last-of-type {
                    text-align: right;
                }
            }
        }

        #nav-social {
            // padding: 0.5em 1em;
            display: flex;
            justify-content: start;
            margin-bottom: 20px;

            a {
                display: block;
                padding: 0.25em;
                text-decoration: none;
            }

            img {
                height: 20px;
                width: 20px;
            }
        }

        #nav-policy {
            padding-bottom: 5px;
            div {
                text-align: left;

                a {
                    color: #505050;
                }
            }
        }
        #nav-payments {
            padding-bottom: 20px;
        }

        >.menu-close {
            position: fixed;
            right: 1em;
            top: 1em;
            height: 3em;
            width: 3em;
            cursor: pointer;
        }

        .bottom {
            text-align: center;
            margin-top: 60px;

            a {
                color: $color-text-primary;
            }
        }

        .page-menu-close {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin-bottom: 30px;

            .page-menu-close-button {
                cursor: pointer;
            }
        }

        @include media($mobile) {
            .page-menu-close:not(.page-menu-aspa-close) {
                display: none;
            }
        }
    }

    #page-menu-aspa-inner {
        background-color: $color-primary;

        h2 {
            display: none;
        }

        .aspa-menu-divider {
            padding: 19px;
        }

        ul.menu li {
            border-top: none;
            border-bottom: 1px solid #fff;

            a {
                color: $color-white;
            }
        }
        
        .aspa-menu-links {
            a {
                color: $color-white;
                font-size: 1em;
                letter-spacing: 1px;
                line-height: 30px;
            }
        }

        @include media($mobile) {
            .outer-wrapper {
                padding: 35px 20px 35px 20px;
            }
        }

    }

    ul li {
        text-align: left;
        border-top: 1px solid $color-primary-light;

        a {
            display: block;
            @include header-text();

            text-transform: uppercase;
            text-decoration: none;
            font-size: 1.3em;
            padding: 0.667em 0;
            color: $color-secondary;

            @include media($mobile) {
                font-size: 1em;
                padding: 0.5em 0;
            }
        }
    }


    .block-menu-block {
        li {
            width: 100%;

            .menu-item-row {
                display: flex;
                align-items: center;

                a {
                    text-decoration: none;
                    display: block;
                    color: $color-secondary;
                }

                a:first-child {
                    flex: 1;
                }
            }

            .menu-item-collapser {
                width: 2em;
                height: 2em;
                background-image: url('../img/nuoli-alas-vector-secondary.svg');
                background-size: 1em 1em;
                background-position: center;
                background-repeat: no-repeat;
            }

            &.opened {
                display: block;

                >.menu {
                    display: block;
                }

                >.menu-item-row .menu-item-collapser {
                    transform: rotateX(180deg);
                    ;
                }
            }
        }

        .menu .menu {
            display: none;

            li a {
                font-family: $base-font-family;
                padding-left: 0.5em;
                text-transform: initial;
            }

            .menu {
                li a {
                    padding-left: 1em;
                }

                .menu {
                    li a {
                        padding-left: 1.5em;
                    }

                    .menu {
                        li a {
                            padding-left: 2em;
                        }
                    }
                }
            }
        }
    }
}
#page-menu-aspa {
    @include media($mobile) {
        top: calc(#{$page-header-height-mobile-screen} - 42px);
        z-index: 200;
    }
}

body.admin-menu {
    #page-menu {
        top: calc(28px);

        #page-menu-inner>.menu-close {
            top: calc(1em + 28px);
        }
    }
}

.minun-ratsula-valikko {
    margin-bottom: 60px;

    ul {
        @include valikko-item();
    }
}

ul.tabs.primary {
    border-bottom: none;
    @include valikko-item();
}

.alasivu-valikko, .aspa-valikko {
    margin: 2em 0;
    text-align: center;
    // text-align: left;
    // max-width: 800px;
    
    @include media($medium) {
        // max-width: 50%;
        
        ul.menu {
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
            overflow-x: scroll;

            li {
                a {
                    white-space: nowrap;
                }
            }
        }
    }
    ul {
        text-align: left;

        li {
            list-style: none;
            display: inline-block;
            margin: 0 10px 10px 0px;
            
            &.first {
                margin-left: 0;
            }
    
            a {
                background-color: $color-primary-contrast;
                font-size: 1em;
                color: $color-primary;

                text-align: center;
                display: block;
                text-transform: uppercase;
                text-decoration: none;
    
                padding: 0.3em 0.7em;

                border: 1px solid $color-primary;
    
                &:hover,
                &.active {
                    color: $color-primary-contrast;
                    background-color: $color-primary;
                }
            }
        }
    }
    @include media($mobile) {
        max-width: 100%;
    }
}
.minun-ratsula-secondary-menu {
    float: right;
    margin-top: 0.35em;
    padding: 0 0 20px 20px;

    a {
        border: 1px solid #505050;
        text-transform: uppercase;
        font-size: 1em;
        color: #505050;
        margin: 0 0 10px 10px;
        text-decoration: none;
        padding: 0.3em 0.7em;
        
        &:hover {
            background-color: #505050;
            color: #FFF;
        }
    }
    @include media($mobile) {
        float: none;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        width: 100%;
        padding: 0;

        a {
            display: inline-block;
            white-space: pre;
            margin: 0 10px 10px 0;
        }
    }
}

body.page-has-background .alasivu-valikko {
    // padding-bottom: 1em;
    // padding-top: 0;
}

.page-menu-mobile-top {
    display: flex;
    align-items: center;
    margin: 1em 1em 2em 1em;

    a {
        color: #FFF;
    }

    >div,
    >a {
        flex: 1;
    }

    .user-img {
        display: flex;
        align-items: center;

        img {
            height: 1.27em;
            margin-right: 0.5em;
        }
    }

    #ratsula-search-app-menu {
        text-align: center;

        a {
            text-decoration: none;
        }

        .search-icon {
            margin-right: 0;
            justify-content: center;
        }
    }

    .menu-cart {
        text-align: right;
        text-decoration: none;

        display: flex;
        align-items: center;
        justify-content: flex-end;

        .cart-img {
            margin-right: 0.5em;
            position: relative;

            .cart-item-count {
                position: absolute;
                top: -7px;
                right: -7px;
                font-size: 0.6rem;
                font-weight: 700;
                background: #ffe5dc;
                border-radius: 100%;
                display: block;
                width: 1rem;
                height: 1rem;
                text-align: center;
                line-height: 1rem;
                color: #8c0b2d;
            }
        }
    }
}

.menu-info {
    margin: 2em 0;

    &.menu-top-info {
        margin: 0.3em 0 0.3em 0;
    }

    &,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a {
        color: $color-text-primary;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Poppins', sans-serif;
        font-size: 1.2em;
        text-align: left;
        font-weight: 600;
    }

    .menu-opening-hours {
        display: none;
    }

    .menu-info-details {
        display: none;

        padding-top: 1em;
        padding-bottom: 2em;

        .days table {
            margin-top: 0;
        }
    }

    .open .menu-info-details {
        display: block;
    }

    .menu-asiakaspalvelu-details {
        p {
            strong {
                display: none;
            }
        }
    }

    tr {

        td,
        th {
            padding: 0.25em 0.25em 0.25em 0;
        }

        &:hover {

            td,
            th {
                background: transparent !important;
            }
        }
    }

    .gomee-lunch-title {
        float: right;
    }

    .gomee-title.toggle-label {
        a {
            text-decoration: none;
        }
    }

    .toggle-label {
        height: 100%;
        line-height: 1.8em;
        cursor: pointer;

        .toggle {
            display: block;
            text-decoration: none;
            width: 1.2em;
            background-image: url('../img/nuoli-alas-vector-black.svg');
            // background-size: 100% auto;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 1.1em 1.1em;
            transition: 0.5s all;
            float: right;
        }
    }

    .open .toggle-label .toggle {
        background-image: url('../img/nuoli-ylos-vector-black.svg');
    }

    .menu-lahjakortti-link {
        display: block;
        margin-top: 2em;
        text-align: center;

        img {
            display: block;
            margin: 0 auto 1em auto;
        }
    }
}

.main-menu-section {
    // margin-top: 50px;
}

.menu-lowermenu-item {
    a {
        text-decoration: none;
    }
}