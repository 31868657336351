.header-cart {
    display: flex;
    align-items: center;

    margin-right: 2em;

    .cart-img {
        display: flex;
        align-items: center;
        position: relative;

        .cart-item-count {
            position: absolute;
            top: -7px;
            right: -7px;
            font-size: 0.6rem;
            font-weight: 700;
            background: #ffe5dc;
            border-radius: 100%;
            display: block;
            width: 1rem;
            height: 1rem;
            text-align: center;
            line-height: 1rem;
            color: #8c0b2d;
        }
    }

    .price-total {
        padding-left: 0.33em;
    }
}

.ratsula-banner-area {
    position: relative;
    width: 200px;
    height: 35px;
    overflow: hidden;
}

.ratsula-banner-area::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 900;
    display: block;
    width: 100%;
    height: 100%;
}

.ratsula-banner_part {
    color: #505050;
    font-size: 0.9375em;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 7;

    transform: translateX(200px);

    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    width: 200px;
    height: 35px;
    line-height: 35px;
    text-align: right;

    /* animation: opaqTransition 28s cubic-bezier(0, 0, 0, 0.97) infinite; */
    // animation: opaqTransition 20s cubic-bezier(0, 0, 0, 0.97) infinite;
    animation: opaqTransition 20s ease-in infinite;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}


.ratsula-banner_part.ratsula-banner_part-two {
    z-index: 6;
    animation-delay: 5s;
    padding-right: 8px;
}

.ratsula-banner_part.ratsula-banner_part-three {
    z-index: 5;
    animation-delay: 10s;
}

.ratsula-banner_part.ratsula-banner_part-four {
    z-index: 4;
    animation-delay: 15s;
}

@include media($medium-min) {
    .ratsula-banner-area {
        width: 300px;
    }

    .ratsula-banner_part {
        width: 300px;
        transform: translateX(300px);
    }
}

@keyframes opaqTransition {
    3% {
        transform: translateX(0);
    }

    21% {
        transform: translateX(0);
    }

    23% {}

    27% {
        transform: translateX(-105%);
        // transform: translateX(-230px);
    }

    100% {
        transform: translateX(-120%);
    }
}

body.taxonomy-term-type-tuoteryhm_t {
    .outer-wrapper {
        max-width: 1400px;
    }

    h1 {
        text-align: left;
        margin-bottom: 0;
    }

    @include media($large-min) {

        h1,
        div.shop-text {
            margin-left: -1rem;
            margin-right: -1rem;
        }
    }

    // @include media($medium) {
    //     #content-left {
    //         display: none;
    //     }

    //     #content-right {
    //         width: 100%;
    //     }
    // }
}

@include media($mobile) {

    body.taxonomy-term-type-tuoteryhm_t,
    body.page-node-5155 {
        #breadcrumb {
            display: none;
        }
    }

    body.taxonomy-term-type-tuoteryhm_t,
    body.page-node-5155,
    .node-type-tuote {
        #breadcrumb {
            .go-back-link {
                display: none;
            }

            .block-easy-breadcrumb {
                margin-top: 0;
            }
        }

        #main-right>.outer-wrapper {
            padding-left: 1em;
            padding-right: 1em;
        }
    }
}

@include media($medium) {
    #ratsula-shop-page-title {
        color: #505050;
        font-size: 1.125rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.shop-text {
    font-size: 0.9em;
    // margin-bottom: 2em;
    color: #902d47;
    font-style: italic;
}

.horizontal-shop-list {
    div.sale {
        &.double-bonus {
            left: 5px;
            right: 5px;

            div.sale-text {
                font-family: inherit;
                font-weight: 400;
            }
        }
    }
}

.shop-list {
    .shop-products {
        position: relative;
        display: grid;

        min-height: 10em;

        // $colWidth: calc(25% - (#{$gutter} * 0.75));
        // grid-template-columns: repeat(auto-fit, $colWidth);
        $colWidth: calc(33.333%);
        grid-template-columns: repeat(auto-fit, $colWidth);
        column-gap: 0;
        // column-gap: $gutter;
        // row-gap: $gutter;
        row-gap: 0;
        // margin: $gutter 0;
        margin-left: -1em;
        margin-right: -1em;

        @include media($large) {
            // $colWidth: calc(33.333% - 1.34em);
            // grid-template-columns: repeat(auto-fit, $colWidth);
        }

        @include media($medium) {
            // column-gap: 1em;
            // row-gap: 1em;
        }

        @include media($mobile) {
            // $colWidth: calc(50% - 3px);
            $colWidth: calc(50%);
            grid-template-columns: repeat(auto-fit, $colWidth);

            // column-gap: 3px;
            // row-gap: 2em;

            .views-row {
                padding: 0.5em;
            }
        }

        @include media($mobile-small) {
            grid-template-columns: 100%;
        }
    }

    .shop-products-count {
        color: #505050;
    }

    .shop-no-results {
        top: 1em;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
    }

    .shop-loading {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #fffb;

        font-size: 2.5em;
        text-align: center;
        padding: 1em;
    }

    .shop-product {
        display: block;
        text-align: center;
        cursor: pointer;
        position: relative;
        text-decoration: none;
        color: #000;
        // max-width: 232px;
        max-width: 100%;
        margin: 0 auto;
        background-color: #FFFFFF;
        width: 100%;
        padding: 10px 10px 20px 10px;
        // border: 1px solid #F1F1F1;
        border-bottom: 1px solid #EEF0F7;
        border-left: 1px solid #EEF0F7;

        &:nth-of-type(3n) {
            border-right: 1px solid #EEF0F7;
        }

        &:nth-of-type(1) {
            border-top: 1px solid #EEF0F7;
        }

        &:nth-of-type(2) {
            border-top: 1px solid #EEF0F7;
        }

        &:nth-of-type(3) {
            border-top: 1px solid #EEF0F7;
        }

        &:last-of-type {
            border-right: 1px solid #EEF0F7 !important;
        }

        // @include media($medium) {
        //     &:nth-of-type(3n) {
        //         border-right: none;
        //     }
        //     &:nth-of-type(2n) {
        //         border-right: 1px solid #F1F1F1;
        //     }
        //     &:nth-of-type(3) {
        //         border-top: none;
        //     }
        //     &:last-of-type {
        //         border-right: 1px solid #F1F1F1!important;
        //     }
        // }

        @include media($mobile) {
            &:nth-of-type(3n) {
                border-right: none;
            }

            &:nth-of-type(2n) {
                border-right: 1px solid #EEF0F7;
            }

            &:nth-of-type(3) {
                border-top: none;
            }
        }


        .image {
            height: auto;
            display: block;
            width: 100%;
            position: relative;
            background-color: #FFFFFF;

            img {
                pointer-events: none;
                display: block;
                margin: 0 auto;
                padding: 10px;
            }

            .product-sizes {
                pointer-events: none;
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                background: white;
                opacity: 1;
                font-size: 0.9em;
                z-index: 2;
                text-align: center;
                color: #505050;
            }

            .product-size {
                display: inline-block;
                padding: 0.5em 0.25em;

                &.not-in-stock {
                    text-decoration: line-through;
                    color: #828282;
                }
            }
        }

        .product-texts {
            margin: 0 0.5em;
        }

        .product-brand {
            font-family: $base-font-family;
            word-break: break-word;
            font-weight: 400 !important;

            font-size: 0.9em;

            @include media ($mobile) {
                // font-size: 0.75em;
            }
        }

        .product-name {
            font-weight: 500;
            font-size: 1em;

            @include media ($mobile) {
                // font-size: 0.75em;
            }
        }

        .product-colors {
            display: flex;
            justify-content: center;
            max-width: 11em;
            margin: 15px auto 4px auto;

            &.has-sizes {
                justify-content: center;
            }


            .product-colors-inner {
                display: flex;
                flex-wrap: wrap;
                gap: 0.3em;
            }

            .product-colors-wrapper {
                position: relative;
            }

            .product-colors-sizes {
                line-height: 24px;
                font-size: 0.8rem;
                color: #000000;
            }
        }

        .product-color {
            display: block;
            cursor: pointer;
            position: relative;
            border-radius: 100%;
            width: 17px;
            height: 17px;
            // width: 1.25em;
            // height: 1.25em;
            // border: 1px solid rgba(255,255,255,0);
            border: 1px solid #F1F1F1;
            text-align: center;
            padding-bottom: 1em;

            &.selected,
            &:hover {
                border: 1px solid #000000;
            }

            .color-image-container {
                overflow: hidden;
                border-radius: 100%;

                img {
                    display: block
                }
            }

            &.out-of-stock {
                // opacity: 0.5;
            }

            svg {
                position: absolute;
                top: -2px;
                left: -2px;
                right: -2px;
                bottom: -2px;
                max-width: calc(100% + 4px);
                max-height: calc(100% + 4px);
            }
        }

        .product-colors-wrapper.white .product-color:not(.selected):not(:hover) {
            border-color: #aaa;
        }

        .product-brand,
        .product-name,
        .product-price {
            text-align: left;
            color: #505050;
        }

        .product-brand {
            text-transform: capitalize;
            font-weight: 600;
        }

        .product-name {
            margin-bottom: 10px;
            color: #000000;
        }

        .product-price {
            display: flex;
            font-size: 0.85rem;
            justify-content: center;
            align-items: flex-end;
        }

        .product-price span.ovh {
            color: #000000;
        }

        .product-price span.ovh.og-price {
            color: #505050;
            font-weight: 400;
            font-size: 14px;
        }

        div.sale {

            div.sale-text {
                background-color: #8c0b2d;
                padding: 3px 6px;
                font-size: 0.75rem;
                display: flex;
                font-weight: bold;
            }

            &.double-bonus {
                div.sale-text {
                    font-family: inherit;
                    font-weight: 400;
                }
            }
        }

        .promoted {
            position: absolute;
            left: 0;
            right: 0;
            top: calc(-1.3em - 8px);
            text-align: center;
            z-index: 1;

            @include media($mobile) {
                top: calc(-0.5em - 8px);
            }

            img {
                width: auto;
                margin: 0 auto;
            }
        }

        .sale {
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 1;
            line-height: 1.125em;

            &.left {
                right: auto;
                left: 5px;
            }

            .sale-text {
                letter-spacing: 1px;
                font-size: 1em;
                margin: 0;
                padding: 0.5em 1em;
                color: $color-secondary !important;
                border-radius: 2px;

                flex-direction: column;
                justify-content: center;
                line-height: 1em;
                height: 30px;

                &:hover {
                    background-color: $color-primary;
                }

                .sale-dates,
                .sale-even {
                    font-size: 0.75em;
                    font-weight: 400;
                    letter-spacing: 0;
                    text-transform: lowercase;
                    font-family: 'Poppins', sans-serif;
                }

                .sale-even {
                    text-transform: uppercase;
                }
            }
        }

        &.season-campaign {
            .sale-text {
                background-color: #18355E !important;
            }
        }

        .sale.outlet {
            .sale-text {
                background-color: #000000 !important;
            }
        }

        .not-in-stock-label {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $color-secondary;
            color: $color-primary !important;
            font-size: 0.8em;
            padding: 0.5em 1em;
            text-transform: uppercase;
        }

        .mr-price-tag {
            position: absolute;
            right: 5px;
            top: 5px;
            z-index: 1;

            img {
                max-width: 3rem;
            }
        }

        .mr-price {
            margin: 0.5em 0;

            img {
                display: block;
                margin: 0 auto;
            }
        }

        .product-price {
            font-weight: 500;
            color: $color-text-primary;
            font-size: 1em;

            &.on-sale {
                color: $color-primary;
                flex-wrap: wrap;

                .ovh {
                    color: $color-text-primary;
                }
            }
        }

        .product-tags {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 0.6em;
            margin-top: 0.5em;

            .product-tag {
                max-height: 2em;
                max-width: 2em;
            }
        }
    }


    .shop-more {
        text-align: center;
        padding: 2em 0;
    }

    &.horizontal-shop-list {
        position: relative;

        &.loading {
            height: 16.75rem;
        }

        .shop-loading {
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;

            img {
                background-color: #fffffff2;
                border-radius: 50%;
            }
        }

        display: flex;
        padding: 0 0.9em;
        overflow-x: auto;

        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .shop-product {
            margin: 0.9em;
        }

        .image {
            height: 10rem;
            width: 7.8rem;

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }

        .brand {
            margin-top: 1rem;
            font-size: 0.875em;
            font-weight: 500;
        }

        .product-price {
            margin-top: 1rem;
            font-size: 0.875em;
        }
    }
}

// section:not(.full-width) {
//     @include span-columns(4);

//     @include media($fhd-min) {
//       &.ostohyvitys, &.edut {
//         margin-right: 0;
//       }
//     }

//     @include media($large) {
//       @include span-columns(6);

//       &.bonussaldo, &.minun-ratsulani {
//         margin-right: 0;
//       }

//       &.ostohyvitys, &.edut {
//         @include shift(3);
//         @include row();
//       }
//     }

//     @include media($mobile) {
//       @include span-columns(12);
//       @include omega('1n+1');

//       &.ostohyvitys, &.edut {
//         @include shift(0);
//       }
//     }
//   }
.shop-compatible-products .shop-list .shop-products {
    position: relative;
    display: grid;

    min-height: 10em;

    $colWidth: calc(20%);
    grid-template-columns: repeat(auto-fit, $colWidth);
    column-gap: 0;
    // column-gap: $gutter;
    // row-gap: $gutter;
    row-gap: 0;
    // margin: $gutter 0;
    margin-left: -1em;
    margin-right: -1em;

    @include media($large) {
        $colWidth: calc(33.33%);
        grid-template-columns: repeat(auto-fit, $colWidth);
    }

    @include media($medium) {
        // column-gap: 1em;
        // row-gap: 1em;
    }

    @include media($mobile) {
        // $colWidth: calc(50% - 3px);
        $colWidth: calc(50%);
        grid-template-columns: repeat(auto-fit, $colWidth);

        // column-gap: 3px;
        // row-gap: 2em;

        .views-row {
            padding: 0.5em;
        }
    }

    @include media($mobile-small) {
        grid-template-columns: 100%;
    }
}

.is-shop {
    h1 {
        text-align: left;
    }
}

.field-type-addressfield {
    #customer-profile-shipping-commerce-customer-address-add-more-wrapper {
        div.form-item {
            margin-top: 0!important;
            margin-bottom: 0!important;
        }
    }
    #edit-customer-profile-shipping-field-puhelin {
        margin-top: -15px;
    }
}

.ratsula-shop-category-menu {
    overflow-y: auto;
    padding-right: 5px;

    &::-webkit-scrollbar {
        margin-left: 5px;
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f4f4f4;
    }

    &::-webkit-scrollbar-thumb {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
        // background: #888;
        // background: #f1f1f1;
        background: #cfcfcf;
    }
}

.ratsula-shop-category-menu,
.ratsula-shop-category-menu-filter {
    >li {
        margin-bottom: 1em;

        >.menu-item-row {
            font-size: 0.9em;
            font-family: 'Ratsula', sans-serif;

            .menu-item-collapser {
                display: none;
            }
        }

        >.ratsula-shop-category-submenu {
            .ratsula-shop-category-submenu {
                display: none;

                li {
                    padding-left: 1em;
                }
            }
        }

        &>.menu-item-row,
        >ul>li>.menu-item-row {
            border-bottom: 1px solid $color-light;
            margin-bottom: 8px;
        }
    }

    li {
        width: 100%;

        .menu-item-row {
            display: flex;
            align-items: center;
            margin-bottom: 0;

            a {
                text-decoration: none;
                display: block;
                color: $color-text-secondary;

                &:hover {
                    color: $color-text-primary;
                }

                &:first-child {
                    flex: 1;
                    height: 2em;
                    line-height: 2em;
                }
            }
        }

        .menu-item-collapser {
            width: 2em;
            height: 2em;
            background-image: url('../img/nuoli-alas-vector.svg');
            background-size: 1em 1em;
            background-position: center;
            background-repeat: no-repeat;
        }

        &.opened {
            display: block;

            >.ratsula-shop-category-submenu {
                display: block;
            }

            >.menu-item-row .menu-item-collapser {
                transform: rotateX(180deg);
                ;
            }
        }
    }
}

#page-menu .ratsula-shop-category-page-menu {
    .ratsula-shop-category-submenu {
        display: none;
    }

    li {
        width: 100%;

        .menu-item-row {
            display: flex;
            align-items: center;

            a {
                text-decoration: none;
                display: block;
                color: $color-secondary;
            }

            a:first-child {
                flex: 1;
            }
        }

        .menu-item-collapser {
            width: 2em;
            height: 2em;
            background-image: url('../img/nuoli-alas-vector-secondary.svg');
            background-size: 1em 1em;
            background-position: center;
            background-repeat: no-repeat;
        }

        &.opened {
            display: block;

            >.ratsula-shop-category-submenu {
                display: block;
            }

            >.menu-item-row .menu-item-collapser {
                transform: rotateX(180deg);
                ;
            }
        }
    }

    .ratsula-shop-category-submenu .ratsula-shop-category-submenu {
        li .menu-item-row a {
            font-family: $base-font-family;
            padding-left: 0.5em;
            text-transform: initial;
        }

        .ratsula-shop-category-submenu {
            li .menu-item-row a {
                padding-left: 1em;
            }

            .ratsula-shop-category-submenu {
                li .menu-item-row a {
                    padding-left: 1.5em;
                }

                .ratsula-shop-category-submenu {
                    li .menu-item-row a {
                        padding-left: 2em;
                    }
                }
            }
        }
    }
}

#content-left {
    min-height: 600px;
}

.block-ratsula-shop {
    position: static;
    flex: 1;
}

.ratsula-shop-category-header-menu {
    display: flex;

    .menu-item-row {
        display: block;
    }

    .mtt {
        flex: 1;
        text-align: right;
    }

    .shop-link-cart {
        margin-top: 2px;
    }

    >li {
        margin-right: 1.25em;

        &:last-child {
            margin-right: 0;
        }

        >.menu-item-row {
            font-family: $heading-font-family;
            font-size: 1.125em;

            a {
                color: $color-text-secondary !important;
                display: block;
                height: 3em;
                line-height: 3em;

                &.active,
                &:hover {
                    color: $color-primary !important;
                }
            }
        }

        >ul>li {
            >.menu-item-row {
                font-family: $heading-font-family;
                margin-bottom: 0.5em;
            }

            >ul>li {
                margin-bottom: 0.5em;

                &:last-child {
                    margin-bottom: 0;
                }

                >.menu-item-row {
                    font-weight: 600;
                }
            }
        }

        .menu-item-row a {

            &.active,
            &:hover {
                >.menu-item-row a {
                    color: $color-primary !important;
                }
            }
        }

        &:hover {
            >ul {
                display: block;
            }
        }

        >ul {
            display: none;
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            z-index: 1;
            flex-wrap: wrap;
            column-count: 5;

            background: $color-bg;
            box-shadow: 0 5px 4px 0px #0000003b;
        }
    }

    >li>.ratsula-shop-category-header-submenu>li {
        padding: 1em;
        break-inside: avoid;
    }
}

.form-type-phonefield-field {
    .description {
        display: none;
    }

    >label {
        // margin-bottom: 0;
        margin-top: 1em;
    }

    .form-item {
        margin-top: 0;
    }
}

.checkout-buttons {
    .button-operator {
        display: none;
    }

    .checkout-cancel,
    .checkout-back {
        padding: 0;
        color: $color-primary;
        font-size: 1em;
        font-weight: normal;
        text-transform: initial;

        &:hover,
        &:focus {
            color: $color-primary-active;
        }
    }
}

.view-commerce-cart-form,
.view-commerce-cart-summary,
.page-minun-ratsula-tilaukset {
    // max-width: 600px;
    // margin-left: auto;
    // margin-right: auto;

    &.view-commerce-cart-summary {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
    }

    .commerce-price-formatted-components {
        width: auto;
        margin-left: 0;

        .component-title {
            padding-left: 0;
        }
    }

    .line-item-summary {
        // margin-right: 4em;
        text-align: left;
        font-family: 'Ratsula';
    }

    .line-item-total-label {
        font-weight: normal;
        display: inline-block;
        margin-right: 2em;
    }

    .views-row {
        margin-bottom: 2em;
        padding-bottom: 2em;
        border-bottom: 1px solid #f1f1f1;

        &.view-row-last {
            border-bottom: none;
        }
    }

    #edit-actions {
        margin-top: 2em;
        display: flex;
    }

    #edit-submit {
        // color: $color-text-secondary !important;
        // background: $color-primary-contrast !important;
        color: #000;
        background-color: unset !important;
        font-family: $base-font-family;
        letter-spacing: inherit;
        text-transform: capitalize;
        font-weight: normal;
        font-size: 0.9em;
        text-decoration: underline;
        padding-left: 0;
        text-align: left;
    }

    #edit-checkout {
        // #edit-submit {
        font-weight: normal;
        font-size: 1em;
        font-family: $heading-font-family;
        letter-spacing: 1px;

        &:hover {
            background: #750825;
        }

        // width: 100%;
    }
}

.ratsula-line-item-delete {
    margin: 1em 0;
    width: 1em;

    .delete-line-item {
        display: none;
    }

    img {
        max-width: none;
    }

    @include media($mobile) {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
    }
}

.cart-icon-prefix img {
    height: 1em;
    padding-right: 0.5em;
}

.view-commerce-cart-summary>.view-content {
    overflow-x: auto;
}

.page-checkout-checkout {
    fieldset {
        min-width: auto;
        // overflow: auto;
    }
}

.view-commerce-cart-form,
.view-commerce-cart-summary {
    table td {
        &.views-field-field-media img {
            max-height: 3em;
            max-width: 3em;
        }

        &.views-field-field-koko,
        &.views-field-quantity,
        &.views-field-edit-quantity,
        &.views-field-commerce-unit-price,
        &.views-field-commerce-total {
            width: 6em;
        }
    }

    table tbody tr:hover {

        >td,
        >th {
            background: none;
        }
    }
}

#edit-as-guest {
    margin: 2em 0;
}

.form-item-vari,
.form-item-koko,
.form-item-merkki {
    select {
        display: none;
    }
}

#views-exposed-form-tuotelistaus-block-1 {
    select {
        color: $color-text-primary;
        background-color: $color-white;
        background-image: url('../img/nuoli-alas-vector-gray.svg');
        background-repeat: no-repeat;
        background-position: top 1.1em right 0.5em;
        min-width: 12em;
        padding: 0.5em 1.5em 0.5em 0.5em;
        margin: 0;
        z-index: 2;

        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;

        &::-ms-expand {
            display: none;
        }
    }

    @include media($mobile) {
        .views-exposed-widgets {
            display: grid;
            grid-template-columns: calc(50% - 0.5em) calc(50% - 0.5em);
            gap: 1em;
        }

        .views-exposed-widget {
            width: 100%;
            padding: 0;
        }

        select {
            width: 100%;
        }
    }

}

.shop-list .shop-filters {
    display: flex;
    flex-wrap: wrap;
    min-height: 3em;

    .shop-filter {
        position: relative;
        z-index: 3;
        cursor: pointer;
        margin: 0.5em 1em 0.5em 0;

        &:last-child {
            margin-right: 0;
        }

        .shop-filter-label {
            background-color: $color-white;
            background-image: url('../img/nuoli-alas-vector-gray.svg');
            background-repeat: no-repeat;
            background-position: top 1.1em right 0.5em;
            min-width: 12em;
            padding: 0.5em 1.5em 0.5em 0.5em;
            z-index: 2;
            border: 1px solid #fff;
            color: #505050;
        }

        @include media($medium) {
            .shop-filter-label {
                font-size: 0.8em;
            }
        }


        &:not(.selected, .open):hover {
            .shop-filter-label {
                background-color: $color-white-active;
            }
        }

        &.selected:not(.open) {
            .shop-filter-label {
                background-color: $color-primary;
                color: $color-primary-contrast;
                background-image: url('../img/nuoli-alas-vector-secondary.svg');

                &:hover {
                    background-color: $color-primary-active;
                }
            }
        }

        .shop-filter-selections-container {
            display: none;
            position: absolute;
            min-width: 100%;
            box-shadow: 0 0 4px #0003;
            padding: 0.5em;
            top: 100%;
            z-index: -1;
            background-color: $color-white;
            flex-wrap: wrap;
            width: 30em;
        }

        &.buttons {
            .shop-filter-selections {
                display: flex;
                flex-wrap: wrap;
                ;
            }

            .shop-filter-selection-value {
                border: 1px solid #ccc;
                margin: 0.5em;
                padding: 0.4em;
                min-width: 3em;
                text-align: center;
                font-weight: 400;
                font-size: 0.9em;

                &:not(.selected):hover {
                    background-color: $color-white-active;
                }

                &.selected {
                    background-color: $color-primary;
                    color: $color-primary-contrast;

                    &:hover {
                        background-color: $color-primary-active;
                    }
                }
            }

            @include media($medium-min) {
                .lot .shop-filter-selection-value {
                    margin: 0.25em;
                    padding: 0.25em;
                }
            }

            .link-like-button {
                margin-top: 2em;
                margin-left: 0.625em;
                margin-bottom: 0.625em;
            }
        }

        &.dropdown {
            .shop-filter-selection-value {
                padding: 0.5em;

                &::before {
                    display: inline-block;
                    content: ' ';
                    width: 0.75em;
                    height: 0.75em;
                    border-radius: 100%;
                    border: 1px solid #fff;
                    box-shadow: 0 0 0px 1px #000;
                    margin-right: 0.5em;
                    transition: background-color 0.3s;
                }

                &.selected {
                    font-weight: 500;

                    &::before {
                        background-color: #000;
                    }
                }
            }
        }

        .shop-filter-search {
            position: relative;
            margin-bottom: 2em;

            .rsf-clear {
                position: absolute;
                right: 0.25em;
                top: 50%;
                transform: translateY(-50%);
                height: -webkit-fill-available;

                img {
                    height: 1em;
                    width: 1em;
                }
            }

            input[type="text"] {
                // border-bottom: 1px solid #000;
                border: 1px solid #E1E1E1;
                box-shadow: none;
                padding-right: 1em;
            }
        }

        &.open {
            z-index: 2;

            .shop-filter-label {
                border-bottom-width: 0;
                padding-bottom: 1em;
                // background-image: none;
                // box-shadow: 0 -4px 4px #0003;
            }

            .shop-filter-shadow {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                box-shadow: 0 0 4px #0003;
                z-index: -1;

                @include media($mobile) {
                    display: none;
                }
            }

            .shop-filter-selections-container {
                display: block;
            }

            .shop-filter-selections-wrapper {
                .shop-filter-selections-inner:not(last-child) {
                    margin-bottom: 1em;
                }
            }

            @include media($mobile) {
                background-color: #fff;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0;
                width: auto;
                overflow: auto;

                z-index: 10000;

                .shop-filter-selections-container {
                    position: static;
                    width: auto;
                    box-shadow: none;
                    font-size: 0.933em;

                    margin-left: 0 !important;
                }

                .shop-filter-label {
                    @include header-text();

                    // background-color: transparent;
                    color: $color-primary;
                    font-size: 1.3em;
                    font-weight: 500;
                    background-image: none;
                    padding-left: 0.5em;
                    padding-bottom: 0.125em;
                    padding-right: 0.5em;
                    // Haun otsikko jää seuraamaan
                    position: sticky;
                    top: 0;
                    background: white;
                    border: none;
                }
            }
        }
    }

    ul.ratsula-shop-category-menu>li {
        display: block;
    }

    .shop-category-menu-filter {
        .ratsula-shop-category-menu-filter {
            @include media ($mobile) {
                font-size: 1.33em;
            }

            >li:not(.opened) {
                display: block;
            }

            >li>span,
            >li>ul>li>span {
                text-transform: uppercase;
            }

            ul li a.active {
                font-weight: 600;
            }
        }

        @include media($large-min) {
            display: none;
        }
    }

    .ratsula-shop-category-menu-filter-one-level {
        display: flex;
        margin: 1em 0 1em 0;
        overflow: auto;
        white-space: nowrap;

        @include media($mobile) {
            width: auto !important;
            max-height: none !important;
        }

        ul {
            display: none;
        }

        li {
            margin-right: 0.5em;
            padding: 0.6em 0.7em;
            background-color: #F5F5F5;
            font-weight: 500;

            &.opened {
                background-color: $color-primary;

                a {
                    color: #fff;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            a {
                text-decoration: none;
                color: #505050;
                font-size: 16px;
                font-weight: 400;
            }

            @include media($medium) {
                font-size: 0.8em;
            }
        }
    }

    @include media($medium-min) {
        .shop-filter-selections-wrapper {
            max-height: 30vh;
            overflow: auto;
        }
    }

    @include media($mobile) {
        @include row();

        .shop-filter {
            margin-left: 0;
            @include span-columns(6);
            @include omega('2n+2');
        }
    }

    @media (max-width: 600px) {
        .shop-filter {
            width: 100%;
            margin: 0.5em 0;
        }
    }

}

@include media($mobile) {
    body.admin-menu .shop-filter-selection-container.open {
        @include calc('top', "#{$page-header-height-medium-screen} + 29px");
        bottom: 4em;
    }
}

.shop-filters-container .shop-filters-sm-label {
    display: none;
}

.shop-filters-sm-container {
    margin-bottom: 1em;

    @include media($mobile) {
        &.fixed-container {
            padding-bottom: 52px;
        }
    }

    .shop-filters {
        max-height: 0px;
        min-height: 0;
        overflow: hidden;
    }

    .shop-filters-label {
        margin-top: 1em;
        padding-top: 1em;
        // border-top: 1px solid #f1f1f1;

        @include media($medium-min) {
            padding-top: 1em;
        }

        .shop-filters-row {
            display: flex;
            justify-content: space-between;
            // font-size: 0.8em;
            color: #505050;
        }

        @include media($medium) {
            .shop-filters-row {
                font-size: 0.8em;
            }
        }

        @include media($mobile) {
            &.fixed-row:not(.filter-menu-open) {
                position: fixed;
                top: 7.8em;
                background: white;
                width: 100%;
                left: 0;
                margin: 0;
                padding: 0.125em 1em;
                z-index: 99;
                border-bottom: 1px solid #f0f0f0;
                transition: 0.3s transform;
                transform: translateY(-200px);
            }

            &.fixed-row.filter-menu-open {
                z-index: 102;
            }
        }
    }

    .shop-filter {
        width: calc(50% - 0.5em);

        &:nth-child(even) {
            margin-right: 0;
        }

        @include media($mobile) {
            width: 100%;
        }
    }

    .shop-filters-sm-label {
        display: block;
        background-color: #f1f1f1;
        font-size: 0.925em;
        cursor: pointer;

        background-image: url(../img/nuoli-alas-vector-gray.svg);
        background-repeat: no-repeat;
        background-position: top 1.1em right 0.5em;
        padding: 1em 1.5em 0.5em 0.5em;
        z-index: 2;
    }

    &.toggled {
        box-shadow: $card-shadow;

        .shop-filters {
            @include media($medium-min) {
                padding: 0.5em;
            }

            max-height: 100%;
            overflow: visible;
        }
    }
}

@include media($mobile) {
    body.scrolled {
        #ratsula-search-app-mobile {
            position: fixed;
            width: 100%;
            transform: translateY(-200px);
            transition: 0.3s transform;
            z-index: -99999;
        }

        &.scrolling-up {
            #ratsula-search-app-mobile {
                transform: translateY(0px);
            }
        }

        &.search-open {
            #ratsula-search-app-mobile {
                transform: none;
            }
        }
    }
}

@include media($mobile) {
    body.scrolled.scrolling-up .shop-filters-sm-container .shop-filters-label.fixed-row:not(.filter-menu-open) {
        transform: translateY(0px);
    }

    body.admin-menu.scrolled.scrolling-up .shop-filters-sm-container .shop-filters-label.fixed-row:not(.filter-menu-open) {
        top: calc(7.8em + 29px);
    }
}

.shop-filters-selected {
    // min-height: 3.6em;
    user-select: none;

    &,
    .filter-container,
    .filter-values {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .filter-label {
        font-size: 0.8em;
        color: $color-text-secondary;
    }

    .filter-value {
        cursor: pointer;
        color: $color-text-primary;

        position: relative;
        border: 1px solid #ccc;
        margin: 0.5em;
        padding: 0.2em 0.4em;
        padding-right: 2em;
        min-width: 3em;
        text-align: center;
        font-weight: 400;

        background-image: url('../img/close_gray.svg');
        background-position: center right 0.5em;
        background-size: 1em;
        background-repeat: no-repeat;

        &:hover {
            border-color: $color-primary-active;
        }

        &:last-child {
            margin-right: 1.5em;
        }
    }
}

@media print {

    html,
    body {
        height: auto;
        margin: 0 !important;
    }

    .page-minun-ratsula-tilaukset {

        #page-header,
        #breadcrumb,
        footer,
        .minun-ratsula-valikko,
        #footer-admin-menu {
            display: none;
        }

        main {
            padding-top: 0 !important;
            margin-top: 0 !important;
        }

        main #content-wrapper {
            padding: 0 !important;
            margin: 0 !important;
        }

        table.commerce-price-formatted-components tbody td {
            padding: 0.25em;
        }

        p {
            margin-bottom: 0;
        }

        .field-name-tilauksen-header {
            float: right;
            width: 200px;
        }

        .field-name-commerce-order-total .commerce-price-formatted-components {
            width: 450px;
        }
    }
}

.loader {
    width: 70px;
    height: 70px;
}

#commerce-checkout-form-account {
    #edit-account-form {
        padding-bottom: 3em;
        margin-bottom: 3em;
        border-bottom: 1px solid $color-text-secondary;

        >legend {
            margin-bottom: 2em;
        }
    }

    #edit-account-form-select>.form-wrapper {
        flex: 1;
    }

    #edit-account-form-select-login {
        position: relative;
        padding-right: 3em;

        &::before {
            content: ' ';
            position: absolute;
            width: 1px;
            right: 0;
            top: -2.5em;
            bottom: 0;
            background-color: $color-text-secondary;
        }
    }

    #edit-account-form-select-register {
        padding-left: 3em;
    }

    #edit-as-guest legend,
    #edit-account-form .fieldset-wrapper legend {
        margin-bottom: 1em;

        span {
            font-size: 1.125em;
            color: $color-text-secondary;
        }
    }

    #edit-cancel {
        color: $color-text-secondary;
    }

    @include media($large) {
        #edit-account-form-select-login {
            padding-right: 1em;
        }

        #edit-account-form-select-register {
            padding-left: 1em;
        }

        input {
            max-width: 30em;
        }

        input[type="submit"],
        input[type="button"] {
            white-space: normal;
        }
    }

    @include media($medium) {
        #edit-account-form {
            margin: 0;
            padding: 0;
            border-bottom: none;
        }

        #edit-account-form-select {
            display: block;
        }

        #edit-account-form-select-login {
            padding-right: 0;

            &::before {
                content: none;
            }
        }

        #edit-account-form-select-register {
            padding-left: 0;
        }

        #edit-account-form-select-login,
        #edit-account-form-select-register {
            margin: 3em 0;
            padding-bottom: 3em;

            border-bottom: 1px solid $color-text-secondary;
        }

        #edit-as-guest {
            margin-top: 0;
        }
    }

}

.field-name-field-paketoitavat-tuotteet {
    display: none;
}

.vocabulary-tuotteiden-toimittajat {

    .shop-brand-image,
    .shop-brand-content {
        margin-bottom: 1rem;

        .shop-brand-categories {
            p {
                strong {
                    display: block;
                }
                a {
                    padding: 8px 12px;
                    text-decoration: none;
                    background-color: #F5F5F5;
                    color: #505050 !important;
                    font-size: 14px;
                    font-weight: 400;
                    margin: 0 5px 5px 0;
                    display: inline-block;
                
                    &:hover {
                        background-color: #D5D5D5;
                    }
                }
            }
        }
    }

    .shop-brand-image {
        text-align: center;
    }

    .shop-brand-description {
        .shop-brand-description-first-p {
            height: 72px;
            overflow: hidden;
            z-index: 2;
            position: relative;
        }

        >p {
            z-index: 3;
            position: relative;

            a {
                display: block;
                margin-top: -20px;
                padding-top: 25px;
                background: linear-gradient(360deg, white, rgba(255, 255, 255, 0.4));
            }
        }
    }

    .shop-brand-products {
        text-align: left;
        margin: 1rem 0;
    }

    @include media ($large-min) {
        .shop-brand {
            display: flex;
        }

        .shop-brand-image {
            margin-right: 1rem;
        }

        .shop-brand-content {
            flex: 1;
        }
    }
}

#edit-commerce-shipping-shipping-service {
    label {
        color: #000;
    }

    .description {
        color: #636363;
    }

    .form-item-commerce-shipping-shipping-service {
        margin: 1em 0;
    }
}

#views-form-commerce-cart-form-default {
    .ratsula-shop-free-delivery-info {
        color: $color-primary;
        margin-bottom: 2em;
    }

    .minun-ratsula-price-cart-title {
        font-family: $heading-font-family;
        // font-size: 1.5em;
        color: $color-primary;
        display: flex;
        flex-wrap: wrap;
        column-gap: 1em;
    }

    #edit-submit {
        display: none;
    }
}

.ratsula-toast {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 1em;
    transform: translateY(-100%);
    transition: 0.5s transform;
    z-index: 1000;
    text-align: center;

    &.show {
        transform: translateY(0%);
    }

    .inner {
        border-radius: 4px;
        background-color: $color-primary;
        padding: 0.5em;
        color: #fff;
    }

    a {
        color: #fff;
    }
}

.page-kampanja {
    h2#ratsula-shop-page-title {
        text-align: left;
    }

    @include media($large-min) {
        h2#ratsula-shop-page-title {
            margin: 0 -1rem;
        }

        .shop-filters-label {
            padding-top: 0;
        }
    }

    .shop-campaign-texts {
        margin-bottom: 2em;

        @include media($medium) {
            h1 {
                text-align: left !important;
            }

            * {
                text-align: left !important;
            }
        }
    }
}
