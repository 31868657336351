.entitytype-parallel_card-form,
.entitytype-card_cash-form,
#card-cash-entityform-edit-form,
#card-account-entityform-edit-form {
    text-align: center;
}

.entitytype-parallel_card-form,
.entitytype-card_cash-form,
#card-account-entityform-edit-form,
#card-cash-entityform-edit-form {
    #edit-field-vakituinen-tyopaikka {
        text-align: left;
    }  

    #edit-field-luottoraja, #edit-field-edellisen-tyosuhteen-kesto, .field-name-field-rinnakkaiskortin-tyyppi,
        #edit-field-siviilisaaty, #edit-field-asunto, #edit-field-asevelvollisuus, #edit-field-haluan-ratsulan-tietoa-tuo {
        margin: 2em 0;
        @include clearfix();

        .form-radios {
            text-align: left;
            margin: 2em 0;

            .form-type-radio {
                margin-top: 1em;
                margin-bottom: 1em;

                @include media($medium-min) {
                    @include span-columns(3);
                    @include omega(4n);
                }

                @include media($mobile) {
                    @include span-columns(12);
                    @include omega(1n);
                }
            }
        }

        .form-checkboxes {
            text-align: left;
            margin: 2em 0;
            @include display(flex);
            @include flex-wrap(wrap);
            justify-content: center;
            text-align: center;

            .form-type-checkbox {
                margin-top: 1em;
                margin-bottom: 1em;

                @include media($medium-min) {
                    @include span-columns(3);
                    @include omega(4n);
                }

                @include media($mobile) {
                    @include span-columns(12);
                    @include omega(1n);
                }
            }
        }
    }

    #edit-field-asevelvollisuus, .field-name-field-rinnakkaiskortin-tyyppi {
        .form-radios {
            @include display(flex);
            @include flex-wrap(wrap);
            justify-content: center;
            text-align: center;
        }
    }

    .form-item-password {
        @include clearfix();
        margin-top: 0;

        label { display: none; }
        div.password-strength, div.password-confirm { margin-top: 0; font-size: 0.85em; text-align: right; }
        .password-confirm { margin-bottom: 0.3em; }
        .password-suggestions { 
            @include clearfix-before();
            width: 100%;
            border: none;
        }

        .form-type-password {
            clear: none;
            @include span-columns(6);
            @include omega(2);
        }
    }
}