body.preview-mode {
  overflow: hidden;
}

.modal {
  $base-border-color: #dcdcdc !default;
  $base-border-radius: 3px !default;
  $base-background-color: #fff !default;
  $base-font-size: 1em !default;
  $base-line-height: 1.5em !default;
  $color-primary: #477dca !default;
  $dark-gray: #333 !default;
  $light-gray: #ddd !default;
  $medium-screen: 40em !default;
  $large-screen: 53.75em !default;
  $color-text-primary: $dark-gray !default;
  $modal-padding: 3em;
  $modal-background: $base-background-color;
  $modal-close-color: $light-gray;
  $modal-max-image-height: 350px;
  $modal-trigger-image-width: 300px;

  label {
    cursor: pointer;
    margin-bottom: 0;
  }

  label img {
    border-radius: $modal-trigger-image-width / 2;
    display: block;
    max-width: $modal-trigger-image-width;
  }

  .modal-state {
    display: none;
  }

  .modal-trigger {
    padding: 0.8em 1em;
  }

  .modal-title {
    margin-bottom: $column-y-padding;
  }

  .modal-fade-screen {
    // overlay
    @include position(fixed, 0);
    background-color: rgba(#000, 0.85);
    padding-top: 0.6em;
    text-align: left;
    transition: opacity 0.25s ease;
    z-index: 99999999999;

    display: flex;
    justify-content: center;
    align-items: center;

    .modal-bg {
      @include position(absolute, 0);
      cursor: pointer;
    }
  }

  .modal-close {
    float: right;
  }

  .modal-inner {
    background: $modal-background;
    border-radius: $base-border-radius;
    max-height: 100%;
    overflow: auto;
    position: relative;
    transition: opacity 0.25s ease;
    width: 1140px;
    max-width: calc(100% - 2em);

    display: flex;
    align-items: stretch;
    justify-content: center;

    .modal-content,
    .modal-image {
      width: 50%;
    }

    .modal-content.no-modal-image {
      width: 100%;
    }

    @include media($mobile) {
      flex-direction: column;

      width: 500px;

      .modal-content,
      .modal-image {
        width: 100%;
      }

      .modal-close {
        position: absolute;
        right: 1em;
        top: 1em;
      }
    }

    h1 {
      color: $color-text-primary;
      text-align: left;
      text-transform: capitalize;
    }

    p {
      color: $color-text-primary;
      line-height: $base-line-height;
    }

    .modal-image {
      padding: 1em;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
      }
    }

    .modal-video {
      padding: 1em;
      display: flex;
      align-items: center;
      justify-content: center;

      video {
        max-width: 100%;
        height: 100%;
      }
    }

    .modal-intro {
      margin-bottom: 1em;
    }

    .modal-content {
      color: $color-text-primary;
      background-color: $color-secondary;
      padding: 1em;

      max-height: calc(100vh - 2em);
      overflow: auto;
    }

    a.cta {
      color: $base-background-color;
      display: inline-block;
      margin-right: 0.5em;
      margin-top: 1em;

      &:last-child {
        padding: 0 2em;
      }
    }

    .modal-content-y-margin {
      display: block;
      content: ' ';
      height: 2em;
    }

    .modal-links {
      margin: 1em -0.5em 0 -0.5em;

      a {
        margin: 0.5em;
      }
    }
  }

}

.modal-open {
  overflow: hidden;
}

.sisaltofeed-modal {
  .modal-inner {
    .hide-text {
      color: transparent;
      width: 0;
      height: 0;
      overflow: hidden;
      display: block;
    }

    .modal-source {
      height: 2em;
      background-position: left top;
      background-size: auto 100%;
      background-repeat: no-repeat;

      margin-bottom: 2em;
    }

    .facebook {
      background-image: url('../img/FB.svg');
    }

    .instagram {
      background-image: url('../img/Ins.svg');
    }


  }
}

.node-type-floor h1#page-title {
  display: none;
}

.varaa-aika-pukeutumisneuvojalle {
  margin-top: 3em;
}



.varaa-aika-pukeutumisneuvojalle .listauksen-alapuolella,
.listauksen-alapuolella .varaa-aika-pukeutumisneuvojalle,
.varaa-aika-pukeutumisneuvojalle.listauksen-alapuolella {
  margin-top: 2em;
  margin-bottom: 3em;
}

.varaa-aika-pukeutumisneuvojalle a,
.link-like-button,
.pager-load-more a {
  display: inline-block;
  text-transform: uppercase;
  font-weight: $font-weight-semibold;
  font-family: $heading-font-family;
  padding: 2em 4em;
  background-color: $color-primary;
  font-size: 0.8em;
  color: $color-primary-contrast !important;
  text-decoration: none;

  &:hover {
    background-color: $color-primary-active;
  }
}

.link-like-button,
.pager-load-more a {
  // padding: 1.2em 2em;
  padding: calc(1.2em + 3px) 2em 1.2em 2em;
  margin: 0 1em;

  &.large {
    font-family: $heading-font-family;
    font-weight: normal;
  }

  &.w100 {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}

.link-like-button.gray {
  background-color: #9a8c8c;
}

.keskita-varaa-pukeutumisneuvoja .varaa-aika-pukeutumisneuvojalle {
  text-align: center;
}

.heading-line {
  margin: 2em 0;

  h2,
  h3 {
    @include normal-text();
    @include heading-line();
    clear: both;
  }
}

.node-webform {
  .four-shift {
    @include shift(3);

    @include media($medium) {
      @include shift(2);
    }

    @include media($mobile) {
      @include shift(0);
    }
  }

  .two-cols {
    @include span-columns(3);

    @include media($medium) {
      @include span-columns(4);

      @include media($medium-min) {
        @include omega(3n);
      }
    }

    @include media($mobile) {
      @include span-columns(6);
      @include omega(2n);
    }
  }

  .four-cols {
    @include span-columns(4);

    @include media($medium-min) {
      @include omega(3n);
    }

    @include media($mobile) {
      @include span-columns(6);
      @include omega(2n);
    }
  }

  .inline-list-two-cols {
    @include row();

    .form-radios,
    .form-checkboxes {
      @include media($large-min) {
        @include display(flex);
        @include flex-wrap(wrap);
        justify-content: center;
        text-align: center;
      }

      .form-item {
        @include media($medium-min) {
          @include span-columns(6);
          @include omega(2n);
        }

        @include media($mobile) {
          @include span-columns(12);
          @include omega(1n);

          text-align: center;

          input {
            display: block;
            margin: 0.5em auto;
          }
        }
      }
    }
  }
}

.text-center {
  text-align: center;
}

.loader-enabled {
  @include loader();
}

#custom-registration-create-authenticated-form-wrapper {
  .card-number {
    display: block;
    font-weight: $font-weight-semibold;
  }

  #edit-birthday {
    .form-item {
      display: inline-block;
    }
  }

  #edit-login-data {
    margin-top: 2em;

    legend {
      font-weight: $font-weight-semibold;
    }

    .form-item-email {
      position: relative;

      .description {
        position: absolute;
        top: -1.5em;
        right: 0;
      }
    }

    .form-item-email {
      margin-bottom: 0;
    }

    .form-item-password {
      @include clearfix();
      margin-top: 0;

      label {
        display: none;
      }

      div.password-strength,
      div.password-confirm {
        margin-top: 0;
        font-size: 0.85em;
        text-align: right;
      }

      .password-confirm {
        margin-bottom: 0.3em;
      }

      .password-suggestions {
        @include clearfix-before();
        width: 100%;
        border: none;
      }

      .form-type-password {

        clear: none;
        @include span-columns(6);
        @include omega(3);
      }
    }
  }

  #edit-contact-methods {
    @include clearfix();
    margin: 2em 0;

    .form-item {
      @include media($medium-min) {
        @include span-columns(4);
        @include omega(3);
      }

      @include media($mobile) {
        @include span-columns(12);
        @include omega(1n);
      }
    }
  }
}

#tupas-banks {
  margin: 1em 0;
  @include display(flex);
  @include flex-wrap(wrap);
  justify-content: center;
  text-align: center;

  .tupas-bank {
    margin-top: 1em;
    margin-bottom: 1em;

    @include media($large-min) {
      @include span-columns(2);
      @include omega(6n);
    }

    @include media($medium-only) {
      @include span-columns(3);
      @include omega(4);
    }

    @include media($mobile) {
      @include span-columns(6);
      @include omega(2n);
    }

    background-color: $color-secondary;
    height: 10em;

    form {
      display: table;
      height: 100%;
      width: 100%;

      div {
        display: table-cell;
        vertical-align: middle;

        input[type="image"] {
          cursor: pointer;
          margin: 0px auto;
        }
      }
    }

    a {
      @include display(flex);
      height: 100%;
      padding: 1em;
      justify-content: center;
      align-items: center;
    }
  }
}

.tupas-info {
  text-align: center;
}

#edit-custom-terms-of-use .fieldset-wrapper {
  max-height: 20em;
  overflow: auto;
  margin: 2em 0;
}


#salasana-container {

  .form-item-salasana,
  .form-item-salasana-uusi {
    @include clearfix();
    margin-top: 0;

    label {
      display: none;
    }

    div.password-strength,
    div.password-confirm {
      margin-top: 0;
      font-size: 0.85em;
      text-align: right;
    }

    .password-confirm {
      margin-bottom: 0.3em;
    }

    .password-suggestions {
      @include clearfix-before();
      width: 100%;
      border: none;
    }

    .form-type-password {
      clear: none;
      @include span-columns(6);
      @include omega(3);

      @include media($mobile) {
        @include span-columns(12);
        @include omega(1n);
      }
    }
  }
}

.show-only-mobile {
  display: none;

  @include media($mobile) {
    display: block;
  }
}

.show-only-mobile-small {
  display: none;

  @include media($mobile-small) {
    display: block;
  }
}

.view-yhteystiedot {
  .view-group {
    margin: 2em 0;

    &:after {
      clear: both;
      content: ' ';
      display: table;
    }
  }
}

.page-acheckout .tilauksen-tiedot {
  .field {
    margin: 1em 0;
    @include clearfix();
  }

  .field-label {
    float: left;
    width: 50%;
    text-align: right;
    padding-right: 1em;
  }

  .field-items {
    float: left;
    width: 50%;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .field-type-text-long {
    white-space: pre-wrap;
  }

  .select-maksutapa {
    margin-top: 0.75em;
  }
}

.page-entityform-edit,
.page-eform-submit {
  .field-name-field-tilauksen-arvo {
    input {
      width: 20em;
      max-width: 100%;
      display: inline-block;
    }
  }
}

body.logged-in .hide-if-logged-in {
  display: none;
}

body.not-logged-in .hide-if-not-logged-in {
  display: none;
}

.page-acheckout .entityform-lahjakortin-tilaus {
  width: 100%;
  max-width: 40em;
  margin: 0 auto;

  .field-name-field-lahjakortin-tiedot-collect {
    margin-top: 0;

    .field-items .field-item:nth-child(1n+2) {
      border-top: 1px solid rgba(140, 11, 45, 0.34);
    }

    .field-items .field-item:first-child {
      .field-name-field-lahjakortin-summa {
        margin-top: 0;
      }
    }
  }

  .field-name-field-lahjakortin-tiedot {
    margin: 0;

    .field-items {
      float: none;
      width: 100%;
    }
  }
}

.entitytype-lahjakortin_tilaus-form {
  width: 100%;
  max-width: 40em;
  margin: 0 auto;

  .field-name-field-haluan-liitty-ratsulan-s-h {
    margin-left: 0.35em;
  }

  .field-name-field-lahjakortin-summa {
    text-align: center;

    input {
      width: 5em;
      max-width: 100%;
      display: inline-block;
      text-align: center;
    }
  }

  #edit-field-lahjakortin-tiedot-collect {
    .field-name-field-postitus > div > div > .form-item:first-child {
      display: none;
    }
    
    .sticky-header,
    thead,
    .field-multiple-drag,
    .tabledrag-toggle-weight-wrapper {
      display: none;
    }

    tr,
    td {
      padding: 0;
    }

    table {
      border-spacing: 0 2em;

      tbody tr:first-child .remove-button {
        display: none;
      }
    }

    td {
      border: 1px solid #750022 !important;
      padding: 2em;
      margin-bottom: 2em !important;

      .form-submit {
        display: block;
        margin-left: auto;
        margin-right: 0;
        padding: 0.8em 1.2em;
        line-height: 1.6em;
      }
    }

    .field-name-field-postitus {
      margin-bottom: 0.9em;

      .form-item.form-type-radio {
        padding: 0.3em;
      }
    }

    .form-type-checkbox label,
    .form-type-radio label {
      white-space: normal;
    }

    .field-add-more-submit, input.remove-button {
      // display: none;
      display: block;
      margin: 0em auto 3em auto;
      color: #8c0b2d;
      background-color: #fff;
      font-family: $base-font-family;
      text-transform: unset;
      font-weight: 400;
      letter-spacing: normal;
    }
    input.remove-button {
      margin: 0 0 0 auto;
    }

    tr:not(:last-child) .form-submit {
      margin-bottom: 1em;
    }

    #edit-field-haluan-liitty-ratsulan-s-h {
      margin-bottom: 2em;
    }
  }

  #tilauksen-kokonaissumma {
    font-size: 1.125em;
    line-height: 2.65em;

    @media(min-width: 451px) {
      float: left;
    }

    #tilauksen-kokonaissumma-summa {
      font-weight: bold;
    }
  }

  #edit-actions {
    margin-left: auto;
    margin-right: 0;
    text-align: right;

    @media(max-width: 450px) {
      text-align: left;
    }
  }
}

.page-acheckout-order .entityform-lahjakortin-tilaus {

  .field-collection-view,
  .field-collection-container {
    margin: 0;
    padding: 0;
    border: none;
  }

  .field-collection-container .field-items .field-item {
    margin-bottom: 0;
  }

  .field-name-field-lahjakortin-tiedot-collect {
    >.field-label {
      display: none;
    }

    >.field-items {
      float: none;
      width: 100%;
    }
  }
}

#commerce-checkout-form-checkout,
#commerce-checkout-form-shipping,
#commerce-checkout-form-review {
  fieldset {
    padding-bottom: 1em;
    margin-bottom: 1em;

    &.cart_contents legend {
      display: none;
    }

    .street-block {
      >.form-item:first-child {
        margin-bottom: 2em;
      }
    }

    &.checkout-buttons .fieldset-wrapper {
      display: flex;
      flex-wrap: wrap;
      // justify-content: flex-end;

      input {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }

      .checkout-back {
        font-family: $base-font-family;
        color: $color-text-primary;
        letter-spacing: normal;
        text-decoration: underline;
        display: block;
        margin-right: 2em;
        order: -1;
      }
    }
  }

  input {
    max-width: 23em;
  }

  select {
    border: 1px solid #636363;
  }

  .postal-code-error {
    font-size: 0.9em;
    padding: 0.5em;
    background-color: #EAEAEA;
    color: #636363;
    font-weight: 500;
    max-width: 25em;
  }
}

#commerce-checkout-form-review {
  .checkout_review {
    .checkout-review>tbody>tr:first-child {
      display: none;
    }
  }
}

#commerce-checkout-form-checkout fieldset,
#commerce-checkout-form-shipping fieldset,
#commerce-checkout-form-account fieldset {
  .fieldset-legend {
    font-size: 1.5em;
    font-family: $heading-font-family;
    color: $color-primary;
  }
  .fieldset-wrapper {
    margin-top: -10px;
    .field-type-addressfield {
      margin-top: 15px;
    }
  }
}

body.page-checkout-review {
  fieldset.checkout-buttons.form-wrapper:before {
      content: 'Tuotteet voi palauttaa ilmaiseksi myymäläämme tai palautuksen voi tehdä normaaliin tapaan jättämällä palautuksen Postiin. Pidätämme tällöin normaalin vakiotoimitusmuodon (Postin Asiakaspalautus-paketti 4, 90 €) kustannuksen palautettavasta summasta. \A\A Muista palata maksutapahtuman jälkeen takaisin verkkokauppaan, jotta maksu rekisteröityy oikein.';
      white-space: pre-wrap;
      display: block;
      margin-bottom: 30px;
  }
}

#commerce-checkout-form-shipping fieldset legend {
  margin-bottom: 1em;
}

#edit-commerce-shipping .description {
  margin-left: 3.2em;
}

#commerce-checkout-form-account .checkout-buttons {
  margin-top: 1em;
}

#commerce-checkout-form-review, #edit-cart-contents {
  .commerce-price-formatted-components {
    float: right;

    td {
      padding: 0.25em 0em;
    }
    td.component-total {
      padding-left: 1em;
    }
  }
}

body.page-node-5156 {
  section {
    &#content {
      @include media($medium) {
        display: flex;
        flex-direction: column;
        .alasivu-valikko {
          margin: 1em 0;
          ul.menu {
            li {
              margin: 0 0.5em 0 0;
            }
          }
        }
      }
    }
  }
}

#commerce-checkout-form-review {
  table.checkout-review {
    table-layout: fixed;
    // border: 1px solid $color-primary;
    border: none;

    tbody tr:hover>td,
    tbody tr:hover>th {
      background: none;
    }
  }

  table.checkout-review td {
    padding-left: 0;
    padding-right: 0;
  }

  table.checkout-review .pane-data:nth-child(2),
  table.checkout-review .pane-title td {
    // font-weight: normal;
    // // color: $color-primary;
    // // font-family: $heading-font-family;
    padding-bottom: 0;
  }

  table.checkout-review tr.pane-title:first-child td {
    font-size: 1.25em;
    padding-left: 0.8em;
  }

  table.checkout-review tr.pane-title:nth-child(2) td {
    padding-top: 0;
    padding-bottom: 0;
  }

  .view-commerce-cart-summary {
    .view-content {
      overflow-x: auto;
    }

    table.views-table {
      table-layout: auto;

      tbody tr {
        &:hover {

          >td,
          >th {
            background: none;
          }
        }

        &.views-row-first td {
          border-top: 1px solid $color-light !important;
        }

        &.even,
        &.odd {
          td {
            border-bottom: 1px solid $color-light !important;
          }
        }
      }
    }
  }

  .view-commerce-cart-summary {

    th,
    td {
      &:first-child {
        padding-left: 0;
      }
    }

    th,
    td {
      &:last-child {
        padding-right: 0;
      }
    }
  }

  .checkout-review {
    .form-item.form-type-item {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.5em;

      label {
        display: none;
        color: #000;
        font-size: 1rem;
        width: 15em;
        margin-bottom: 0;

        @include media($mobile) {
          width: auto;
          margin-right: 1em;
        }
      }
    }
  }

  .commerce-order-handler-area-order-total .commerce-price-formatted-components {
    width: auto;

    @include media($mobile) {
      width: 100%;
    }
  }

  .commerce-smartpost-shipping-service-info-form div:first-child {
    font-weight: bold;
  }

  .checkout_review,
  fieldset {
    margin-bottom: 2em;
  }

  fieldset {

    &.commerce_payment,
    &.commerce_smartpost {
      border: 1px solid $color-primary;
      padding: 1em;

      legend {
        padding: 0 2px;
        // font-family: $heading-font-family;
        // padding-bottom: 1em;
      }
    }
  }
}

body>.image-preview {
  &.showing {
    animation: show-opacity 0.75s;
  }

  &.removing {
    animation: hide-opacity 0.75s;
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-bg;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;

  .image-container img {
    max-width: calc(100vw - 4em);
    max-height: calc(100vh - 4em);
  }

  .close {
    position: absolute;
    right: 1em;
    top: 1em;
    z-index: 2;

    &,
    img {
      width: 2em;
      height: 2em;
    }
  }

  .arrowbtn {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -1.5em;
    right: 1em;

    height: 3em;
    width: 3em;

    background-color: $color-primary;
    background-image: url('../img/kuvanuoli-oikea.svg');
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      background-color: $color-primary-active;
    }

    &.left {
      right: auto;
      left: 1em;
      background-image: url('../img/kuvanuoli-vasen.svg');
    }
  }
}

.ajax-progress .throbber {
  background: url('../img/Loader.svg') no-repeat 0px center;
  background-size: 100%;
  height: 2em;
  width: 2em;
}

#front-video-container {
  position: relative;
  overflow: hidden;
  z-index: -1;

  @include calc(max-height, "100vh - #{$page-header-height-without-bottom}");

  @include media($large) {
    @include calc(max-height, "100vh - #{$page-header-height-medium-screen}");
  }

  @include media($medium) {
    // display: none;
  }
}

// body.front {
//   #main-right > .outer-wrapper {
//     // margin-top: -12em;
//     // background: $color-bg;
//     // border-radius: 4px 4px 0 0;

//     @include media($medium) {
//       // margin-top: 0;
//       // border-radius: 0;
//     }
//   }
// }

.commerce-order-commerce-order .field-name-commerce-smartpost-extra-field {
  margin: 2em 0;

  &:before,
  &:after {
    display: table;
    content: ' ';
    clear: both;
  }
}

#front-video-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.2s opacity;
  overflow: hidden;
  z-index: -1;

  @include calc(max-height, "100vh - #{$page-header-height-without-bottom}");

  @include media($large) {
    @include calc(max-height, "100vh - #{$page-header-height-medium-screen}");
  }


  background-size: cover;
  background-position: center;
}

.field-name-commerce-order-total .commerce-price-formatted-components {
  td {
    line-height: 1;
    padding: 0.25em 1em;
  }
  tr.component-type-commerce-price-formatted-amount, tr.component-type-balance-due {
    background: none;
    td.component-total {
      color: $color-primary;
      font-size: 1.1em;
    }
  }

  tr.component-type-alv-024 {
    td {
      padding-bottom: 0.6em !important;
    }
  }

  tr.component-type-ratsula-shop-double-bonus-campaign {
    td {
      padding-top: 0.75em !important;
    }
  }

  tr.component-type-giftcard, tr.component-type-ratsula-shop-double-bonus-campaign {
    td {
      padding-top: 0.75em !important;
    }
  }

  tr.component-type-balance-due {
    font-weight: bold;
  }

  tr.component-type-ratsula-shop-double-bonus-campaign {
    td {
      border-top: 1px solid #efefef;
    }
  }

  tr.component-type-line {
    background: #efefef !important;

    td {
      height: 1px;
      overflow: hidden;
      padding: 0 !important;
    }

    td.component-total {
      font-size: 0.1px;
      color: transparent;
    }
  }
}

@keyframes show-opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide-opacity {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@include media($medium-min) {
  .show-on-mobile {
    display: none !important;
  }
}

@include media($mobile) {
  .hide-on-mobile {
    display: none !important;
  }
}

@include media($medium) {
  .hide-on-medium {
    display: none !important;
  }
}

@include media($large-min) {
  .show-on-medium {
    display: none !important;
  }
}

.krumo-root {
  text-align: left;
}

#views-form-commerce-cart-form-default {
  position: relative;
}

.cart-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0008;

  display: flex;
  justify-content: center;
  align-items: center;

  .throbber {
    height: 4em;
    width: 4em;
  }
}

span#ratsula-header-login {
  color: #8c0b2d;
  cursor: pointer;
}

#ratsula-login-form {
  position: fixed;
  z-index: 300;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .ratsula-login-form-area {
    background: white;
    padding: 2em;
    border-radius: 3px;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.13);
    max-width: 95vw;

    @include media($large-min) {
      padding: 4em;
    }

    form {
      .form-required {
        display: none;
      }

      .form-item {
        label {
          margin-bottom: 0;
        }
      }

      div.form-actions {
        text-align: center;
      }
    }

    div.item-list {
      text-align: center;

      ul {
        li {
          margin-left: 0;

          &.first {
            display: none;
          }

          a {
            font-size: 0.9em;
          }
        }
      }
    }

    a.tab-like-button {
      padding: 0.6em 0.7em;
      font-weight: 500;
      text-decoration: none;
      background-color: #F5F5F5;
      color: #505050!important;
      font-size: 16px;
      font-weight: 400;

      @include media($mobile) {
        padding: 0.4em 0.45em;
        font-size: 13px;
        line-height: 13px;
        white-space: nowrap;
      }

      &:hover {
        background-color: #D5D5D5;
      }
    }

    .ratsula-login-form-close-area {
      text-align: right;
      padding-bottom: 1em;
      margin-top: -1em;
      margin-right: -1em;

      @include media($large-min) {
        margin-top: -3em;
        margin-right: -3em;
      }

      img {
        width: 15px;
        height: 15px;
        cursor: pointer;

        @include media($large-min) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  // form {
  //   background: white;
  //   padding: 3em;
  //   border-radius: 3px;
  // }
  // .ratsula-login-form-close-area {
  //   background: white;
  //   padding: 3em;
  //   border-radius: 3px;
  //   text-align: right;
  // }
}

.view-tuotevariaatiot {
  overflow-x: auto;
}

.r-moda-flex {

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @include media($fhd-min) {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 -200px;
  }

  .r-moda-flex-item {
    margin-top: 40px;
    width: 100%;
    padding: 0 25px;

    span.smalltext {
      font-size: 0.6em;
    }

    h3 {
      margin-bottom: 10px;
      height: auto;
    }

    .r-moda-flex-item-link {
      margin-bottom: 20px;
      font-size: 0.8em;
      background: #8c0b2d;
      color: #fff;
      font-weight: 600;
      text-align: center;
      display: block;
      text-transform: uppercase;
      text-decoration: none;
      padding: 0.75em 1em;
      line-height: 0.8em;
      border: 1px solid #8c0b2d;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;

      &.placeholder {
        border-color: white;
        background-color: white;
        cursor: default;
        height: 0px;
        padding: 0;
        margin: 0;
      }
    }

    @include media($fhd-min) {
      width: 33.3%;

      h3 {
        height: 25px;
      }
      .r-moda-flex-item-link.placeholder {
        height: auto;
        margin-bottom: 20px;
        padding: 0.75em 1em;
      }
    }
  }
}
.scroll-back-to-top-btn, .aspa-btn {
  display: none;
  background-image: url('../img/nuoli-ylos-vector-white.svg');
  background-color: #8c0b2d;
  width: 2em;
  height: 2em;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  transform: rotateZ(180deg);
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 3;
}
.aspa-btn {
  background-image: none;
  // right: calc(20px + 25px + 1em);
  right: 20px;
  transform: unset;
  width: auto;
  display: block;
  color: #fff;
  padding: 1em 1em;
  height: unset;
  line-height: 0;
  transition: .2s;

  &.moved {
    right: calc(20px + 25px + 1em);
  }
}

.page-minun-ratsula.page-minun-ratsula-etukupongit {
  h1 {
    font-family: $krona-font-family;
    font-size: 26px;
    color: #8C0B2D;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 40px;
  }

  div.os-coupon {
    display: flex;
    flex-direction: row;

      @media (max-width:750px) {
        flex-direction: column-reverse;
      }

    .os-coupon-contents, .os-coupon-image {
      flex: 1;
    }

    .os-coupon-contents {
      order: 1;

      .os-coupon-content {
        h3, h6, p.rtecenter, div {
          text-align: left;
        }
      }

      .os-button-area {
        margin-top: 35px;

        .code-area {
          background: #828282;
          border-radius: 1px;
          text-align: center;
          padding: 1em;
          position: relative;
          cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

          .code {
            font-size: 22px;
            color: #FFFFFF;
            letter-spacing: 1.38px;
            text-align: center;
            line-height: 1;
          }
          #copy-code {
            position: absolute;
            top: 1em;
            right: 1em;

            svg {
              filter: invert(1);
            }
          }
        }
        .smaller {
          text-align: center;
          font-weight: 500;
          font-size: 16px;
          color: #505050;
          letter-spacing: 0;
          text-align: center;
          margin-top: 5px;
        }
      }
    }

    .os-coupon-image {
      order: 2;
      margin: 0;
    }
  }
}

#ka-maintitle {
  background-image: url('../img/ratsula-kanta-asiakkuus.jpg');
  width: 100vw;
  position: absolute;
  left: 0;
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
}

#ka-f-section, #ka-t-section {
  padding-top: 500px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .ka-f-section.ka-f-section-f, .ka-t-section.ka-t-section-f {
    text-align: left;

    h2 {
      text-align: left;
      font-family: $krona-font-family;
      font-size: 26px;
      color: #8C0B2D;
      letter-spacing: 0;
    }
  }

  .ka-f-section.ka-f-section-s {
    background: #8C0B2D;
    border-radius: 1px;
    font-family: $heading-font-family;
    font-size: 16px;
    color: #FFEFEA;
    letter-spacing: 1px;
    text-align: center;
    padding: 10px 30px;

    &.ka-t-section {
      width: fit-content;
      margin-top: 50px;
    }
  }
}

#ka-s-section {
  display: flex;
  padding-top: 50px;

  .ka-s-section {
    border: 1px solid #EEF0F7;
    padding: 40px;
    flex: 1;

    h3 {
      font-family: $krona-font-family;
      font-size: 20px;
      color: #8C0B2D;
      letter-spacing: 0;
    }
  }
  .ka-f-section.ka-t-section.ka-f-section-s {
    background: #8C0B2D;
    border-radius: 1px;
    font-family: $heading-font-family;
    font-size: 16px;
    color: #FFEFEA;
    letter-spacing: 1px;
    text-align: center;
    padding: 10px 30px;
  }
}

#ka-t-section {
  padding-top: 50px;
}

.ratsula-content {
  h2 {
    text-align: left;
    font-family: $krona-font-family;
    font-size: 26px;
    color: #8C0B2D;
    letter-spacing: 0;

    &.extramargin {
      margin: 50px 0 20px 0;
    }
  }

  &.hightpadding {
    padding-top: 500px;
  }
}
.ratsula-content-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .mw-750 {
    max-width: 750px;
  }
  .py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.ratsula-boxed-content {
  display: flex;

  @include media($medium) {
    flex-direction: column;
  }

  &.py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .ratsula-content-box {
    border: 1px solid #EEF0F7;
    padding: 40px 40px 80px 40px;
    flex: 1;
    position: relative;

    h3 {
      font-family: $krona-font-family;
      font-size: 20px;
      color: #8C0B2D;
      letter-spacing: 0;
      font-weight: 400;

      &.smaller {
        font-size: 18px;
      }
    }

    .tc {
      text-align: center;
    }

    div.btn-area {
      margin: 0 auto;
      text-align: center;
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

a.linklike-btn {
  background: #8C0B2D;
  border-radius: 1px;
  font-family: $heading-font-family;
  font-size: 16px;
  color: #FFEFEA;
  letter-spacing: 1px;
  text-align: center;
  padding: 10px 30px;
  text-decoration: none;
}
h2 {
  a.linklike-btn {
    float: right;
    margin-top: 0!important;
  }
}
div.limit-width {
  max-width: 700px;
}

body.ratsula-wide-content #content .field.field-name-body.field-type-text-with-summary.field-label-hidden {
  width: 100%;
  margin: 0;
  float: none;

  h2 {
    font-family: $krona-font-family;
    font-size: 26px;
    color: #8C0B2D;
    letter-spacing: 0;
    font-weight: 400;
    text-transform: none;
  }
  h3 {
      font-family: $krona-font-family;
      font-size: 20px;
      color: #8C0B2D;
      letter-spacing: 0;
      font-weight: 400;
      text-transform: none;
      margin: 30px 0 10px 0;
  }
}
section.vuokrauspalvelu-yhteydenotto {
  margin-left: 0!important;

  h2, div.form-actions {
    text-align: left;
  }
  h2 {
    font-family: "Krona One",sans-serif;
    font-size: 20px;
    color: #8C0B2D;
    letter-spacing: 0;
    font-weight: 400;
    text-transform: none;
    margin: 30px 0 10px 0;
  }
  .form-item.webform-component.webform-component-email.webform-component--sahkopostiosoite {
    width: 49%;
    float: left;
    margin-right: 2%;
  }
  .form-item.webform-component.webform-component-textfield.webform-component--puhelinnumero {
    width: 49%;
    float: left;
  }
}
#custom-registration-form-wrapper {
  .thinner-on-large-screen {
    margin-left: 0!important;
  }
  h2, p {
    text-align: left;
  }
  h2 {
    font-family: "Krona One",sans-serif;
    font-size: 20px;
    color: #8C0B2D;
    letter-spacing: 0;
    font-weight: 400;
    text-transform: none;
    margin: 30px 0 10px 0;
  }
  .mknh-fixed {
    width: 500px;
    margin: 50px 0;
    max-width: 100%;
  }
  .form-item.form-type-textfield.form-item-card-number {
    width: 500px;
    max-width: 100%;
    margin: 0;
  }
  .form-item.form-type-textfield.form-item-first-name {
    width: 49%;
    float: left;
    margin-right: 2%;
  }
  .form-item.form-type-textfield.form-item-last-name {
    width: 49%;
    float: left;
  }
  input.form-submit {
    margin: 20px 0 100px 0;
    float: left;
  }
  @include media($mobile) {
    .form-item.form-type-textfield.form-item-first-name, .form-item.form-type-textfield.form-item-last-name {
      width: 100%;
      margin-right: 0;
    }
    input.form-submit {
      font-size: 14px;
    }
  }
}
body.ratsula-wide-content div.paivamaara-to-calendar {
  margin-left:0!important;
}
body.ratsula-wide-content form#webform-client-form-631 {
  margin: 0;

  div.paivamaara-to-datepicker {
    margin-left: 0;
    margin-bottom: 0;
  }
  div.webform-component--kellonaika {
    margin-bottom: 0;
  }
  .heading-line h3 {
    text-align: left;
    font-family: "Krona One",sans-serif;
    font-size: 18px;
    color: #8C0B2D;
    letter-spacing: 0;
    font-weight: 400;
    text-transform: none;
    margin: 30px 0 10px 0;

    &::before, &::after {
      display: none!important;
    }
  }
  .heading-line h3::before, .heading-line h3::after {
    display: none!important;
  }

  div.webform-component--etunimi, div.webform-component--puhelinnumero {
    width: 49%;
    float: left;
    margin-right: 2%;
    margin-top: 0;
  }
  div.webform-component--sukunimi, div.webform-component--sahkopostiosoite {
    width: 49%;
    float: left;
    margin-top: 0;
  }

  div.form-actions {
    text-align: left;
  }
}
body.ratsula-wide-content form#card-cash-entityform-edit-form, body.ratsula-wide-content form#card-account-entityform-edit-form {
  margin-left: 0;
  .pre-instructions {
    h2, p {
      text-align: left;
    }
    h2 {
      font-family: "Krona One",sans-serif;
      font-size: 1.6em;
      color: #8C0B2D;
      letter-spacing: 0;
      font-weight: 400;
      text-transform: none;
    }
  }
  div.field-type-text div.form-item, div.field-type-list-text div.form-item {
    margin: 0;
  }
  #edit-field-firstname, #edit-field-katuosoite, #edit-field-paikkakunta, #edit-field-puhelinnumero {
    width: 49%;
    float: left;
    margin-right: 2%;
  }
  #edit-field-lastname, #edit-field-postinumero, #edit-field-sahkoposti {
    width: 49%;
    float: left;
  }
  @include media($mobile) {
    div.moda-kortin-numero-hukassa a {
      font-size: 14px;
    }
    #edit-field-firstname, #edit-field-katuosoite, #edit-field-paikkakunta, #edit-field-puhelinnumero {
      width: 100%;
      margin-right: 0;
    }
    #edit-field-lastname, #edit-field-postinumero, #edit-field-sahkoposti {
      width: 100%;
    }
  }
  #edit-field-syntym-aika {
    width: 100%;
    float: left;
    text-align: left;

    .form-item select {
      border: 1px solid #828282;
    }
    .fieldset-legend {
      font-weight: 400;
      margin-bottom: 0.375em;
      padding-left: 0;
      font-size: 0.9em;
      font-family: "Poppins",sans-serif;
      color: #636363;
    }
    fieldset.date-combo .container-inline-date .date-padding {
      padding: 10px 10px 10px 0;
    }
  }

  #edit-login-data {
    width: 100%;
    float: left;
    text-align: left;
    margin-top: 15px;

    .form-item.form-type-password-confirm.form-item-password {
      width: 100%;
      float: left;
      text-align: left;
      margin-top: 15px;

      div.password-strength {
        float: left;
        text-align: left;
      }
    }
  }

  #edit-field-haluan-ratsulan-tietoa-tuo, #edit-customer-terms-of-user-container, #edit-field-rinnakkaiskortti, #edit-field-kanta-asiakaskortin-toimit {
    width: 100%;
    float: left;
    text-align: left;

    #edit-field-haluan-ratsulan-tietoa-tuo-und {
      margin-top: 5px;
    }
    .form-item.form-type-checkbox.form-item-custom-terms-of-use-check {
      margin-left: 0;
    }
  }
  #edit-field-haluan-ratsulan-tietoa-tuo {
      margin-bottom: 0;
  }
  #edit-field-kanta-asiakaskortin-toimit {
    margin-bottom: 2em;

      .form-type-radio {
          padding: 0.25em 0;
      }
  }
  #field-rinnakkaiskortti-add-more-wrapper .form-item {
    margin-left: 0;
  }
  #edit-actions {
    width: 100%;
    float: left;
    text-align: left;
  }
}
body.ratsula-wide-content form#card-account-entityform-edit-form {
  fieldset.required-fields.group-acoount-card.field-group-fieldset.form-wrapper {
    width: 100%;
    float: left;
    text-align: left;

    #edit-field-luottoraja {
      margin-bottom: 0;
      #edit-field-luottoraja-und {
        margin-top: 10px;
      }
    }
  }
  #edit-field-postinumero {
    margin-right: 2%;
  }
  #edit-field-katuosoite, #edit-field-puhelinnumero {
    margin-right: 0;
  }
  #edit-field-haluan-ratsulan-tietoa-tuo {
    margin: 0;
  }
  #edit-field-hetu {
    width: 100%;
    float: left;
    text-align: left;
    margin-bottom: 15px;

    .form-item.form-type-textfield.form-item-field-hetu-und-0-value {
      max-width: 49%;
      @include media($mobile) {
        max-width: 100%;
      }
    }
  }
}
body.ratsula-wide-content form#webform-client-form-1112 {

  .form-item {
    margin-left: 0;
  }
  .webform-component--etunimi, .webform-component--lahiosoite, .webform-component--puhelinnumero {
    width: 49%;
    float: left;
    margin-right: 2%;
    @include media($mobile) {
      width: 100%;
      margin-right: 0;
    }
  }
  .webform-component--sukunimi, .webform-component--postinumero, .webform-component--sahkopostiosoite {
    width: 49%;
    float: left;
    @include media($mobile) {
      width: 100%;
    }
  }
  .webform-component--moda-kortin-numero, .webform-component--postitoimipaikka, .webform-component--lisatiedot {
    width: 100%;
    float: left;
    max-width: unset;
  }
  div.form-actions {
    text-align: left;
    float: left;
    width: 100%;
    margin-bottom: 100px;
  }
}

table#ratsula-content-table {
  table-layout: fixed;
  border-collapse: collapse;


  tbody tr:hover>td {
    background-color: white;
  }

  tr {
    td {
      vertical-align: top;
      border: 1px solid #EEF0F7;
      padding: 40px 25px 30px 25px;
    }
  }

}
@media (max-width:750px) {
  h2 {
    a.linklike-btn {
      float: none;
      margin: 0 15px;
    }
  }
  table#ratsula-content-table {
    tbody {
      display: flex;
      flex-direction: column;

      tr {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.provider-group-name {
  text-align: left;
  margin: 2em 0 1em 0;
}

.provider-group {
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  pointer-events: none;

  a {
    text-decoration: none;
    color: #505050;
    display: block;
    pointer-events: auto;

    &:hover .provider {
      box-shadow: 0 0 8px 4px #d1d1d1aa;
    }
  }

  &:hover a:not(:hover) .provider {
    opacity: 0.5;
  }

  .provider {
    transition: 0.2s all;
    display: flex;
    flex-direction: column;
    border: 1px solid #efefef;
    border-radius: 8px;
    overflow: hidden;
    width: 175px;
    text-align: center;

    .provider-name {
      padding: 0.5em 0.33em;
      border-top: 1px solid #efefef;
      text-align: center;
    }
  }
}
