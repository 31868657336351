// #site-header {
//     position: fixed;
//     left: 0; right: 0;
//     top: 0;
//     height: $site-header-height;

//     #logo {
//         // float: left;
//         height: 1.667em;
//         margin-top: 1.834em;
//         max-width: 50%;

//         img {
//             height: 100%;
//         }
//     }

//     .header-right-nav {
//         float: right;
//         height: $site-header-height;
//         line-height: $site-header-height;

//         .hr-nav {
//             float: left;
//             margin: 0 3em;
//             font-size: 1.067em;
//             font-weight: 500;

//             @include media($medium-only) {
//                 margin: 0 1em;
//                 font-size: 0.9em;
//             }

//             @include media($mobile) {
//                 margin: 0 0.4em;

//                 a {
//                     display: block;
//                     width: 1px;
//                     overflow: hidden;
//                     color: transparent !important;
//                     white-space: nowrap;
//                 }
//             }

//             &:last-child {
//                 margin-right: 0;
//             }

//             a {
//                 display: block;
//                 padding-left: 2em;
//                 text-decoration: none;
//             }

//             background-position: left center;
//             background-repeat: no-repeat;
//             background-size: auto 1.667em;

//             &.login-nav {
//                 background-image: url('../img/User.png');
//             }

//             &.cafe-nav {
//                 background-image: url('../img/Cafe.png?v2');
//             }

//             &.toggle-nav {
//                 background-image: url('../img/Menu.png');
//             }

//             &.login-out-nav {
//                 @include media($mobile) {
//                     display: none;
//                 }

//                 margin-left: 0;

//                 a {
//                     padding: 0;
//                     font-size: 0.75em;
//                 }
//             }

//         }

//     }
// }

#page-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;

    // box-shadow: 0 0px 4px #b5b5b5;
    background: $color-bg;

    // height: $page-header-height-without-bottom;

    // @include media($medium) {
    //     height: $page-header-height-small-screen;
    // }

    // @include media($mobile) {
    //     height: $page-header-height-small-screen;
    // }

    a {
        color: $color-primary;

        &:hover {
            color: $color-primary-active;
        }
    }

    #page-header-top {
        background-color: $color-light;
        color: $color-text-primary;
        &,
        a {
            color: $color-text-primary;
        }

        @include media($mobile) {

            &,
            a {
                color: $color-text-primary;
                text-decoration: none;
            }
        }

        #page-header-top-content {
            display: flex;
            align-items: center;
            height: $header-top-height;

            .right {
                flex: 1;
                text-align: right;
            }

            @include media($mobile) {
                font-size: 0.8em;
            }
        }
    }

    #page-header-middle {
        @include media($mobile) {
            border-bottom: 1px solid #f0f0f0;
            background-color: $color-bg;
        }

        #page-header-middle-content {
            height: 6em;
            align-items: center;
            display: grid;
            grid-template-columns: 25% 50% 25%;
            width: 100%;

            #logo {
                display: none;

                text-align: center;

                img {
                    width: 13em;
                    display: block;
                    margin: 0 auto;
                }
            }

            #logo-desktop {
                display: block;
                text-align: center;

                img {
                    width: 13em;
                    display: block;
                    margin: 0 auto;
                }
            }

            #kenen-ratsula {
                text-align: center;
                font-family: 'Ratsula';
                font-size: 1.25em;
                line-height: 1em;
            }

            .header-right-nav {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            @include media($medium) {
                grid-template-columns: 30% 40% 30%;
            }

            @include media($mobile) {
                display: flex;
                height: 3em;

                #logo {
                    flex: 1;
                    display: block;
                }

                #logo-desktop {
                    display: none;
                }

                .header-left-nav-link {
                    display: flex;
                    align-items: center;
                    margin-right: 1em;
                    padding-right: 1em;
                    border-right: 1px solid #f0f0f0;
                    height: 100%;
                }

                .header-left-nav {
                    height: 100%;
                }

                .user-img {
                    margin-right: 0;

                    img {
                        display: block;
                    }
                }

                .header-cart {
                    margin-left: 1em;
                    margin-right: 1.5em;
                }

                #logo img {
                    margin: 0;
                }

                #kenen-ratsula {
                    display: none;
                }
            }
        }
    }

    #page-header-bottom {
        background: $color-light;
    }

    #page-header-bottom-content {
        display: flex;
        position: relative;
        align-items: center;
    }

    a {
        text-decoration: none;
    }

    .toggle-nav {
        width: 1.6em;
        // margin-left: 1em;

        &,
        img {
            height: 1.3334em;
        }

        &.open {
            display: block;
        }

        &.close {
            display: none;
        }
    }

    .login-nav {
        position: relative;
        display: flex;
        align-items: center;
        height: 1.3334em;

        >a {
            background-image: url('../img/nuoli-alas-vector-secondary.svg');
            background-position: right 0.5em center;
            background-repeat: no-repeat;
            padding-right: 2em;
        }

        .own-details {
            position: absolute;
            left: 0;
            top: 100%;
            z-index: 2;
            text-align: center;
            font-size: 0.9em;

            width: max-content;
            max-width: unset;
            padding: 1em 0.5em;

            color: $color-primary;
            background-color: #fff;
            display: none;
            border: 1px solid $color-primary;

            .ostohyvitys {
                font-size: 16px;
                color: $color-primary;
                letter-spacing: 0;
                text-align: center;
                max-width: 210px;
                margin: 0 auto;
                font-weight: 500;

                .maara {
                    margin: 1em 0;
                }

                .summa {
                    display: block;
                    font-size: 2.5em;
                    line-height: 1em;
                }
            }

            .link {
                max-width: 300px;

                a {
                    text-decoration: none;
                    border: 1px solid $color-primary;
                    color: $color-primary;
                    padding: 0.5em 1em;
                    text-transform: uppercase;
                    margin: 7.5px;
                    display: inline-block;
                    width: calc(50% - 15px);
                    text-align: center !important;

                    &:hover {
                        background-color: $color-primary;
                        color: #fff;
                    }
                }
            }

            .link-logout {
                margin-top: 2em;
                font-weight: 500;

                a {
                    text-decoration: underline;
                    color: $color-primary;;
                }
            }
        }

        &.opened {
            .own-details {
                display: block;
            }
        }
    }

    .user-img {
        margin-right: 0.33em;

        img {
            height: 1.3334em;
        }
    }

    .left p.sijainti {
        display: inline-block;
        padding-left: 1em;

        a {
            position: absolute;
            margin-left: -1.5em;
            margin-top: -0.2em;
        }
    }

    .header-aukioloajat {
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        // margin: 0 -1em;

        a {
            text-decoration: underline;
        }

        .left,
        .right {
            display: flex;
            font-size: 0.9375em;
            height: 100%;
            align-items: center;

            @include media($medium) {
                >* {
                    // margin: 0 -0.5em !important;
                    margin: 0 !important;
                }
            }
        }

        .left>*:not(:first-child),
        .right>*:not(:last-child) {
            margin: 0 0.25em;
        }

        .right {
            justify-content: flex-end;
        }
    }

    #aukioloaika,
    #asiakaspalvelu,
    #gomee {
        height: 100%;
        padding: 0 0.5em;
        position: relative;

        .toggle-label {
            height: 100%;
            line-height: 2.8em;
            cursor: pointer;

            a {
                display: inline-block;
                text-decoration: none;
                width: 1.2em;
                background-image: url('../img/nuoli-alas-vector-black.svg');

                @include media($mobile) {
                    background-image: url('../img/nuoli-alas-vector-black.svg');
                }

                // background-size: 100% auto;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 0.8em 0.8em;
                transition: 0.5s all;
            }
        }

        a {
            &.toggle-label {
                display: inline-block;
                text-decoration: none;
                width: 1.2em;
                background-image: url('../img/nuoli-alas-vector-black.svg');

                @include media($mobile) {
                    background-image: url('../img/nuoli-alas-vector-black.svg');
                }

                // background-size: 100% auto;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 0.8em 0.8em;
                transition: 0.5s all;
            }
            &.link-to-gomee {
                text-decoration: none;
            }
        }

        &.opened {
            background-color: $color-light;

            @include media($mobile) {
                background-color: $color-light;
            }
        }
    }

    #gomee {
        margin-right: -0.5em;
    }

    #aukioloaika {
        margin-left: -0.5em;

        #aukioloajat-popup {
            table {
                @include media($mobile) {
                    font-size: 0.9em;
                }
            }

            #aukioloajat-lisatiedot {
                strong {
                    display: initial !important;
                }

                @include media($mobile) {
                    font-size: 0.9em;
                }

                a {
                    text-decoration: underline;
                }
            }

            .today {
                display: inline-block;
                padding-right: 2em;
            }

            .days {
                display: flex;
                flex-wrap: wrap;
                margin: 0.5em 0;

                .day-names {
                    font-weight: bold;
                }

                .day {
                    margin: 0.5em;
                }

                .day-oh {
                    white-space: nowrap;
                }
            }

            table {
                margin: 0;
                border-collapse: collapse;
                table-layout: auto;
                width: auto;

                tr.this-week td {
                    font-weight: 600;
                }

                td {
                    padding: 0;

                    &:nth-child(1) {
                        padding-right: 1.25em;
                    }

                    &:nth-child(2) {
                        text-align: center;
                        padding: 0 0.25em;
                    }

                    &.closed {
                        text-align: center;
                    }
                }

                tr:hover td {
                    background-color: transparent;
                }
            }
        }
    }

    #asiakaspalvelu-popup, #gomee-popup {
        h2, h6, h4 {
            font-size: 1em;
            text-transform: lowercase;
            font-weight: 600;
            &::first-letter {
                text-transform: capitalize;
            }
        }
    }

    #aukioloajat-popup,
    #asiakaspalvelu-popup,
    #gomee-popup {
        display: none;
        box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.1);

        background-color: $color-light;

        @include media($mobile) {
            background-color: $color-light;
        }

        z-index: 2;

        position: absolute;
        left: 0;
        // right: 0;
        top: 100%;
        width: 28em;
        max-width: 100vw;
        margin: 0;
        float: none;

        padding: 1em;
        text-align: left;

        p {
            strong {
                display: none;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: 'Poppins', sans-serif;
            font-size: 1em;
            text-align: left;
            font-weight: 600;
            color: $color-text-primary;

            @include media($mobile) {
                color: $color-text-primary;
            }
        }

        #ratsula-mobile-close-aup, #ratsula-mobile-close-gomee {
            display: none;
        }
        @include media($mobile) {
            position: fixed;
            top: $header-top-height;
            left: 0;
            right: 0;
            width: 100%;
            bottom: 0;

            div, p, td, *, h4, span {
                font-size: 14px;
            }

            #ratsula-mobile-close-aup, #ratsula-mobile-close-gomee {
                display: flex;
                justify-content: flex-end;
                margin: 0 1em 1em 0;
                img {
                    height: 13px;
                }
            }
        }
    }

    #gomee-popup {
        right: 0;
        left: auto;

        .lunch-title {
            margin-bottom: 0em;
        }

        .lunch-item {
            display: block;
            margin: 0.5em 0;
        }
    }

    .opened {

        #aukioloajat-popup-toggle,
        #asiakaspalvelu-popup-toggle,
        #gomee-popup-toggle {
            // background-position: center 0px;
            transform: rotateX(180deg);
        }
    }

    @include media($large) {
        #page-header-bottom .block-ratsula-shop .ratsula-shop-category-header-menu>li>span {
            font-size: 0.8em;
        }
    }

    @include media($medium) {
        #page-header-bottom {
            display: none;
        }
    }
}

.menu-open {
    .toggle-nav {
        &.open {
            display: none !important;
        }

        &.close {
            display: block !important;
        }
    }
}

.logged-in #page-header-middle {
    background: $color-primary;

    &,
    a {
        color: $color-secondary;
    }
}

// .block-search #search-block-form > div > div {
//     display: flex;

//     .form-item {
//         margin: 0;

//         input[type="text"] {
//             border-width: 0 0 1px 0;
//             box-shadow: none;
//             background-color: transparent;
//         }
//     }

//     input[type="submit"] {
//         font-size: 0px;
//         background: transparent;
//         background-image: url('../img/suurennuslasi.svg');
//         background-size: 1.5rem 1.5rem;
//         background-position: center;
//         background-repeat: no-repeat;
//         width: 2rem;
//         height: 2rem;
//         display: block;

//         color: $color-text-secondary;

//         &:hover {
//             color: $color-text-secondary-active;
//         }
//     }
// }

body.admin-menu {
    #page-header {
        margin-top: 29px;
    }

    @include media($mobile) {

        #aukioloajat-popup,
        #asiakaspalvelu-popup,
        #gomee-popup {
            @include calc(top, "#{$header-top-height} + 29px");
        }
    }
}

@include media($mobile) {
    body.scrolled-over-main {
        #page-header {
            // display: none;
        }
    }
}

body {

    &.menu-open,
    &.search-open {
        .fb_dialog {
            display: none;
        }
    }
}
